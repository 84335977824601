<template>
  <div id="historyDetail">
    <div class="detail-header">
      <div class="store-name">{{ obStoreInformation.store_name }}</div>
    </div>

    <div class="detail-title">
      <span class="title-text">{{ $t('ORDER_HISTORY.CUSTOMER_INFORMATION') }}</span>
      <span class="title-explain">{{ $t('ORDER_HISTORY.STORE_CUSTOMER') }}</span>
    </div>

    <div class="detail-content content-customer">
      <div class="detail-row-1">
        <div class="title">{{ $t('ORDER_HISTORY.RECIPIENT') }}</div>
        <div class="content">{{ obHistoryKey.user_name }}</div>
      </div>
      <div class="detail-row-1">
        <div class="title">{{ $t('ORDER_HISTORY.CONTACT') }}</div>
        <div class="content">{{ obHistoryKey.user_tel }}</div>
      </div>
    </div>

    <div class="detail-title">
      <span class="title-text">{{ $t('ORDER_HISTORY.MENU_INFORMATION') }}</span>
      <span class="title-explain">주문 접수중</span>
    </div>

    <div class="history-detail-list" v-for="(history, index) in historyDetail" :key="index">

      <div class="detail-content content-option-header">
        <div class="detail-row-2 detail-row-font-bold">
          <div class="content-title">{{ $t('ORDER_HISTORY.MENU') }}</div>
          <div class="content-order">
            <div class="content-count">{{ $t('ORDER.QUANTITY') }}</div>
            <div class="content-price">{{ $t('ORDER_HISTORY.PRICE') }}</div>
          </div>
        </div>
      </div>

      <div class="detail-content-container">
        <div class="detail-content-box detail-content-item-digest">
          <div class="detail-row-2 detail-row-color-1">
            <div class="content-title">{{ history.menu_name }}</div>
            <div class="content-order">
              <div class="content-count">{{ history.menu_count }}</div>
              <div class="content-price">{{ history.menu_price | commaNumber }} {{ $t('COMMON.NONEYARY_UNIT') }}</div>
            </div>
          </div>
          <div class="detail-row-2 detail-row-color-2">
            <div class="content-title">{{ $t('ORDER_HISTORY.DISCOUNT_AMOUNT') }}</div>
            <div class="content-order">
              <div class="content-count">  </div>
              <div class="content-price">{{ history.menu_dc_price | commaNumber }} {{ $t('COMMON.NONEYARY_UNIT') }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="detail-content-container">
        <div class="detail-content-box detail-content-item-option">
          <div class="detail-row-option" v-for="(option, idxOption) in history.option" :key="idxOption">
            <div class="detail-row-option-name"><span v-if="option.option_name !== ''">- </span>{{ option.option_name }}</div>
            <div class="detail-row-option-other">
              <div class="detail-row-option-content">{{ option.option_content }}</div>
              <div class="detail-row-option-price">{{ option.option_price | commaNumber }} {{ $t('COMMON.NONEYARY_UNIT') }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="detail-content-container">
        <div class="detail-content-box detail-content-item-total detail-content-container-list">
          <div class="detail-row-2 detail-row-color-1">
            <div class="content-title">{{ $t('ORDER.SUB_TOTAL') }}</div>
            <div class="content-order">
              <div class="content-count">{{ history.menu_count }}</div>
              <div class="content-price">{{ history.price * history.menu_count | commaNumber }} {{ $t('COMMON.NONEYARY_UNIT') }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="detail-total-stats-box">
      <div class="total-stats-detail">
        <div class="total-price">
          <div class="title">{{ $t('ORDER.TOTAL_MENU_AMOUNT') }}</div>
          <div class="price">{{ totalPrice + totalDiscountPrice | commaNumber }} {{ $t('COMMON.NONEYARY_UNIT') }}</div>
        </div>
        <div class="total-price">
          <div class="title">{{ $t('ORDER.TOTAL_DISCOUNT_AMOUNT') }}</div>
          <div class="price">{{ totalDiscountPrice | commaNumber }} {{ $t('COMMON.NONEYARY_UNIT') }}</div>
        </div>
      </div>
      <div class="total-stats-digest">
        <div class="digest-title">{{ $t('ORDER_HISTORY.TOTAL_AMOUNT') }}</div>
        <div class="digest-other">
          <div class="digest-count">{{ totalCount }}</div>
          <div class="digest-price">{{ totalPrice | commaNumber }} {{ $t('COMMON.NONEYARY_UNIT') }}</div>
        </div>
      </div>
    </div>

    <div class="requests-msg-box">
      <div class="requests-msg-title">{{ $t('ORDER_HISTORY.REQUESTS') }}</div>
      <div class="requests-msg">{{ obHistoryKey.request_t }}</div>
    </div>

    <div class="detail-title">
      <span class="title-text">{{ $t('ORDER_HISTORY.ORDER_INFROMATION') }}</span>
    </div>

    <div class="history-information-box">
      <div class="inform-row">
        <div class="inform-title">{{ $t('ORDER_HISTORY.ORDER_DATIME') }}</div>
        <div class="inform-content">{{ obHistoryKey.wait_dt }}</div>
      </div>
      <div class="inform-row">
        <div class="inform-title">{{ $t('ORDER_HISTORY.ORDER_NUMBER') }}</div>
        <div class="inform-content">{{ obHistoryKey.order_no }}</div>
      </div>
      <div class="inform-row">
        <div class="inform-title">{{ $t('ORDER_HISTORY.PAYMENT_AMOUNT') }}</div>
        <div class="inform-content">{{ obHistoryKey.price | commaNumber }}</div>
      </div>
      <div class="inform-row">
        <div class="inform-title">{{ $t('ORDER_HISTORY.PAYMENT_METHODS') }}</div>
        <div class="inform-content">{{ obHistoryKey.pay_type }}</div>
      </div>
      <!-- <div class="inform-row">
        <div class="inform-title">승인 번호</div>
        <div class="inform-content">{{ obHistoryKey. }}</div>
      </div> -->
    </div>

    <div class="detail-btn-box-area"></div>
    <div class="detail-btn-box">
      <button class="btn-home" @click="goHome()">{{ $t('COMMON.HOME') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      historyDetail: [],
      checkOptionID: '',
      totalCount: 0,
      totalPrice: 0,
      totalDiscountPrice: 0
    }
  },
  computed: {
    obHistoryDetail() {
      console.log(this.$store.state.history.detail)
      return this.$store.state.history.detail;
    },
    obHistoryKey() {
      console.log(this.$store.state.history.key)
      return this.$store.state.history.key;
    },
    obStoreInformation() {
      console.log(this.$store.state.storeInformation)
      return this.$store.state.storeInformation;
    }
  },
  filters: {
    commaNumber(value) {
      let negative = false;
      if(value < 0) {
        negative = true;
        value *= -1;
      }
      
      value = '' + value;
      let floatingPoint = value.split('.');
      value = floatingPoint[0];

      return `${negative? '- ' : ''}${value.split('').reverse().reduce((acc, digit, i) => {
        if (i > 0 && i % 3 === 0) acc.push(',')
        return [...acc, digit]
      }, []).reverse().join('')}`;
    },
  },
  methods: {
    processedHistory(origin) {
      console.log(origin)
      console.log(JSON.parse(origin))
      origin = JSON.parse(origin)
      let outcome = [];      

      for (let history=0; history<origin.length; history++) {
        if (origin[history].detail_type === 'menu') {
          console.log(origin[history]);
          outcome.push(origin[history]);
          outcome[outcome.length-1].option = [];

          outcome[outcome.length-1].price = origin[history].menu_price;
          outcome[outcome.length-1].discountPrice = origin[history].menu_dc_price;

          this.totalCount += origin[history].menu_count;
          this.totalPrice += origin[history].menu_price;
          this.totalDiscountPrice += origin[history].menu_dc_price;
        } else if (origin[history].detail_type === 'option') {
          outcome[outcome.length-1].option.push(origin[history]);

          outcome[outcome.length-1].price += origin[history].option_price;
          if(this.checkOptionID === origin[history].option_id) {
            outcome[outcome.length-1].option[outcome[outcome.length-1].option.length-1].option_name = '';
          }
          this.checkOptionID = origin[history].option_id;

          this.totalPrice += origin[history].option_price
        }
      }

      return outcome;
    },
    goHome() {
      this.$router.push(`${this.$store.state.gRoot}/main/${this.$store.state.storeID}`);
    }
  },
  created() {
    console.log(this.obHistoryDetail)
    this.historyDetail = this.processedHistory(this.obHistoryDetail.order_detail); 
  },
  mounted() {
  }
}
</script>

<style lang="scss">
#historyDetail {
  .detail-header {
    .store-name {
      height: 47px;
      padding: 0 20px;

      border-top: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
      font-size: 15pt;

      background-color: #fff;
      color: #ff8b45;

      text-align: center;
      line-height: 47px;
    }
  }

  .detail-title {
    padding: 0 20px;

    border-bottom: 1px solid #ebebeb;

    .title-text {
      display: inline-block;

      height: 42px;

      font-size: 13pt;

      color: #808080;

      line-height: 42px;
    }

    .title-explain-box {
      float: right;

      height: 42px;
    }

    .title-explain {
      float: right;

      height: 23px;
      padding: 0 8px;
      margin-top: 9px;

      background-color: #ff8b45;
      color: #fff;

      line-height: 23px;
    }
  }

  .detail-content {
    border-bottom: 1px solid #ebebeb;

    background-color: #fff;

    .detail-row-1,
    .detail-row-2 {
      overflow: hidden;

      height: 18px;

      font-size: 11pt;

      line-height: 18px;

      &:not(:first-child) {
        margin-top: 4px;
      }
    }

    .detail-row-1 {
      .title {
        float: left;
      }

      .content {
        float: right;
      }
    }

    .detail-row-2 {
      .content-title {
        float: left;
      }

      .content-order {
        float: right;

        .content-count {
          float: left;

          width: 64.666667px;
          height: 18px;

          text-align: center;
        }
        .content-price {
          float: left;

          width: 77px;

          text-align: right;
        }
      } 
    }

    .detail-row-font-bold {
      font-weight: 700;
    }
  }

  .detail-content-container {
    padding-left: 20px;

    background-color: #fff;

    &:last-child {
      // background-color: magenta;

      .detail-content-box {
        border-bottom: none;
      }
    }

    .detail-content-box {
      padding-right: 20px;
      
      border-bottom: 1px solid #f7f7f7;

      .detail-row-color-1 {
        color: #424242;
      }
      .detail-row-color-2 {
        color: #a8a8a8;
      }

      .detail-row-2 {
        overflow: hidden;

        height: 18px;

        font-size: 11pt;

        line-height: 18px;

        &:not(:first-child) {
          margin-top: 4px;
        }

        .content-title {
          float: left;
        }

        .content-order {
          float: right;

          .content-count {
            float: left;

            width: 64.666667px;
            height: 18px;

            text-align: center;
          }
          .content-price {
            float: left;

            width: 77px;

            text-align: right;
          }
        } 
      }

      .detail-row-option {
        overflow: hidden;

        font-size: 11pt;

        color: #a8a8a8;

        line-height: 18px;

        &:not(:first-child) {
          margin-top: 4px;
        }

        .detail-row-option-name {
          float: left;

          width: 81px;
          height: 18px;
        }

        .detail-row-option-other {
          overflow: hidden;
          float: left;

          width: 100%;
          height: 18px;
          margin-right: -81px;
          padding-right: 81px;

          .detail-row-option-content {
            float: left;
          }

          .detail-row-option-price {
            float: right;
          }
        }
      }
    }

    .detail-content-container-list {
      border: none;
    }

    .detail-content-item-digest {
      padding: {
        top: 14px;
        bottom: 10px;
      }
    }
    .detail-content-item-option {
      padding: {
        top: 10px;
        bottom: 10px;
      }
    }
    .detail-content-item-total {
      padding: {
        top: 11px;
        bottom: 11px;
      }
    }
  }

  .content-customer {
    padding: 14px 20px;

    color:  #a8a8a8;
  }

  .content-option-header {
    padding: 10px 20px;

    color: #666;
  }

  .detail-total-stats-box {
    margin-top: 6px;
    padding-left: 20px;

    border-bottom: 1px solid #ebebeb;

    background-color: #fff;

    .total-stats-detail {
      padding: {
        top: 14px;
        right: 20px;
        bottom: 12px;
      }

      border-bottom: 1px solid #f7f7f7;

      .total-price {
        overflow: hidden;

        line-height: 24px;

        .title {
          float: left;

          height: 24px;

          font-size: 13pt;
          color: #999;
        }
        .price {
          float: right;

          height: 23px;

          font-size: 13pt;
          color: #424242;
        }
      }
    }

    .total-stats-digest {
      overflow: hidden;

      padding-right: 20px;

      line-height: 60px;

      .digest-title {
        float: left;

        font-size: 13pt;

        color: #424242;
      }

      .digest-other {
        float: right;

        .digest-count {
          float: left;

          width: 36px;

          font-size: 13pt;
          color: #424242;

          text-align: center;
        }
        .digest-price {
          float: left;

          width: 96px;

          font-size: 14pt;
          color: #4a9cdd;

          text-align: right;
        }
      }
    }
  }


  .requests-msg-box {
    overflow: hidden;

    margin-top: 20px;
    padding: 14px 20px;

    border-bottom: 1px solid #ebebeb;
    font-size: 11pt;

    color: #a8a8a8;

    background-color: #fff;

    line-height: 18px;

    .requests-msg-title {
      float: left;

      width: 81px;
    }

    .requests-msg {
      float: left;

      width: 100%;
      margin-right: -81px;
      padding-right: 81px;
    }
  }

  .history-information-box {    
    border-bottom: 1px solid #ebebeb;

    background-color: #fff;
    color: #a8a8a8;

    padding: 14px 20px;

    .inform-row {
      overflow: hidden;
      height: 18px;

      line-height: 18px;

      &:not(:first-child) {
        margin-top: 4px;
      }

      .inform-title {
        float: left;
      }
      .inform-content {
        float: right;
      }
    }
  }

  .detail-btn-box-area {
    width: 100%;
    height: 70px;
  }

  .detail-btn-box {
    position: fixed;
    display: inline-block;

    right: 0;
    bottom: 0;
    left: 0;

    .btn-home {
      width: 100%;
      height: 50px;
      
      font-size: 17.333333pt;

      background-color: #ff8b45;
      color: #fff;

      &:active {
        background-color: #d7773c;
      }
    }

    
  }
  
}
</style>
