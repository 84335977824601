<template>
  <div id="Failure">
    <div class="failure-container">
      <img class="failed-logo" src="../../assets/images/new/failed.svg" alt="">
      <h1 class="failed-title">결제 실패</h1>
      <h2 class="failed-guide">결제에 실패했습니다.</h2>
      <div class="failed-text">
        <h3></h3>
        <h3>[404]</h3>
      </div>
      <button class="retry" @click="routeHome">{{ $t('COMMON.CONFIRM') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    console.log(123)
  },
  methods: {
    routeHome() {
      this.$router.push('/stationary/home')
    }
  }
}
</script>

<style lang="scss">
#Failure {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px;  

  .failure-container {
    height: 100%;
    border-radius: 18px;
    background: linear-gradient(to bottom, #777, #424242 );

    text-align: center;

    .failed-logo {
      margin-top: 80px;
    }

    .failed-title {
      height: 40px;
      margin-top: 4px;

      font-size: 30px;
      color: white;

      line-height: 1.33;
    }

    .failed-guide {
      height: 30px;
      margin-top: 15px;

      font-size: 20px;
      color: white;

      line-height: 1.5;
    }

    .failed-text {
      margin-top: 5px;

      font-size: 13px;
      color: #b3b3b3;

      line-height: 1.54;
    }

    .retry {
      width: 300px;
      height: 60px;
      margin-top: 48px;

      border-radius: 100px;
      font-size: 20px;
      color: white;
      background-color: #333;

      line-height: 1.5;

      &:active {
        background-color: #666;
      }
    }
  }
}
</style>