import axios from 'axios';

export const loadBanner = async (id) => {
  try {
    const config = {
      method: 'GET',
      url: `${process.env.VUE_APP_API_URL}/store/${id}/banners`,
    }

    const result = await axios(config);
    console.log(result);
    return result;
  } catch (error) {
    console.error(error);
  }
};