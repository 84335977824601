<template>
  <div id="StationaryHome">
    <div class="limit-width">
      <div class="store-signboard" :style="{ backgroundImage: `url('${ isNotLogo ? '' : GET_STORE_INFORMATION.mobile_img}')` }">
        <span v-if="isNotLogo" class="store-name-text">{{ GET_STORE_INFORMATION.store_name }}</span>
      </div>

      <div class="fixed-banner-container">
        <div v-for="(banner, idx) in GET_BANNERS" :key="idx" class="banner-wrap">
          <div 
            class="banner-item"
            :style="{ backgroundImage: `url(${banner.menu_img})` }"
            @click="lookProductDetail(banner)"
          ></div>
        </div>
      </div>

      <div class="carousel-slide">
        <hooper :autoPlay="true" :playSpeed="6000">
          <slide v-for="(item, index) in GET_STORE_INFORMATION.ad_list" :key="index">
            <div class="slide-box">
              <div class="slide-item" :style="{ backgroundImage: `url('${item.ad_img}')` }"></div>
            </div>
          </slide>
          <pagination slot="hooper-addons"></pagination>
        </hooper>
      </div>

      <div class="category-container">
        <div class="category-box">
          <span>
            <button class="menu"></button>
            <button 
              class="category-item" :class="{ 'selected': GET_CATEOGRY_ID === category.category_id }" 
              v-for="(category, idx) in GET_CATEGORY_LIST" :key="idx"
              @click="lookProductList(category)"
            >{{ category.category_names | language }}</button>
          </span>
        </div>
      </div>

      <div class="product-list-container">
        <div class="product-list">
          <div class="product-item" v-for="(product, idx) in GET_PRODUCT_LIST" :key="idx" @click="lookProductDetail(product)">
            <div class="image-container">
              <div class="product-image" :style="{ backgroundImage: `url('${product.menu_img}')` }"></div>
            </div>
            <div class="product-name">{{ product.menu_names | language }}</div>
            <div class="origin-price">
              <span v-if="product.dc_price>0">{{ product.price + product.dc_price | commaPrice }}원</span>
              </div>
            <div class="price">
              <span class="number">{{ product.price | commaPrice }}</span>
              <span>원</span> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vuex from 'vuex'
import language from '../../filters/language'
import commaPrice from '../../filters/commaPrice';
import { Hooper, Slide, Pagination } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  components: {
    Hooper,
    Slide,
    Pagination
  },
  data() {
    return {
      storeID: '',
    }
  },
  computed: {
    ...vuex.mapGetters({
      'GET_STORE_ID': 'im/GET_STORE_ID',
      'GET_STORE_INFORMATION': 'im/GET_STORE_INFORMATION',
      'GET_CATEGORY_LIST': 'im/GET_CATEGORY_LIST',
      'GET_CATEOGRY_ID': 'im/GET_CATEOGRY_ID',
      'GET_PRODUCT_LIST': 'im/GET_PRODUCT_LIST',
      'GET_BANNERS': 'store/GET_BANNERS',
    }),
    banners() {
      return Array.from({ length: this.bannerCount });
    },
    isNotLogo() {
      const { mobile_img: logo } = this.GET_STORE_INFORMATION;
      const regrex = /no_img/;

      console.log('logo logo logo logo')
      console.log(regrex.test(logo));
      return regrex.test(logo);
    }
  },
  filters: {
    language,
    commaPrice
  },
  async created() {
    console.log(`Version 2.2.1`);

    document.body.style.backgroundColor = "white";

    await this.initApp();
    await this.fetchCategoryList();
    await this.LOAD_BANNER();

    console.log(this.GET_STORE_INFORMATION);
  },
  mounted() {
    console.log('TheHome')
  },
  methods: {
    ...vuex.mapActions({
      'FETCH_STORE_INFORMATION': 'im/FETCH_STORE_INFORMATION',
      'FETCH_CATEGORY_LIST': 'im/FETCH_CATEGORY_LIST',
      'FETCH_PRODUCT_LIST': 'im/FETCH_PRODUCT_LIST',
      'SET_PRODUCT_ITEM': 'im/SET_PRODUCT_ITEM',
      'INIT_VUEX_STORE': 'im/INIT_VUEX_STORE',
      'LOAD_BANNER': 'store/LOAD_BANNER',
    }),
    async initApp() {
      this.storeID = this.$route.params.storeID || this.GET_STORE_ID;

      this.INIT_VUEX_STORE();
      
      let res = await this.FETCH_STORE_INFORMATION(this.storeID);

      document.title = res.data?.store_name;
      return res;
    },
    async fetchCategoryList() {
      let res = await this.FETCH_CATEGORY_LIST();
      return res;
    },
    async lookProductList(category) {
      let res = await this.FETCH_PRODUCT_LIST(category.category_id)
      return res;
    },
    lookProductDetail(product) {
      this.SET_PRODUCT_ITEM(product)

      this.$router.push(`/stationary/product/detail`)
    }
  }
}
</script>

<style lang="scss">
#StationaryHome {
  .limit-width {

  }

  .store-signboard {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100px;
    margin: 10px 152px;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    .store-name-text {
      font-size: 54px;
      font-weight: bold;
    }    
  }

  .carousel-slide {
    margin-bottom: 66px;
    padding: 0 32px;
  }

  .category-container {
    padding: 0 30px;

    .category-box {
      display: flex;
      align-items: center;
      height: 90px;
      border-top: 1px solid #dbdbdb;
      border-bottom: 1px solid #dbdbdb;

      .menu {
        width: 60px;
        height: 60px;
        margin-right: 9px;

        background-image: url('../../assets/images/new/menu-nor.svg');
        background-position: center;
        background-size: 60px;
        background-repeat: no-repeat;

        vertical-align: middle;
        transition: all .2s;

        cursor: pointer;

        &:active {
          background-image: url('../../assets/images/new/menu-pre.svg');
        }
      }

      .category-item {
        height: 60px;
        margin: 0 9px;

        font-size: 18px;
        font-weight: bold;
        color: black;

        cursor: pointer;
      }

      .category-item.selected {
        color: $color-main;
      }
    }
  }

  .product-list-container {
    padding: 30px 24px;

    .product-list {
      display: flex;
      flex-wrap: wrap;

      .product-item {
        flex-basis: 33.333333%;
        flex-basis: 25%;
        padding: 0 12px;

        cursor: pointer;

        &:not(:nth-child(-n+4)) {
          margin-top: 30px;
        }

        .image-container {
          position: relative;
          width: 100%;
          padding-top: 100%;

          .product-image {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            border-radius: 18px;
            background-position: center;
            background-size: cover;
          }
        }

        .product-name {
          overflow: hidden;
          height: 56px;
          margin-top: 20px;
          font-size: 18px;
          font-weight: bold;
          line-height: 1.56; 
        }
        .origin-price {
          height: 28px;
          margin-top: 4px;

          font-size: 18px;
          color: #999;

          text-align: right;
          line-height: 1.56;
          text-decoration: line-through;
        }
        .price {
          height: 34px;
          font-size: 24px;
          
          text-align: right;
          line-height: 1.42;

          .number {
            color: $red;
          }
        }
      }
    }
  }

  .fixed-banner-container {
    display: flex;
    flex-wrap: wrap;

    .banner-wrap {
      flex-basis: 50%;
      width: 50%;

      &:nth-child(2n) {
        padding-left: 5px;
      }
      &:nth-child(2n + 1) {
        padding-right: 5px;
      }
      &:not(:nth-child(-n+2)) {
        padding-top: 10px;
      }

      .banner-item {
        aspect-ratio: 472/250;
        background-image: url('https://via.placeholder.com/472x250/cccccc/666.png?text=472:250');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

.hooper {
  height: auto;

  .hooper-list {
    margin-bottom: 66px;

    .hooper-slide {
      .slide-box {
        position: relative;
        padding-top: 50%;

        .slide-item {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }

    .hooper-pagination {
      bottom: -48px;
      height: 30px;

      .hooper-indicators {
        .hooper-indicator {
          width: 12px;
          height: 12px;
          margin: 0 15px;
          border-radius: 6px;
          background-color: #dbdbdb;
        }
        .is-active {
          background-color: black;
        }
      }
    }
  }
}


</style>