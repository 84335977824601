<template>
  <footer id="HomeFooter" :class="{ 'footer-fixed': false }" @resize="rePosition">
    <div class="store-information">
      <div class="sinfo-row">
        <div class="title">{{ $t('STORE_NAME') }}</div>
        <div class="content">{{ getStoreImformation.store_names | language }}</div>
      </div>
      <div class="sinfo-row">
        <div class="title">{{ $t('ADDRESS') }}</div>
        <div class="content">{{ getStoreImformation.addr1s | language }} {{ getStoreImformation.addr2 | language }}</div>
      </div>
      <div class="sinfo-row">
        <div class="title">{{ $t('REPRESENTATIVE') }}</div>
        <div class="content">{{ getStoreImformation.ceo_names | language }}</div>
      </div>
      <div class="sinfo-row">
        <div class="title">{{ $t('전화번호') }}</div>
        <div class="content">{{ getStoreImformation.phone_no }}</div>
      </div>
      <div class="sinfo-row">
        <div class="title">{{ $t('BUSINESS_NUMBER') }}</div>
        <div class="content">{{ getStoreImformation.business_no }}</div>
      </div>
      <div class="sinfo-row" v-if="getStoreImformation.open_time_yn && getStoreImformation.open_time_yn === 'Y'">
        <div class="title">{{ $t('BUSINESS_INFORMATION') }}</div>
        <div class="content">
          <div>
            {{ times.storeOpenTime }} ~ {{ times.storeCloseTime }}
          </div>
          <div>
            
            <div v-if="times.storeBreakStart !== times.storeBreakEnd" >
              ({{ $t('BREAK_TIME') }} : {{ times.storeBreakStart }} ~ {{ times.storeBreakEnd }})
            </div>
            <div v-else>({{ $t('BREAK_TIME') }} : - )</div>            
          </div>
          <!-- <div>공지</div> -->
        </div>
      </div>
    </div>
    <div class="page-information">
      <span @click="showPolicyPIPC">{{ $t('PRIVACT_STATEMENT') }}</span>
      <img src="../assets/images/elixirpay.svg" alt="">
    </div>

    <PolicyPIPC></PolicyPIPC>
  </footer>
</template>

<script>
import vuex from 'vuex';
import moment from 'moment'

import PolicyPIPC from './PolicyPIPC.vue'

import language from '../filters/language.js'

export default {
  components: {
    PolicyPIPC
  },
  computed: {
    ...vuex.mapGetters({
      'getStoreImformation': 'getStoreImformation'
    })
  },
  filters: {
    language
  },
  data () {
    return {
      times: {
        storeOpenTime: '0000',
        storeCloseTime: '0000',
        storeBreakStart: '0000',
        storeBreakEnd: '0000'
      },
      fixedFooter: true
    }
  },
  mounted() {
    this.setOperatingTime();

    let time = [250, 500, 1000, 2000, 3000, 4000]
    for(let t=0; t<time.length; t++) {
      setTimeout(()=>{
        this.rePosition();
      }, time[t]);
    }
  },
  destroyed() {
    this.HIDE_POLICY_PIPC();
  },
  methods: {
    ...vuex.mapActions({
      'SHOW_POLICY_PIPC': 'SHOW_POLICY_PIPC',
      'HIDE_POLICY_PIPC': 'HIDE_POLICY_PIPC'
    }),
    setOperatingTime() {
       const storeOpenTime = moment(this.getStoreImformation.open_start_time, 'hhmm').format('hh:mm A');
       const storeCloseTime = moment(this.getStoreImformation.open_end_time, 'hhmm').format('hh:mm A');

       const storeBreakStart = moment(this.getStoreImformation.break_start_time, 'hhmm').format('hh:mm A');
       const storeBreakEnd = moment(this.getStoreImformation.break_end_time, 'hhmm').format('hh:mm A');

       let times = {
         storeOpenTime,
         storeCloseTime,
         storeBreakStart,
         storeBreakEnd
       }
       this.times = times;
    },
    showPolicyPIPC() {
      this.SHOW_POLICY_PIPC();
    },
    rePosition() {
      let documentHeight = document.querySelector('#app').clientHeight;
      let windowHeight = window.innerHeight;
      if(windowHeight > documentHeight) {
        this.fixedFooter = true;
      } else {
        this.fixedFooter = false;
      }
    }
  }
}
</script>

<style lang="scss">
#HomeFooter {
  @include desktop();
  z-index: 5000;

  .store-information {
    display: flex;
    flex-direction: column;
    padding: 20px;

    background-color: #212121;

    .sinfo-row {
      display: flex;
      flex-basis: 18px;

      font-size: 12px;
      color: #999;

      line-height: 18px;

      &:not(:first-of-type) {
        margin-top: 6px;
      }

      .title {
        flex-basis: 63px;
      }
      .content {
        flex-grow: 1;
      }
    }
  }

  .page-information {
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding: 18px 20px;

    font-size: 12px;

    background-color: black;
    color: white;

    line-height: 1.5;
  }
}

.footer-fixed {
  position: fixed;
}
</style>