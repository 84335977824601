<template>
  <div id="StationaryProductDetail">
    <div class="limit-width">
      <div class="category-navigator">
        <div class="category-box">
          <span>
            <button class="menu"></button>
            <button 
              class="category-item" :class="{ 'selected': GET_CATEOGRY_ID === category.category_id }"
              v-for="(category, idx) in GET_CATEGORY_LIST" :key="idx"
              @click="lookProductList(category)"
            >{{ category.category_names | language }}</button>
          </span>
        </div>
      </div>

      <div class="product-container">
        <div class="product-image-container">
          <div class="product-image">
            <div class="detail-img-box">
              <div class="detail-img" :style="{ backgroundImage: `url('${GET_PRODUCT_DETAIL.menu_sub_img && GET_PRODUCT_DETAIL.menu_sub_img[detailImgIdx] }')` }"></div>
            </div>

            <div class="detail-img-list-box">
              <div class="detail-img-list-wrap" v-for="(img, idx) in GET_PRODUCT_DETAIL.menu_sub_img" :key="img" @click="viewDetailImage(idx)">
                <div class="detail-img-item-wrap">
                  <div class="detail-img-item" :class="{ active: idx===detailImgIdx }" :style="{ backgroundImage: `url('${img}')` }"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="product-text-container">
          <div class="product-core">
            <div class="product-name">{{ GET_PRODUCT_DETAIL.menu_name }}</div>
            <div class="product-origin-price">{{ GET_PRODUCT_DETAIL.price + GET_PRODUCT_DETAIL.dc_price | commaPrice }} 원</div>
            <div class="product-price-row">
              <span class="discount-rete">{{ GET_PRODUCT_DETAIL.dc_percent }}%</span>
              <span class="product-price">{{ GET_PRODUCT_DETAIL.price | commaPrice }}</span>
              <span class="price-unit">원</span>
            </div>
          </div>

          <div class="product-option-list">
            <div class="option-item" v-for="(option, optionIdx) in GET_PRODUCT_DETAIL.option" :key="option.option_id">
              <div class="option-text">
                <span class="option-title">{{ option.option_names | language }}</span>
                <span class="option-guide" :class="{ required: option.option_type==='OT001', optional: option.option_type==='OT002' }">
                  <span v-if="option.option_type === 'OT001'">(필수선택)</span>
                  <span v-else>(최대 {{ option.select_max }}개 선택가능)</span>
                </span>
              </div>

              <div class="option-list">
                <div 
                  class="option-detail" :class="{ checked: content.checked }" 
                  v-for="(content, contentIdx) in option.content" :key="content.name"
                  @click="toggleOption(option, optionIdx, content, contentIdx)"
                >
                  <span class="content-text">{{ content.name }}</span>
                  <span class="content-price">{{ content.price | symbolNumber }}원</span>
                </div>
              </div>
            </div>
          </div>

          <div class="product-adjustment-container">
            <div class="product-quantity-controller">
              <button class="quantity-controller" @click="changeQuantity({ type: 'DECREMENT' })"><span>－</span></button>
              <span class="product-quantity"><span>{{ GET_PRODUCT_DETAIL.count }}</span></span>
              <button class="quantity-controller" @click="changeQuantity({ type: 'INCREMENT' })">＋</button>
            </div>
            <div class="product-status">
              <div class="product-number">총 수량 {{ GET_PRODUCT_DETAIL.count }} 개</div>
              <div class="product-price-box"><span class="product-price">{{ GET_PRODUCT_DETAIL.totalCountPrice | commaPrice }}</span> 원</div>
            </div>
          </div>

          <div class="button-box">
            <div class="btn-wrap"><button class="btn-common btn-cart" @click="addCart()">장바구니</button></div>
            <div class="btn-wrap"><button class="btn-common btn-order" @click="directOrder()">바로 주문</button></div>
          </div>
        </div>        
      </div>

      <div class="product-content-container">
        <div class="product-content">
          <img :src="GET_PRODUCT_DETAIL.description_img" alt="" class="product-image">
        </div>
        <div class="product-description" v-html="GET_PRODUCT_DETAIL.description"></div>
      </div>

    </div>
  </div>
</template>

<script>
import vuex from 'vuex'
import language from '../../filters/language'
import commaPrice from '../../filters/commaPrice'
import symbolNumber from '../../filters/symbolNumber'

export default {
  data() {
    return {
      detailImgIdx: 0,
      productNumber: 1
    }
  },
  computed: {
    ...vuex.mapGetters({
      'GET_CATEGORY_LIST': 'im/GET_CATEGORY_LIST',
      'GET_CATEOGRY_ID': 'im/GET_CATEOGRY_ID',
      'GET_PRODUCT_DETAIL': 'im/GET_PRODUCT_DETAIL'
    }),
  },
  filters: {
    language,
    commaPrice,
    symbolNumber
  },
  created() {
    document.body.style.backgroundColor = "white";

    this.fetchProductDetail();
  },
  methods: {
    ...vuex.mapActions({
      'FETCH_PRODUCT_LIST': 'im/FETCH_PRODUCT_LIST',
      'FETCH_PRODUCT_DETAIL': 'im/FETCH_PRODUCT_DETAIL',
      'TOGGLE_PRODUCT_OPTION': 'im/TOGGLE_PRODUCT_OPTION',
      'CHANGE_PRODUCT_QUANTITY': 'im/CHANGE_PRODUCT_QUANTITY',
      'ADD_CART': 'im/ADD_CART',
      'CONTAIN_ORDER': 'im/CONTAIN_ORDER'
    }),
    async lookProductList(category) {
      let categoryID = category.category_id;
      let res = await this.FETCH_PRODUCT_LIST(categoryID);      
      this.$router.push(`/stationary/home`)
    },
    async fetchProductDetail() {
      let res = await this.FETCH_PRODUCT_DETAIL();
      console.log(res);
    },
    viewDetailImage(index) {
      this.detailImgIdx = index;
    },
    toggleOption(option, optionIndex, content, contentIndex) {
      this.TOGGLE_PRODUCT_OPTION({
        option, optionIndex, content, contentIndex
      })
    },
    changeQuantity(instructions) {
      this.CHANGE_PRODUCT_QUANTITY(instructions)
    },
    addCart() {
      this.ADD_CART();
    },
    directOrder() {
      this.CONTAIN_ORDER('detail');
      this.$router.push('/stationary/order')
    }
  }
}
</script>

<style lang="scss">
@import './common.scss';

#StationaryProductDetail {
  @include category;

  .product-container {
    display: flex;
    padding: 30px 64px;

    .product-image-container {
      flex-basis: 48%;
      padding-right: 12px;

      .product-image {
        .detail-img-box {
          position: relative;
          padding-top: 100%;

          .detail-img {
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            border-radius: 18px;
            background-position: center;
            background-size: cover;
          }
        }

        .detail-img-list-box {
          display: flex;
          flex-wrap: wrap;
          padding: 15px 0;

          .detail-img-list-wrap {
            flex-basis: 16.666667%;

            .detail-img-item-wrap {
              position: relative;
              padding-top: 100%;

              .detail-img-item {
                position: absolute;
                top: 5px; right: 5px; bottom: 5px; left: 5px;
                border-radius: 6px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

                opacity: .6;

                &.active {
                  border: 3px solid black;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
    .product-text-container {
      flex-basis: 52%;
      padding: {
        top: 20px;
        left: 12px;
      }
      
      .product-core {
        padding: 0 20px;

        .product-name {
          height: 56px;
          font-size: 18px;
          line-height: 1.56;;
        }
        .product-origin-price {
          height: 28px;
          color: #999;

          text-align: right;
          line-height: 1.56;
          text-decoration: line-through;
        }
        .product-price-row {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 34px;

          .discount-rate {
            height: 28px;
            font-size: 18px;
            line-height: 1.56;
          }
          .product-price {
            height: 34px;
            margin: {
              right: 6px;
              left: 10px;
            }
            font-size: 24px;
            font-weight: 900;
            color: red;
            line-height: 1.42;
          }
          .price-unit {
            height: 34px;
            font-size: 24px;
            font-weight: bold;
            text-align: right;
            line-height: 1.42;;
          }
        }
      }

      .product-option-list {
        margin-top: 20px;
        .option-item {
          margin-top: 10px;
          padding: 20px;
          border: 1px solid #e1e1e1;
          border-radius: 18px;

          .option-text {
            display: flex;
            align-items: center;
            height: 28px;

            .option-title {
              margin-right: 4px;
              font-size: 18px;
              font-weight: bold;
              line-height: 1.56;              
            }

            .option-guide {
              height: 22px;
              font-size: 13px;
              line-height: 1.69;

              &.required {
                color: $red;
              }
              &.optional {
                color: #888;
              }
            }
          }

          .option-list {
            .option-detail {
              display: flex;
              height: 24px;
              margin-top: 12px;
              padding-left: 34px;
              font-size: 14px;

              background-image: url('../../assets/images/new/option-unchecked.svg');
              background-position: left center;
              background-size: contain;
              background-repeat: no-repeat;

              line-height: 1.71;
              transition: all .2s;

              &:first-of-type {
                margin-top: 20px;
              }

              &.checked {
                background-image: url('../../assets/images/new/option-checked.svg');
              }

              .content-text {
                margin-right: 4px;
              }
              .content-price {
                font-size: 14px;
                font-weight: bold;
                color: $color-main;
              }
            }
          }
        }
      }

      .product-adjustment-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        padding: 30px 20px;
        border-top: 1px solid #999;

        .product-quantity-controller {
          display: flex;

          .quantity-controller {
            width: 46px;
            height: 46px;
            border: 1px solid #e1e1e1;
            border-radius: 45px;
            font-size: 28px;
            font-weight: bold;
            background-color: white;
            color: #666;

            line-height: 1.36;
          }
          .product-quantity {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;

            font-size: 24px;
            font-weight: bold;
          }
        }

        .product-status {
          text-align: right;

          .product-number {
            height: 24px;
            font-size: 14px;
            font-weight: bold;

            line-height: 1.71;
          }
          .product-price-box {
            margin-top: 4px;
            height: 40px;
            font-size: 30px;
            
            line-height: 1.33;

            .product-price {
              font-weight: 900;

              color: $color-main;
            }
          }
        }
      }

      .button-box {
        display: flex;
        .btn-wrap {
          flex-basis: 50%;

          &:first-child {
            padding-right: 5px;
          }
          &:last-child {
            padding-left: 5px;
          }

          .btn-common {
            width: 100%;
            height: 50px;
            border-radius: 25px;
            font-size: 20px;
            color: white;
          }
          .btn-cart {
            background-color: #212121;
          }
          .btn-order {
            background-color: $color-main;
          }
        }
      }
    }
  }

  .product-content-container {
    .product-content {
      width: 100%;
      max-width: 896px;
      margin: 0 auto;
      padding-bottom: 30px;
      text-align: center;

      .product-image {
        width: 100%;
        height: auto;
      }
    }

    .product-description {
      padding: 0 64px;
      color: #666;
    }
  }
}
</style>