<script src="../../server/app.js"></script>
<script src="../router/index.js"></script>
<template>
  <div id="orderPayment">
    <div class="store-inform-box">
      <div class="store-name" @click="test">{{ this.$store.state.storeInformation.store_names | language }}</div>
    </div>

    <div class="order-table order-option" v-if="getInfo.setting.packing_yn === 'Y'">
      <div class="table-header">
        <div class="header-title">{{ $t('ORDER_PAYMENT.ORDER_OPTION') }}</div>
      </div>      
      <div class="table-row hrz">
        <div class="row-package" :class="{ checked: !packing }" @click="togglePackage(false)">{{ $t('ORDER_PAYMENT.ORDER_OPTION_3') }}</div>
      </div>
      <div class="table-row">
        <div class="row-package" :class="{ checked: packing }" @click="togglePackage(true)">{{ $t('ORDER_PAYMENT.ORDER_OPTION_4') }}</div>
      </div>
    </div>

    <div class="order-table order-option">
      <div class="table-header">
        <div class="header-title">{{ $t('ORDER_PAYMENT.ORDER_RECEIVE_GUIDANCE') }}</div>
      </div>
      <div class="receive-guidance">
        메뉴가 준비되면 카카오 알림톡으로 알려드립니다.
      </div>
      <div class="table-row">
        <input type="text" name="" id="" :placeholder="$t('ORDER_PAYMENT.ORDER_RECEIVE_GUIDANCE_PLACEHOLDER')" class="requests" v-model="phoneNumber">
      </div>
      <div class="receive-guidance">
        <p>{{ $t('ORDER_PAYMENT.ORDER_RECEIVE_GUIDANCE_1') }}</p>
        <p>{{ $t('ORDER_PAYMENT.ORDER_RECEIVE_GUIDANCE_2') }}</p>
      </div>
    </div>

    <div class="order-table order-option" v-if="getInfo.setting.mobile_order_memo_type !== 'disabled' && !getUserId">
      <div class="table-header">
        <div class="header-title">{{ $t('ORDER_PAYMENT.REQUEST_GUIDE') }}({{ getInfo.setting.mobile_order_memo_type === 'required' ? '필수선택' : '선택사항' }})</div>
      </div>      
      <div class="table-row">
        <input type="text" name="" id="" :placeholder="this.$store.state.storeSetting.mobile_order_memo" class="requests" v-model="requestMsg">
      </div>
    </div>

    <div class="order-table order-methods">
      <div class="table-header">
        <div class="header-title">{{ $t('ORDER_PAYMENT.PAYMENT_METHOD') }}</div>
      </div>
      <div class="table-row" v-for="(method, idx) in getStore.information.payment_type_list" :key="idx">
        <div v-if="method.payment_access.mobile">
          <input type="radio" name="payment-methods" :id="`method_${idx}`" class="switch" :ref="`methods_${idx}`" :value="idx" v-model="paymentMethod">
          <div class="methods-item" @click="selectMethod(idx)">{{ method.titles | language }}</div>
        </div>
      </div>
      <div class="table-row" v-if="getStore.information.payment_type_list.length === 0">결제 수단이 없습니다.</div>
    </div>

    <div class="cart-synthesis-box" >
      <div class="cart-total-price">
        <div class="total-price-row">
          <div class="row-title">{{ $t('ORDER.TOTAL_MENU_AMOUNT') }}</div>
          <div class="row-content">{{ totalOrderInform(obOrder, 'originPrice') | commaNumber }} {{ $t('COMMON.NONEYARY_UNIT') }}</div>
        </div>
        <div class="total-price-row">
          <div class="row-title">{{ $t('ORDER.TOTAL_DISCOUNT_AMOUNT') }}</div>
          <div class="row-content">- {{ totalOrderInform(obOrder, 'DCPrice') | commaNumber }} {{ $t('COMMON.NONEYARY_UNIT') }}</div>
        </div>
          <div class="total-price-row">
          <div class="row-title">총 주문 개수</div>
          <div class="row-content">{{ totalOrderInform(obOrder, 'count') | commaNumber }} {{ '개' }}</div>
        </div>
      </div>
      <div class="cart-synthesis-horizon"></div>
      <div class="cart-total-inform" @click="_payTest($refs.form)">
        <div class="inform-title">{{ $t('ORDER.FINAL_PAYMENT_AMOUNT') }}</div>
        <div class="inform-amount">
          <!-- <div class="inform-count">{{ totalOrderInform(obOrder, 'count') | commaNumber }}</div> -->
          <div class="inform-price">{{ totalOrderInform(obOrder, 'price') | commaNumber }} {{ $t('COMMON.NONEYARY_UNIT') }}</div>
        </div>
      </div>
    </div>

    <div class="term-container">
      <div class="term-title">
        <input id="Term" type="checkbox" v-model="term">
        <label class="term-box" for="Term"><span class="checkbox-image" :class="{ 'checked': term }"></span>개인 정보 제공에 동의하고 주문 진행합니다.</label>
        <!-- <span>개인 정보 제공에 동의하고 주문 진행합니다.</span>{{term}} -->
      </div>
      <div class="term-link" @click="toggleModalTerm()">{{ $t('ORDER_PAYMENT.GUIDE_MSG_1') }}</div>
      <div class="term-contents-box">
        {{ $t('ORDER_PAYMENT.GUIDE_MSG_2') }}
      </div>
    </div>

    <div class="btn-container">
      <button class="btn-order-payment" @click="goOrderInform()" :disabled="getStore.information.payment_type_list.length === 0 || !term || phoneNumber.length < 10 || (getInfo.setting.mobile_order_memo_type === 'required' && requestMsg === '')">{{ $t('ORDER_PAYMENT.DO') }}</button>
<!--      <button class="btn-order-payment" @click="goOrderInform()" :disabled="getStore.information.payment_type_list.length === 0">{{ $t('ORDER_PAYMENT.DO') }}</button>-->
    </div>

    <div class="modal-background" v-if="modalTerm">
      <div class="outer">
        <div class="inner">
          <div class="modal-term centered">
            <div class="term-content">
              <div class="term-header">
                <span class="header-title">{{ $t('MOBILE_PRIVACY_COLLECTION_CONSENT') }}</span>
                <button class="close-modal" @click="toggleModalTerm()"></button>
              </div>
              <div class="term-body">
                <div class="body-content">
                  <div class="content-scroll">
                    <p>[개인정보 제3자 제공에 대한 동의]</p>
                    
                    <p>귀하의 개인정보는 제 3자 제공 동의를 선택한 경우 「개인정보보호법」 에 따라 다음과 같이 제3자에게 제공됩니다.</p>
                    <p>개인정보의 제공</p>
                    <p>개인정보를 제공받는 자</p>
                    <p>ㆍ(주)엘릭서페이</p>
                    <p>제공받는자의 개인정보 수집 이용</p>
                    <p>제공받는자의 개인정보 수집 이용 목적</p>
                    <p>① 주문관리</p>
                    <p>② 주문 알림 전송 및 주문 내역 제공</p>
                    <p>제공할 개인정보의 항목</p>
                    <p>① 결제 상태 정보</p>
                    <p>② 상품 구매, 취소, 반품 교환 정보</p>
                    <p>③ 휴대폰번호</p>
                    <p>제공받는자의 개인정보 보유 이용기간</p>
                    <p>동의일로부터 1년</p>
                    <p>개인정보의 제3자 제공 동의</p>
                    <p>귀하는 개인정보의 선택적인 제공에 대한 동의를 거부할 수 있습니다. 다만, 동의 거부시 주문 서비스 이용에 제한이 있을 수 있습니다.</p>
                    <p>고객이 주문을 완료 하면 개인정보 제3자 제공에 대해 동의한 것으로 간주합니다.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="kspay-form">
      <!-----------------------------------------<Part 1. KSPayWeb Form: 결과페이지주소 설정 > ---------------------------------------->
      <!--결제 완료후 결과값을 받아처리할 결과페이지의 주소-->
      <form id="KSPayWeb" name="KSPayWeb" method="post" action="./kspay_result" ref="form">
        <!-- 결과값 수신 파라메터, value값을 채우지마십시오. KSPayRcv.asp가 실행되면서 채워주는 값입니다-->
        <!--------------------------------------------------------------------------------------------------------------------------->
        정보를 기입하신 후 지불버튼을 눌러주십시오
        <!----------------------------------------------- < Part 2. 고객에게 보여지지 않는 항목 > ------------------------------------>
        <!--이부분은 결제를 위해 상점에서 기본정보를 세팅해야 하는 부분입니다.														-->
        <!--단 고객에게는 보여지면 안되는 항목이니 테스트 후 필히 hidden으로 변경해주시길 바랍니다.									-->
        결제수단 :

        <select name="sndPaymethod">
          <option value="1000000000" selected>신용카드</option>
          <option value="0100000000">가상계좌</option>
          <option value="0010000000">계좌이체</option>
          <option value="0000010000" >휴대폰결제</option>
        </select>
        상점아이디 :
        <!--      <input type=text name=sndStoreid size=10 maxlength=10 value="2999199999">-->
        <input type="text" name="sndStoreid" size="10" maxlength="10">
        <!-- 화폐단위 원화로 설정 : 410 또는 WON -->
        화폐단위 :
        <input type="text" name="sndCurrencytype" size="30" maxlength="3" value="WON">
        <!--상품명은 30Byte(한글 15자) 입니다. 특수문자 ' " - ` 는 사용하실수 없습니다. 따옴표,쌍따옴표,빼기,백쿼테이션 -->
        주문번호 :
        <input type="text" name="sndOrdernumber" size="30" maxlength="30">
        <!--주민등록번호는 필수값이 아닙니다.-->
        주민번호 :
        <input type="text" name="sndAllregid" size="30" maxlength="13" value="">
        신용카드 기본항목
        <!--상점에서 적용할 할부개월수를 세팅합니다. 여기서 세팅하신 값은 KSPAY결재팝업창에서 고객이 스크롤선택하게 됩니다 -->
        <!--아래의 예의경우 고객은 0~12개월의 할부거래를 선택할수있게 됩니다. -->
        할부개월수  :
        <input type="text" name="sndInstallmenttype" size="30" maxlength="30" value="0:2:3:4:5:6:7:8:9:10:11:12">
        <!--무이자 구분값은 중요합니다. 무이자 선택하게 되면 상점쪽에서 이자를 내셔야합니다.-->
        <!--무이자 할부를 적용하지 않는 업체는 value='NONE" 로 넘겨주셔야 합니다. -->
        <!--예 : 모두 무이자 적용할 때는 value="ALL" / 무이자 미적용할 때는 value="NONE" -->
        <!--예 : 3,4,5,6개월 무이자 적용할 때는 value="3:4:5:6" -->
        무이자구분  :
        <input type="text" name="sndInteresttype" size="30" maxlength="30" value="NONE">
        신용카드표시구분  :
        <input type="text" name="sndShowcard" size="30" maxlength="30" value="C">
        <!----------------------------------------------- <Part 3. 고객에게 보여주는 항목 > ----------------------------------------------->
        고객에게 보여주는 항목
        <!--상품명은 30Byte(한글 15자)입니다. 특수문자 ' " - ` 는 사용하실수 없습니다. 따옴표,쌍따옴표,빼기,백쿼테이션 -->
        상품명 :
        <input type="text" name="sndGoodname" size="30" maxlength="30">
        가격 :
        <input type="text" name="sndAmount" size="30" maxlength="9" />
        성명 :
        <input type="text" name="sndOrdername" size="30" maxlength="20">
        <!--KSPAY에서 결제정보를 메일로 보내줍니다.(신용카드거래에만 해당)-->
        전자우편 :
        <input type="text" name="sndEmail" size="30" maxlength="50" value="">
        <!--카드사에 SMS 서비스를 등록하신 고객에 한해서 SMS 문자메세지를 전송해 드립니다.-->
        <!--전화번호 value 값에 숫자만 넣게 해주시길 바랍니다. : '-' 가 들어가면 안됩니다.-->
        이동전화 :
        <input type="text" name="sndMobile" size="30" maxlength="12" value="">
        <input type="button" value=" 지 불 " @click="_payTest($refs.form)">
        <!--                                    <input type="""button" value=" 지 불 " onClick="javascript:_pay(document.KSPayWeb);">-->
        <input type="hidden" name="sndCharSet" value="utf-8">									<!-- 가맹점 문자열 체어셋 -->
        <input type="hidden" name="sndReply"           		  value="">
        <input type="hidden" name="sndEscrow"          	    value="0">                           <!--에스크로적용여부-- 0: 적용안함, 1: 적용함 -->
        <input type="hidden" name="sndVirExpDt"     		    value="">                            <!-- 마감일시 -->
        <input type="hidden" name="sndVirExpTm"     		    value="">                            <!-- 마감시간 -->
        <input type="hidden" name="sndStoreName"       	    value="케이에스페이(주)">                  <!--회사명을 한글로 넣어주세요(최대20byte)-->
        <input type="hidden" name="sndStoreNameEng"    	    value="kspay">                       <!--회사명을 영어로 넣어주세요(최대20byte)-->
        <input type="hidden" name="sndStoreDomain"     	    value="https://www.naver.com"> <!-- 회사 도메인을 http://를 포함해서 넣어주세요-->
        <input type="hidden" name="sndGoodType"		   		    value="1">								           <!--실물(1) / 디지털(2) -->
        <input type="hidden" name="sndUseBonusPoint"		    value="">   							           <!-- 포인트거래시 60 -->
        <input type="hidden" name="sndRtApp"		   	        value="">									           <!-- 하이브리드APP 형태로 개발시 사용하는 변수 -->
        <input type="hidden" name="sndStoreCeoName"         value="신동우">                            <!--  카카오페이용 상점대표자명 -->
        <input type="hidden" name="sndStorePhoneNo"         value="19553744">                            <!--  카카오페이 연락처 -->
        <input type="hidden" name="sndStoreAddress"         value="서울 강남구 남부순환로365길 5 418호">                            <!--  카카오페이 주소 -->
      </form>
    </div>

    <GuideSelection></GuideSelection>


  </div>
</template>

<script>
import vuex from 'vuex'
import axios from 'axios';

import GuideSelection from '../components/GuideSelection.vue'

import language from '../filters/language.js'

export default {
  components: {
    GuideSelection
  },
  data() {
    return {
      payMethods: [

      ],
      paymentMethod: 0,
      packing: false,
      modalTerm: false,
      requestMsg: '',
      totalPrice: null,
      totalDCPrice: null,
      totalCount: null,
      term: false,
      phoneNumber: '',
      ksp: {
        amount: 1234
      }
    }
  },
  computed: {
    ...vuex.mapGetters({
      'getStore': 'getStore',
      'getUserId': 'getUserId',
      'getStoreImformation': 'getStoreImformation',
      'getOrderInform': 'getOrderInform',
    }),
    obOrder() {
      return this.$store.state.order;
    },
    getKsp() {
      return this.kspay;
    },
    getInfo() {
      return this.getStore?.information;
    },
    getKsForm() {
      return this.$refs.form
    }
  },
  filters: {
    commaNumber(value) {
      // add
      let negative = false;
      if(value < 0) {
        negative = true;
        value *= -1;
      }
      
      value = '' + value;
      let floatingPoint = value.split('.');
      value = floatingPoint[0];

      return `${negative? '- ' : ''}${value.split('').reverse().reduce((acc, digit, i) => {
        if (i > 0 && i % 3 === 0) acc.push(',')
        return [...acc, digit]
      }, []).reverse().join('')}`;
    },
    language
  },
  created() {
    this.requestMsg = this.getUserId || '';
  },
  methods: {
    ...vuex.mapActions({
      'SHOW_INPUT_PHONE_MODAL': 'SHOW_INPUT_PHONE_MODAL',
      'PAY_QR_CODE': 'qr/PAY_QR_CODE',
      'SET_ERROR': 'SET_ERROR'
    }),
    togglePackage(value) {
      this.packing = value;
    },
    selectMethod(idx) {
      this.$refs[`methods_${idx}`][0].click();
    },
    totalOrderInform(cartList, key) {
      let totalPrice = 0; 
      let totalDCPrice = 0;
      let totalCount = 0;

      for(let cart in cartList) {
        let itemTotalPrice = 0;
        let itemTotalDCPrice = 0;
        let itemTotalCount = 0;

        let cartItem = cartList[cart];

        itemTotalPrice += cartItem.price;
        itemTotalDCPrice += cartItem.dc_price;
        itemTotalCount += cartItem.count;

        for(let opt in cartItem.option) {
          let optionItem = cartItem.option[opt];

          itemTotalPrice += optionItem.price;
        }

        itemTotalPrice = itemTotalPrice*itemTotalCount;
        itemTotalDCPrice = itemTotalDCPrice*itemTotalCount;

        totalPrice += itemTotalPrice;
        totalDCPrice += itemTotalDCPrice;
        totalCount += itemTotalCount;
      }

      this.totalPrice = totalPrice;
      this.totalDCPrice = totalDCPrice;
      this.totalCount = totalCount;

      if (key === 'originPrice') {
        return totalPrice+totalDCPrice;
      } else if (key === 'DCPrice') {
        return totalDCPrice;
      } else if (key === 'count') {
        return totalCount;
      } else if (key === 'price') {
        return totalPrice;
      }

      return totalPrice;
    },
    toggleModalTerm() {
      this.modalTerm = !this.modalTerm;
    },
    goOrderInform() {
      this.$store.commit('setStates', [
        { target: 'orderInform', key: 'packing', value: this.packing },
        { target: 'orderInform', key: 'method', value: this.paymentMethod },
        { target: 'orderInform', key: 'requestMsg', value: this.requestMsg },
        { target: 'orderInform', key: 'totalPrice', value: this.totalPrice },
        { target: 'orderInform', key: 'totalDCPrice', value: this.totalDCPrice },
        { target: 'orderInform', key: 'totalCount', value: this.totalCount },
        { target: 'orderInform', key: 'code', value: this.getStore.information.payment_type_list[this.paymentMethod].code },
        { target: 'orderInform', key: 'extra', value: this.getStore.information.payment_type_list[this.paymentMethod].extra },
      ])
      
      this.payDirect();
    },
    getOrderTitle() {
      let order = this.$store.state.order;
      let title = '';
      if(order.length > 1) {
        title = `${language(order[0].menu_names)} 외 ${order.length-1}건`
      } else {
        title = `${language(order[0].menu_names)}`;
      }

      return title;
    },
    async payDirect() {
      let data = {
        language: this.$i18n.locale,
        store_id: this.$store.state.storeID,
        store_name: language(this.getStoreImformation.store_names),
        payment_type_code: this.getOrderInform.code,
        payment_type_extra: this.getOrderInform.extra,
        user_tel: this.phoneNumber,
        title: this.getOrderTitle(),
        packing_yn: this.$store.state.orderInform.packing? "Y" : "N",
        order_fail_time: this.$store.state.storeSetting.order_fail_time,
        request_t: this.$store.state.orderInform.requestMsg,
        order: this.$store.state.order
      }

      // console.log(data)
      let res = await this.PAY_QR_CODE(data);

      if(res.data && res.data.result && res.data.result.message) {
        this.SET_ERROR({
          message: res.data.result.message,
          code: res.data.result.code
        })

        this.$router.push(`${this.$store.state.gRoot}/page/error`);
        return;
      }

      if (res.status == 200) {
        if(this.getStore.information.payment_type_list[this.paymentMethod].extra === "KSNET_USB") {
          console.log(res.data);
          this.ksp = { ...this.ksp, ...res.data };
          console.log(this.ksp)

          // document.querySelector('input[name="sndStoreid"]').value = res.data.ksnet_store_id;
          // document.querySelector('input[name="sndOrdernumber"]').value = res.data.order_no;
          // document.querySelector('input[name="sndGoodname"]').value = this.getOrderTitle();
          // document.querySelector('input[name="sndAmount"]').value = res.data.amount;

          document.querySelector('input[name="sndOrdername"]').value = language(this.getStoreImformation.store_names);

          // document.querySelector('input[name="sndReply"]').value = 'https://github.com/rae-han/learn-algorithm/blob/main/programmers/level1/72410_newIdRecommendation.js';

          if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {

          }

          console.log(this.$refs.form)
          // this._payTest(this.$refs.form);
          console.log(this.getKsForm);
          this._payTest(this.getKsForm);
          return;
        }

        if(this.needChildWindow(res.data, this.getStore.information.payment_type_list[this.paymentMethod])) {
          return 0;
        }
        let url = res.data.qr_url;

        location.href = url
      }
    },
    needChildWindow(result, method) {
      console.log(method)
      let paymentMethods = ['EX_WALLET', 'ALLAT'];
      let filter = paymentMethods.find(item => item === method.extra);

      if(filter) {
        var win = window.open(result.qr_url, '_blank');
        win.focus();
        return true;
      }
    },
    _payTest(_frm) {
      // sndReply는 kspay_wh_rcv.jsp (결제승인 후 결과값들을 본창의 KSPayWeb Form에 넘겨주는 페이지)의 절대경로를 넣어줍니다.
      _frm.sndReply.value       = this.getLocalUrl("result") ;

      _frm.sndStoreid.value     = this.ksp.ksnet_store_id;
      _frm.sndOrdernumber.value = this.ksp.order_no
      _frm.sndGoodname.value    = this.getOrderTitle();
      _frm.sndAmount.value      = this.ksp.amount;
      // _frm.sndStoreid.value = "2999199999";
      // _frm.sndOrdernumber.value = "test_1234";
      // _frm.sndGoodname.value = "this_is_test";
      // _frm.sndAmount.value = "1004";

      _frm.sndStoreCeoName.value = '신동우';
      _frm.sndStorePhoneNo.value = '19553744';
      _frm.sndStoreAddress.value = '서울 강남구 남부순환로365길 5 418호';

      _frm.sndOrdername.value = language(this.getStoreImformation.store_names);

      var agent = navigator.userAgent;
      var midx		= agent.indexOf("MSIE");
      var out_size	= (midx != -1 && agent.charAt(midx+5) < '7');

      //_frm.target = '_blank';
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        _frm.action ='https://kspay.ksnet.to/store/KSPayMobileV1.4/KSPayPWeb.jsp';
      } else {
        _frm.action ='https://kspay.ksnet.to/store/KSPayWebV1.4/KSPayPWeb.jsp';
      }

      _frm.submit();
    },
    getLocalUrl(mypage) {
      console.log(mypage);
      var myloc = location.href;
      console.log(myloc);
      return myloc.substring(0, myloc.lastIndexOf('/')) + '/' + mypage;
    },
    test() {
      this.phoneNumber = "01071121906";
      this.term = true;
      this.requestMsg = 'test';
    }
  },
}
</script>

<style lang="scss">
@mixin placeholders($color: #000) {
  &::placeholder { color: $color }
  &::-webkit-input-placeholder { color: $color }
  &::-moz-placeholder { color: $color }
  &:-ms-input-placeholder { color: $color }
  &:-moz-placeholder { color: $color }
}

#orderPayment {
  .store-inform-box {
    height: 47px;
    padding: 0 20px;

    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;

    background-color: #fff;

    text-align: center;
    line-height: 47px;

    .store-name {
      font-size: 15pt;

      color: #ff8b45;
    }
  }

  .order-table {
    .table-header {
      height: 50px;
      padding: 0 20px;

      font-size: 13pt;

      color: #808080;
      color: black;

      line-height: 50px;
    }

    .table-row {
      height: 60px;
      padding-left: 20px;

      background-color: #fff;
      
      line-height: 60px;

      .switch {
        display: none;

        font-size: 14pt;

        color: #424242;
      }
    }

    .receive-guidance {
      padding: 10px 20px;
      border-bottom: 1px solid #ebebeb;
      font-size: 11pt;
      color: #999;
      line-height: 1.5;
    }
  }

  .order-option {
    .table-row {
      &.hrz {
        border-bottom: 1px solid #ebebeb;
      }

      .row-package {
        border-bottom: 1px solid #f7f7f7;

        background-image: url('../assets/images/bt_radio_n.png');
        background-size: 36px 44.666667px;
        background-position: right 13.333333px center;
        background-repeat: no-repeat;

        transition: all .1s;

        &.checked {
          background-image: url('../assets/images/bt_radio_a.png')
        }
      }

      &:last-child {
        .row-content {
          border: none;
        }
      }

      .requests {
        width: 100%;
        height: 100%;
        padding-right: 20px;

        font-size: 14pt;

        color: #424242;

        @include placeholders(#b3b3b3);
      }
    }
  }

  .order-methods {
    .table-row {
      .methods-item {
        border-bottom: 1px solid #f7f7f7;

        background-image: url('../assets/images/bt_radio_n.png');
        background-size: 36px 44.666667px;
        background-position: right 13.333333px center;
        background-repeat: no-repeat;

        transition: all .3s;
      }

      .switch:checked + .methods-item  {
        background-image: url('../assets/images/bt_radio_a.png');
      }
    }
  }


  .cart-synthesis-box {
    margin-top: 20px;

    border-bottom: 1px solid #ebebeb;

    background-color: #fff;

    .cart-total-price {
      padding: 14px 20px 12px;

      .total-price-row {
        overflow: hidden;
        
        height: 24px;

        font-size: 13pt;

        line-height: 24px;

        .row-title {
          float: left;

          color: #999;
        }

        .row-content {
          float: right;
          
          color: #424242;
        }
      }
    }

    .cart-synthesis-horizon {
      margin: 0 11px;
      border-bottom: 1px solid #f7f7f7;
    }

    .cart-total-inform {
      overflow: hidden;
      
      height: 60px;
      padding: 0 20px;

      font-size: 13pt;

      color: #424242;

      line-height: 60px;

      .inform-title {
        float: left;
      }
      .inform-amount {
        float: right;

        .inform-count {
          float: left;

          width: 30px;
          margin: 0 4px;

          text-align: center;
        }
        .inform-price {
          float: left;

          width: 92px;

          font-size: 14pt;
          font-weight: 700;

          color: #4a9cdd;

          text-align: right;
        }
      }
    }
  }

  .term-container {
    padding: 34px 20px 33px;
    padding-bottom: 83px;

    text-align: center;

    .term-title {
      // height: 22px;

      font-size: 13pt;

      color: #424242;

      line-height: 22px;

      #Term {
        overflow: hidden;
        height: 0px;
        width: 0px;
        margin: 0;
        border: none;
      }

      .term-box {
        display: flex;
        justify-content: center;

        .checkbox-image {
          display: inline-block;
          width: 24px;
          height: 24px;
          margin-right: 6px;

          background-image: url('../assets/images/btn_checkbox_fill_n.png');
          background-size: 37px;
          background-position: center;
          background-repeat: no-repeat;

          transition: all .3s;
          cursor: pointer;

          &.checked {
            background-image: url('../assets/images/bt_check_a.png');
          }
        }
      }      
    }
    .term-link {
      height: 17px;
      margin-top: 6px;

      font-size: 12pt;

      color: #999;

      line-height: 17px;
      text-decoration: underline;
    }

    .term-contents-box {
      height: 44px;
      margin-top: 13px;

      font-size: 11pt;

      color: #b3b3b3;

      line-height: 14pt;
    }
  }

  .btn-container {
    position: fixed;
    @include desktop();

    right: 0;
    bottom: 0;
    left: 0;

    .btn-order-payment {
      display: inline-block;

      width: 100%;
      height: 50px;

      font-size: 17.333333pt;

      background-color:#ff8b45;
      color: #fff;

      &:disabled {
        background-color: #ccc;
      }

      &:active {
        background-color: #d7773c;
      }
    }
  }

  .modal-background {
    position: fixed;

    z-index: 300;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 500px;
    margin: 0 auto;

    background-color: rgba(#000, .6);

    .modal-term {
      padding: 0 25px;

      .term-content {
        background-color: #fff;

        text-align: left;

        .term-header {
          position: relative;

          height: 48px;
          padding: 0 21px;

          border-bottom: 1px solid #ebebeb;

          .header-title {
            font-size: 14pt;
            font-weight: 700;
            color: #424242;
            line-height: 48px;
          }

          .close-modal {
            display: inline-block;
            position: absolute;

            top: 0;
            right: 3px;

            width: 38px;
            height: 100%;

            background-image: url('../assets/images/bt_close_1_n.png');
            background-size: 38px 38px;
            background-position: center;
            background-repeat: no-repeat;

            &:active {
              background-image: url('../assets/images/bt_close_1_p.png');
            }
          }
        }

        .term-body {
          padding: 18px 0;

          font-size: 11pt;

          color: #808080;

          line-height: 15.333333pt;

          .body-content {
            overflow-y: scroll;

            height: 450px;
            padding: 0 21px;
          }
        }
      }
    }
  }

  .kspay-form {
    z-index: -1000;
    position: fixed;
    top: -1000px;
    right: -1000px;
  }
}
</style>
