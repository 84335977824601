<template>
<header id="StationaryHeader">
  <div class="limit-width">
    <div 
      class="header-left" 
      :style="{ backgroundImage: `url('${ isNotLogo ? '' : GET_STORE_INFORMATION.mobile_img}')` }" 
      @click="routeHome"
    >
      <div v-if="isNotLogo" class="store-name">{{ GET_STORE_INFORMATION.store_name }}</div>
    </div>
    <div class="header-right">
      <!-- <button class="order-history-search header-btn">주문내역 조회</button> -->
      <button class="cart header-btn" @click="lookCart()">
        <span>장바구니</span>
        <span class="cart-count">{{ GET_CART.length }}</span>
      </button>
      <span class="languages-box">
        <button class="languages"></button>
      </span>
    </div>
  </div>
</header>

</template>

<script>
import vuex from 'vuex';

export default {
  computed: {
    ...vuex.mapGetters({
      'GET_CART': 'im/GET_CART',
      'GET_STORE_INFORMATION': 'im/GET_STORE_INFORMATION'
    }),
    isNotLogo() {
      const { mobile_img: logo } = this.GET_STORE_INFORMATION;
      const regrex = /no_img/;

      console.log('logo logo logo logo')
      console.log(regrex.test(logo));
      return regrex.test(logo);
    }
  },
  methods: {
    lookCart() {
      this.$router.push(`/stationary/cart`)
    },
    routeHome() {
      this.$router.push('/stationary/home')
    }
  }
}
</script>

<style lang="scss">
#StationaryHeader {
  height: 60px;
  background-color: white;

  .limit-width {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100%;
    padding: {
    left: 30px;
    right: 20px;
  }
  }

  .header-left {
    display: flex;
    width: 240px;
    height: 100%;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    align-items: center;

    .store-name {
      font-size: 24px;
      font-weight: bold;

      text-align: left;
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .header-btn {
      height: 100%;
      margin-left: 18px;
      font-size: 14px;
      font-weight: bold;
    }

    .cart-count {
      margin-left: 2px;
      font-weight: 900;
      color: $color-main
    }

    .languages-box {
      width: 60px;
      height: 60px;
      margin-left: 10px;
      padding: 9px;

      .languages {
        width: 100%;
        height: 100%;
        border: solid 1px #e1e1e1;
        border-radius: 21px;
        background-image: url('../../assets/images/flags/korea.jpg');
        background-size: 42px;
        background-position: center;
        background-repeat: no-repeat;
      }

    }
  }

}
</style>