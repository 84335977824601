<script src="../router/index.js"></script>
<template>
  <div id="EntryPoint">
    <div @click="enterCustomer()">상점 아이디 2EE81511 입장!</div>
  </div>
</template>

<script>
export default {
  methods: {
    enterCustomer: function() {
      this.$router.push(`${this.$store.state.gRoot}/main/2EE81511`);
    },
    autoEnterCustomer: function() {
      let storeID = this.$route.params.storeID;
      let userId = this.$route.query.specified || null;
      console.log('userId', userId);

      this.$store.commit('setState', {
        key: 'userId',
        value: userId,
      })

      this.$store.commit('setState', {
        key: 'gRoot',
        value: '/cus'
      });

      this.$router.push(`${this.$store.state.gRoot}/main/${storeID}`)
    },
    branchRouter(storeID) {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];

      let isMobile = toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem);
      });

      console.log('isMobile', isMobile)

      // if(!(navigator.userAgent.indexOf('Mobi') > -1)) {
      // if(!isMobile) {
      //   this.$router.push(`/stationary/home/${storeID}`)
      //   return true;
      // }
    }
  },
  created() {
    this.autoEnterCustomer();
  },
  mounted() {
    // console.log(navigator)
  }
}
</script>

<style>

</style>
