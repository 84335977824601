import Vue from 'vue';
import Vuex from 'vuex';
// import VuexPersistence from 'vuex-persist';
import axios from 'axios'
import VuexPersistence from 'vuex-persist/dist/umd';

import qr from './modules/qr.js'
import lang from './modules/lang.js'
import im from './modules/im.js'
import store from './modules/store'

Vue.use(Vuex);

let gRoot = '/cus'

let dev = false;

let elixirpayMenucApi = process.env.VUE_APP_API_URL;
// elixirpayMenucApi = 'https://asp.test.elixir.codes/api/menuc'
elixirpayMenucApi = 'https://asp.elixirpay.co.kr/api/menuc'

if(dev) {
  elixirpayMenucApi = `http://10.100.200.242:8000/api/menuc`;
  gRoot = '/cus';
}

const vuexLocal = new VuexPersistence({
  key: 'asp-menu-3',
  storage: window.localStorage
})

export default new Vuex.Store({
  modules: {
    qr,
    lang,
    im,
    store,
  },
  state: {
    gRoot: gRoot,
    menucApi: elixirpayMenucApi,
    preferences: {
      headerText: '제목',
      fixedFooter: false
    },
    storeID: '',
    userId: null,
    storeInformation: {
      store_names: null,
    },
    storeSetting: {},
    categoryList: [],
    categoryID: '',
    productList: [],
    productID: '',
    productDetail: {},
    order: [],
    cart: [],
    orderInform: {},
    toast: {
      visible: false,
      show: false,
      msg: 'null msg'
    },
    regNumber: '',
    history: {
      phoneNubmer: '',
      list: [],
      key: {},
      detail: {}
    },
    menuStatus: false,
    productDetailImages: {
      display: false,
      img: []
    },
    inputPhoneModal: false,
    policyPIPC: false,
    termModal: { 
      visible: false, 
      index: 0 
    },
    browser: {
      deprecated: false
    },
    locationURL: {
      origin: ''
    },
    error: {
      message: '',
      code: ''
    }
  },
  getters: {
    getStore (state) {
      return {
        information: state.storeInformation
      }
    },
    getStoreID (state) {
      return state.storeID;
    },
    getUserId (state) {
      return state.userId;
    },
    getStoreImformation (state) {
      return state.storeInformation;
    },
    getProductDetail(state) {
      return state.productDetail;
    },
    getAPIURL (state) {
      return state.menucApi
    },
    getOrderInform (state) {
      return state.orderInform;
    },
    getMenuStatus (state) {
      return state.menuStatus;
    },
    getProductDetailImages (state) {
      return state.productDetailImages;
    },
    getCart (state) {
      return state.cart;
    },
    getInputPhoneModal(state) {
      return state.inputPhoneModal;
    },
    getPolicyPIPC(state) {
      return state.policyPIPC;
    },
    GET_BROWSER(state) {
      return state.browser;
    },
    GET_LOCATION_URL(state) {
      return state.locationURL;
    },
    GET_ERROR_STATUS(state) {
      return state.error;
    },
    GET_TERM_MODAL(state) {
      return state.termModal
    }
  },
  mutations: {
    initStore(state) {
      // for (let key in state) {
      //   // console.log(`${key} : ${state[key]}`);
      // }

      state.menucApi = process.env.ROOT_API;

      if(dev === true) {
        state.menucApi = elixirpayMenucApi;
      }
    },
    setStoreID(state, storeID) {
      state.storeID = storeID;
    },
    setPreference(state, params) {
      state.preferences[params.key] = params.value;
    },
    setState(state, params) {
      state[params.key] = params.value;
    },
    setStates(state, params) {
      for(let idx in params) {
        let store;
        
        if(params[idx].target !== null) {
          store = state[params[idx].target];
        } else {
          store = state;
        }
        store[params[idx].key] = params[idx].value;
      }
    },
    setDetail(state, data) {
      state.history.key = data
      state.history.detail = data
    },
    setStoreInformaiton(state, storeInformation) {
      state.storeInformation = storeInformation;
    },
    setCategoryList(state, categoryList) {
      state.categoryList = categoryList;
    },
    cartCount(state, cart) {
      if (cart.calc === 'add') {
        state.cart[cart.idx].count++;
      } else if (cart.calc === 'sub') {
        state.cart[cart.idx].count--;
      }
    },
    raplaceCartOrder(state, cart) {
      state.order = cart;
    },
    removeCartItem(state, obj) {

      state.cart.splice(obj.idx, 1);
    },
    showToastMsg(state, msg) {
      state.toast.msg = msg;

      state.toast.visible = true;
      setTimeout(() => {
        state.toast.show = true;

        setTimeout(() => {
          state.toast.show = false;
        }, 2000);

        setTimeout(() => {
          state.toast.visible = false;
        }, 3000)
      }, 1)
    },
    hideToastMsg(state) {
      state.toast.show = false;
      state.toast.visible = false;
    },
    toggleSideMenu(state) {
      state.menuStatus = !state.menuStatus;
    },
    hideSideMenu(state) {
      state.menuStatus = false;
    },
    SHOW_ENLARGE_DETAIL_IMGS(state, data) {
      state.productDetailImages = {
        display: true,
        img: data
      };
    },
    HIDE_ENLARGE_DETAIL_IMGS(state) {
      state.productDetailImages = {
        display: false,
        img: []
      };
    },
    SHOW_INPUT_PHONE_MODAL(state) {
      state.inputPhoneModal = true;
    },
    HIDE_INPUT_PHONE_MODAL(state) {
      state.inputPhoneModal = false;
    },
    TOGGLE_POLICY_PIPC(state, data) {
      state.policyPIPC = data
    },
    SET_PRODUCT_DETAIL(state, data) {
      state.productID = data.productID;
      state.productDetail = data.productDetail
    },
    SET_PRODUCT_DETAIL_PRICE(state, data) {
      console.log(state.productDetail)
      state.productDetail.price = data.amount;
      state.productDetail.count = data.count;

      state.order = [{
        ...state.productDetail
      }]
      // console.log(state.order)
    },
    TOGGLE_BROWSER_DEPRECATED(state, data) {
      state.browser.deprecated = data;
    },
    SAVE_LOCATION_URL(state, data) {
      state.locationURL = data;
    },
    SET_ERROR(state, data) {
      state.error = data;
    },
    SET_TERM_MODAL(state, data) {
      state.termModal = data
    }
  },
  actions: {
    TOGGLE_SIDE_MENU(context) {
      context.commit('toggleSideMenu')
    },
    SHOW_ENLARGE_DETAIL_IMGS(context, data) {
      context.commit('SHOW_ENLARGE_DETAIL_IMGS', data.menu_sub_img)      
    },
    HIDE_ENLARGE_DETAIL_IMGS(context) {
      context.commit('HIDE_ENLARGE_DETAIL_IMGS')      
    },
    SHOW_INPUT_PHONE_MODAL(context) {
      context.commit('SHOW_INPUT_PHONE_MODAL');
    },
    HIDE_INPUT_PHONE_MODAL(context) {
      context.commit('HIDE_INPUT_PHONE_MODAL');
    },
    SHOW_POLICY_PIPC(context) {
      context.commit('TOGGLE_POLICY_PIPC', true)
    },
    HIDE_POLICY_PIPC(context) {
      context.commit('TOGGLE_POLICY_PIPC', false)
    },
    async SET_PRODUCT_DETAIL(context, data) {
      let res = await axios({
        method: 'post',
        url: `${process.env.VUE_APP_API_URL}/menu/detail`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          store_id: data.storeID,
          menu_id: data.productID
        }
      })
      // console.log(res.data)

      context.commit('SET_PRODUCT_DETAIL', {
        productID: data.productID,
        productDetail: { ...res.data, count: 1 }
      })

      return res;
    },
    SET_PRODUCT_DETAIL_PRICE(context, data) {
      // console.log(data)
      context.commit('SET_PRODUCT_DETAIL_PRICE', data);
    },
    SHOW_BROWSER_DEPRECATED(context, data) {
      context.commit('TOGGLE_BROWSER_DEPRECATED', true)
    },
    HIDE_BROWSER_DEPRECATED(context, data) {
      context.commit('TOGGLE_BROWSER_DEPRECATED', false)
    },
    SAVE_LOCATION_URL(context, data) {
      context.commit('SAVE_LOCATION_URL', data)
    },
    SET_ERROR(context, data) {
      context.commit('SET_ERROR', data)
    },
    SET_TERM_MODAL(context, data) {
      context.commit('SET_TERM_MODAL', data)
    },
  },
  plugins: [vuexLocal.plugin]
})

