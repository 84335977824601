<template>
  <div id="MasterTerm" v-if="this.GET_TERM_MODAL.visible">
    <div class="modal-background">
      <div class="outer">
        <div class="inner">
          <div class="modal-term centered">
            <div class="term-content">
              <div class="term-header">
                <span class="header-title">{{ GET_TERM_MODAL.text }}</span>
                <button class="close-modal" @click="HIDE_MODAL"></button>
              </div>
              <div class="term-body">
                <div class="body-content">
                  <div class="content-scroll">
                    <Term1 v-if="GET_TERM_MODAL.index == 1"></Term1>
                    <Term2 v-if="GET_TERM_MODAL.index == 2"></Term2>
                    <Term3 v-if="GET_TERM_MODAL.index == 3"></Term3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vuex from 'vuex'

import Term1 from '../term/term_1.vue';
import Term2 from '../term/term_2.vue';
import Term3 from '../term/term_3.vue';

export default {
  components: {
    Term1,
    Term2,
    Term3,
  },
  computed: {
    ...vuex.mapGetters({
      'GET_TERM_MODAL': 'GET_TERM_MODAL',
    })
  },
  methods: {
    ...vuex.mapActions({
      'SET_TERM_MODAL': 'SET_TERM_MODAL'
    }),
    HIDE_MODAL() {
      this.SET_TERM_MODAL({
        visible: false,
        index: 0,
      })
    }
  },
  created() {
    console.log('term craeted')
    console.log(this.GET_TERM_MODAL)
  }
}
</script>

<style lang="scss">
#MasterTerm {
  .modal-background {
    position: fixed;

    z-index: 3000;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(#000, .6);

    .modal-term {
      padding: 0 25px;

      max-width: 767px;

      .term-content {
        background-color: #fff;

        text-align: left;

        .term-header {
          position: relative;

          height: 48px;
          padding: 0 21px;

          border-bottom: 1px solid #ebebeb;

          .header-title {
            font-size: 14pt;
            font-weight: 700;
            color: #424242;
            line-height: 48px;
          }

          .close-modal {
            display: inline-block;
            position: absolute;

            top: 0;
            right: 3px;

            width: 38px;
            height: 100%;

            background-image: url('../../assets/images/bt_close_1_n.png');
            background-size: 38px 38px;
            background-position: center;
            background-repeat: no-repeat;

            &:active {
              background-image: url('../../assets/images/bt_close_1_p.png');
            }
          }
        }

        .term-body {
          padding: 18px 0;

          font-size: 11pt;

          color: #808080;

          line-height: 15.333333pt;

          .body-content {
            overflow-y: scroll;

            max-height: 450px;
            padding: 0 21px;
          }
        }
      }
    }
  }
}
</style>