<template>
  <div id="Multilingual" v-if="GET_MULTILINGUAL.display">
    <div class="background-dimmed">
      <div class="window">
        <div class="language-list">
          <div class="language-item" v-for="(lang, idx) in LanguageList" :key="idx">
            <div 
              class="language" :class="{ 'language-on': lang.lang === GET_LANG }" @click="setLanguage(lang)" v-if="lang.use" 
            >{{ lang.name }}</div>
          </div>
        </div>

        <button class="cancel" @click="hideMultilingual">{{ $t('COMMON.CANCEL') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import vuex from 'vuex'

export default {
  computed: {
    ...vuex.mapGetters({
      'getStoreImformation': 'getStoreImformation',
      'GET_LANG': 'lang/GET_LANG',
      'GET_MULTILINGUAL': 'lang/GET_MULTILINGUAL'
    }),
    LanguageList() {
      return this.getStoreImformation.language_list;
    }
  },
  destroyed() {
    this.HIDE_MULTILINGUAL();
  },
  methods: {
    ...vuex.mapActions({
      'SET_LANGUAGE': 'lang/SET_LANGUAGE',
      'HIDE_MULTILINGUAL': 'lang/HIDE_MULTILINGUAL'
    }),
    setLanguage(lang) {
      this.$i18n.locale=lang.lang;
      this.SET_LANGUAGE(lang),
      this.HIDE_MULTILINGUAL();
    },
    hideMultilingual() {
      this.HIDE_MULTILINGUAL();
    }
  }
}
</script>

<style lang="scss">
#Multilingual {
  .background-dimmed {
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    z-index: 1000;

    top: 0; right: 0; bottom: 0; left: 0;

    background-color: rgba(black, .84);

    .window {
      position: relative;
      width: 310px;
      background-color: white;

      .language-list {
        padding: 35px 0 63px;

        .language-item {
          .language {
            height: 24px;

            font-size: 15px;
            font-weight: bold;

            text-align: center;
            line-height: 1.6
          }

          .language-on {
            color: #ff8b45;
          }

          &:not(:first-of-type) {
            .language {
              margin-top: 20px;
            }
          }
        }
      }

      .cancel {
        position: absolute;        
        right: 20px;
        bottom: 12px;
        width: 52px;
        height: 28px;

        color: #828282;
      }
    }    
  }

}
</style>