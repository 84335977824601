<template>
  <div id="Main">
    <div class="main-banner-img" :style="{ backgroundImage: 'url(' + storeInformation.mobile_img + ')' }">
      <div class="main-banner-text">
        <div class="outer">
          <div class="inner text-bottom">
            <div class="main-store-name">{{ storeInformation.store_names | language }}</div>
            <div class="main-store-msg">{{ storeInformation.title }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="menu-container">
      <div class="menu-list">
        <button class="menu-item order" @click="goProductList()">주문<span class="sale-preparation">영업 준비중!</span></button>
        <div class="horizon-line"></div>
        <button class="menu-item history" @click="goHistorySearch()">내역조회</button>
      </div>
    </div>

    <div class="store-information">
      <div class="inform-row">
        <div class="title" @click="test()">{{ $t('ADDRESS') }}</div>
        <div class="content">
          {{ storeInformation.addr1 }} {{ storeInformation.addr2 }}
        </div>
      </div>
      <div class="inform-row">
        <div class="title">{{ $t('TEL') }}</div>
        <div class="content" @click="callPhone()">{{ storeInformation.phone_no }}</div>
      </div>
      <div class="inform-row">
        <div class="title">{{ $t('BUSINESS_INFORMATION') }}</div>
        <div class="content">
          <div>{{ storeInformation.open_start_time | semicolonTime }} - {{ storeInformation.open_end_time | semicolonTime }}</div>
          <div v-if="storeInformation.break_start_time !== storeInformation.break_end_time">(브레이크타임 {{ storeInformation.break_start_time | semicolonTime }} - {{ storeInformation.break_end_time | semicolonTime }})</div>
          <div>{{ storeInformation.close_text }}</div>
        </div>
      </div>
    </div>
    <Footer></Footer>

    <FullSpinner></FullSpinner>
  </div>
</template>

<script>
import vuex from 'vuex'
import axios from 'axios';

import Footer from '../components/TheFooter'
import FullSpinner from '../components/TheSpinner.vue'

import language from '../filters/language.js'

export default {
  components: {
    Footer,
    FullSpinner
  },
  data() {
    return {

    }
  },
  computed: {
    ...vuex.mapGetters({
      'getStoreImformation': 'getStoreImformation'
    }),
    storeInformation() {
      return this.$store.state.storeInformation;
    }
  },
  filters: {
    semicolonTime(value) {
      value = ''+value;

      let hour = value.slice(0, 2);
      let minute = value.slice(2, 4);

      return `${hour}:${minute}`
    },
    language
  },
  methods: {
    ...vuex.mapActions({
      'SET_LANGUAGE': 'lang/SET_LANGUAGE',
      'SET_LANGUAGE_LIST': 'lang/SET_LANGUAGE_LIST',
    }),
    async loadStoreID() {
      // let storeID = this.$route.query.storeID;
      let storeID = this.$route.params.storeID;
      console.log(storeID)
      this.$store.commit('setStoreID', storeID);

      await this.getStoreInformation(storeID);
      await this.getStoreSetting(storeID);
      this.setLanguage()
    },
    async getStoreInformation(storeID) {
      let self = this;

      return await axios({
        method: 'post',
        url: `${process.env.VUE_APP_API_URL}/store/info`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          store_id: storeID
        }
      })
        .then(function(res) {
          let storeInformation = res.data;
          self.$store.commit('setStoreInformaiton', storeInformation)
        })
        .catch(function(err) {
          console.log(err);
          let res = err.response;
          console.log(res);   
          self.$router.push(`/page/error`) 
        })        
    },
    async getStoreSetting(storeID) {
      let self = this;

      return await axios({
        method: 'post',
        url: `${process.env.VUE_APP_API_URL}/store/setting`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          store_id: storeID
        }
      })
        .then(function(res) {
          // console.log(res);
          let storeSetting = res.data;
          self.$store.commit('setState', {
            key: 'storeSetting', value: storeSetting
          })
          
        })
        .catch(function(err) {
          console.log(err);
          let res = err.response;
          console.log(res);    
        })        
    },
    setLanguage() {
      let language = this.getStoreImformation.language;
      let languageList = this.getStoreImformation.language_list;

      this.SET_LANGUAGE_LIST(languageList);

      for(let lang of languageList) {
        if(language.default === lang.lang) {
          this.$i18n.locale = lang.lang;
          this.SET_LANGUAGE(lang)
        }
      }
    },
    checkFooterPosition() {
      let fixed = false;
      if(window.innerHeight > document.body.clientHeight) {
        fixed = true;
      }

      this.$store.commit('setPreference', {
        key: 'fixedFooter',
        value: fixed
      })
    },
    callPhone() {
      location.href = `tel:${this.storeInformation.phone_no}`;
    },
    goProductList() {
      this.$store.commit('setState', {
        key: 'categoryID',
        value: ''
      })
      this.$router.push(`${this.$store.state.gRoot}/home/list`)
    },
    goHistorySearch() {
      this.$router.push(`${this.$store.state.gRoot}/history/search`)
    },
    initStore() {
      // store를 초기화하는 코드!
      // this.$store.commit('initStore')
    },
  },
  created() {
    // this.initStore();
    this.loadStoreID();
  },
  mounted() {
    this.checkFooterPosition();
    this.goProductList();
    console.log('TheMain')
  }
}
</script>

<style lang="scss">
#Main {
  .main-banner-img {
    position: relative;

    padding-top: 75%;

    background-size: cover;
    background-position: center;

    .main-banner-text {
      position: absolute;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;;

      padding: 0 20px 23px;

      .text-bottom {
        line-height: 1.44;
        text-align: left;
        vertical-align: bottom;
      }

      .main-store-name {
        font-size: 24pt;
        font-weight: 700;

        color: #fff;
      }

      .main-store-msg {
        margin-top: 5px;

        font-size: 14pt;

        color: rgba(#fff, .9);
      }
    }
  }

  .menu-container {
    margin-top: 6px;

    .menu-list {
      padding: {
        top: 7.333333px;
        bottom: 6.666667px;
      };

      background-color: #fff;

      .menu-item {
        display: block;
        position: relative;

        width: 100%;
        height: 63.333333px;

        padding-left: 70.666667px;

        font-size: 15pt;

        background-size: 63.333333px;
        background-position: 7.333333px center;
        background-repeat: no-repeat;

        color: #5e5e5e;

        text-align: left;

        &:active {
          color: rgba(#5e5e5e, .3)
        }

        .sale-preparation {
          display: none;
        }

        &:disabled {
          // opacity: .3;

          color: rgba(#5e5e5e, .3);

          .sale-preparation {
            display: inline-block;

            position: absolute;

            top: 16.666667px;
            right:20px;

            width: 76px;
            height: 30px;

            border-radius: 15px;

            font-size: 11pt;

            background-color: rgba(#000, .5);
            color: #fff;

            line-height: 30px;
          }
        }
      }
      .order {
        background-image: url('../assets/images/home_order.png');
      }
      .history {
        background-image: url('../assets/images/home_list.png');
      }

      .horizon-line {
        margin-left: 20px;

        border-top: 1px solid #f2f2f2;
      }
    }
  }

  .store-information {
    padding: 18px 20px;

    .inform-row:not(:first-child) {
      margin-top: 6px;
    }

    .inform-row {
      overflow: hidden;

      font-size: 10pt;

      color: #a8a8a8;

      .title, .content {
        float: left;
        line-height: 18px;
      }

      .title {
        width: 64px
      }
      // trick
      .content {
        width: 100%;
        margin-right: -64px;
        padding-right: 64px
      }
    }
  }
}
</style>
