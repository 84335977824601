<template>
  <div>
    <div id="PayemntDefault">
      <div class="digest-container" :style="{ backgroundColor: '#1D9BF7' }">
        <div class="digest-box">
          <div class="franchisee-name digest-text">{{ digest.store || 'TEST CAFE' }}</div>
          <div class="product-name digest-text">{{ digest.title }}</div>
          <div class="product-price">{{ digest.price | CommaNumbersRemoveDecimals }} 원</div>
        </div>
      </div>
      <div class="fixed-area"></div>
    </div>
    <div id="Domestic" @click.self="inputBlur">
      
      <input style="display:none" aria-hidden="true">
      <input type="password" style="display:none" aria-hidden="true">
      <input style="display: none" type="text" name="fakeusernameremembered" />
      <input style="display: none" type="password" name="fakepasswordremembered" />

      <div class="content-container">
        <div class="content-item">
          <h1 class="title">국내 카드 결제</h1>
          <div class="input-container">

            <div class="input-box">
              <div class="input-title">카드 번호</div>
              <div class="input-wrap">
                <input type="tel" inputmode="numeric" class="input-item" placeholder="예) 1234 - 1234 - 1234 - 1234" v-model="card.number" @focus="focusInput" @blur="blurInput" ref="cardNumber">
              </div>
            </div>

            <div class="input-box">
              <div class="input-title">유효기간</div>
              <div class="input-wrap">
                <input 
                  type="tel" inputmode="numeric" class="input-item card-validity" :style="styleObject" placeholder="MM / YY" ref="validityDate"
                  v-model="card.validity" maxlength="4" @focus="focusInput" @blur="blurInput"
                >
                <div class="validity-date input-item" @click="$refs.validityDate.focus()"  :style="styleObject">
                  <span class="date-number" :class="{ 'filled': card.validity[0], 'cursor': card.validity.length === 0 }">{{ card.validity[0] || 'M'}}</span>
                  <span class="date-number" :class="{ 'filled': card.validity[1], 'cursor': card.validity.length === 1 }">{{ card.validity[1] || 'M'}}</span>
                  <span> / </span>
                  <span class="date-number" :class="{ 'filled': card.validity[2], 'cursor': card.validity.length === 2 }">{{ card.validity[2] || 'Y'}}</span>
                  <span class="date-number" :class="{ 'filled': card.validity[3], 'cursor': card.validity.length === 3 }">{{ card.validity[3] || 'Y'}}</span>
                </div>
              </div>
            </div>

            <div class="input-box">
              <div class="input-title">비밀번호 (앞 2자리)</div>
              <div class="input-wrap">
                <input type="text" inputmode="numeric" maxlength="2" class="input-item password" autocomplete="off" placeholder="" v-model="card.password" @focus="focusInput" @blur="blurInput" ref="cardNumber">
              </div>
            </div>

            <div class="input-box">
              <div class="input-title">생년월일</div>
              <div class="input-wrap">
                <input 
                  type="tel" inputmode="numeric" class="input-item card-validity" :style="styleObject" placeholder="MM / YY" ref="birthdayDate" 
                  v-model="card.date" maxlength="6" @focus="focusInput" @blur="blurInput"
                >
                <div class="validity-date input-item" @click="$refs.birthdayDate.focus()"  :style="styleObject">
                  <span class="date-number" :class="{ 'filled': card.date[0], 'cursor': card.date.length === 0 }">{{ card.date[0] || 'Y'}}</span>
                  <span class="date-number" :class="{ 'filled': card.date[1], 'cursor': card.date.length === 1 }">{{ card.date[1] || 'Y'}}</span>
                  <span> / </span>
                  <span class="date-number" :class="{ 'filled': card.date[2], 'cursor': card.date.length === 2 }">{{ card.date[2] || 'M'}}</span>
                  <span class="date-number" :class="{ 'filled': card.date[3], 'cursor': card.date.length === 3 }">{{ card.date[3] || 'M'}}</span>
                  <span> / </span>
                  <span class="date-number" :class="{ 'filled': card.date[4], 'cursor': card.date.length === 4 }">{{ card.date[4] || 'D'}}</span>
                  <span class="date-number" :class="{ 'filled': card.date[5], 'cursor': card.date.length === 5 }">{{ card.date[5] || 'D'}}</span>
                </div>
              </div>
            </div>

          </div>
        </div>

        <!-- <div class="content-item">
          <h1 class="title">{{ $t('DOMESTIC.RECEIPT') }}</h1>
          <h2 class="guide">{{ $t('DOMESTIC.RECEIPT_SUB_TITLE') }}</h2>
          <div class="input-container">

            <div class="input-box">
              <div class="input-title">{{ $t('DOMESTIC.EMAIL_ADDRESS') }}</div>
              <div class="input-wrap">
                <input type="email" inputmode="email" class="input-item" v-model="email"  @focus="focusInput" @blur="blurInput" ref="email">
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="button-container">
        <button class="next-step" :class="{ 'hide': !visibleBtnNext }" :style="styleObject" @click="nextStep" :disabled="disabledButtonNext">결제하기</button>
      </div>
    </div>

  </div>
</template>

<script>
import vuex from 'vuex'

export default {
  data () {
    return {
      digest: {},
      card: {
        number: '',
        validity: '',
        // number: '4171-0307-0763-3949',
        // validity: '0823',
        installmentMonths: "00",
        password: '',
        date: ''
      },
      email: '',
      showInstallment: true,
      disBtnNext: false,
      visibleBtnNext: true
    }
  },
  computed: {
    ...vuex.mapGetters({
      'getColor': 'style/getColor',
      'GET_PAYMENT_INFORMATION': 'GET_PAYMENT_INFORMATION',
      'GET_PAYMENT_QUERY': 'GET_PAYMENT_QUERY'
    }),
    styleObject: function() {
      return {
        '--color': '#1D9BF7',
        '--color-active': '#146BAB'
      }
    },
    disabledButtonNext() {
      return (this.disBtnNext || (this.card.number.length < 15 || this.card.validity.length < 4 || this.card.password.length < 2 || this.card.date.length < 6))
    }
  },
  filters: {
    comQuota(value) {
      if(value === "00" || value === "0") {
        return '일시불'
      } else {

        return `${Number(value)}개월`;
      }
    },
    CommaNumbersRemoveDecimals(value = '') { 
      value = '' + value

      // 숫자를 세 자리 마다 쉼표를 넣은 문자로 변환한다 (1000 -> '1,000')
      return value.split('.').shift().split('').reverse().reduce((acc, digit, i) => {
        if (i > 0 && i % 3 === 0) acc.push(',')
        return [...acc, digit]
      }, []).reverse().join('')
    }
  },
  mounted() {
    this.digest = {
      ...this.digest, ...this.$route.query
    }
  },
  methods: {
    ...vuex.mapActions({
      'REQUEST_CARD_PAYMENT': 'REQUEST_CARD_PAYMENT',
      'SET_ERROR_PAGE': 'SET_ERROR_PAGE'
    }),
    // setInstallment() {
    //   if(this.GET_PAYMENT_INFORMATION.amount<=50000) {
    //     this.showInstallment = false;
    //   }
    // },
    async nextStep() {
      this.SET_ERROR_PAGE({
        message: 123,
        code: 2345
      })
      this.$router.push('/stationary/failure')
    },
    async requestCardPayment() {
      console.log(this.GET_PAYMENT_QUERY.api_key)
      let cardNumber = this.card.number;
      cardNumber = cardNumber.replace(/[^0-9]/g,'');   // 입력값이 숫자가 아니면 공백
      cardNumber = cardNumber.replace(/-/g,'');        // ,값 공백처리
      cardNumber = this.encrypt(cardNumber, this.GET_PAYMENT_QUERY.api_key);

      let cardValidity = this.card.validity;
      let validityMonth = cardValidity.substr(0, 2);
      let validityYear = cardValidity.substr(2, 2);
      cardValidity = this.encrypt(validityYear+validityMonth, this.GET_PAYMENT_QUERY.api_key);

      let card = {
        number: cardNumber,
        validity: cardValidity
      }

      let res = await this.REQUEST_CARD_PAYMENT({
        card: card,
        email: this.email
      })
      
      return res;
    },
    encrypt (text, key) {
      const iv = this.CryptoJS.lib.WordArray.random(16)
      const keyHash = this.CryptoJS.SHA256(key)
      const cipherText = this.CryptoJS.AES.encrypt(text, keyHash, { iv: iv } ).ciphertext
      
      return this.CryptoJS.enc.Hex.stringify(iv) + this.CryptoJS.enc.Hex.stringify(cipherText)
    },
    test() {

    },
    focusInput() {
      // this.visibleBtnNext = false;
    },
    blurInput() {
      // this.visibleBtnNext = true;
    },
    inputBlur() {
      console.log(this.$refs)

      for(let el in this.$refs) {
        console.log(el)
        this.$refs[el].blur();
      }

    }
  }
}
</script>

<style lang="scss">
@import './card.scss';

#PaymentDefault {
  position: relative;
}

.header-area {
  height: 44px;
}

.digest-container {
  z-index: 1000;
  position: fixed;
  width: 100%;

  .digest-box {
    /* @include basic-display; */
    max-width: 600px;
    margin: 0 auto;

    padding: 15px 30px 21px;

    color: #fff;

    .digest-text {
      height: 28px;

      font-size: 18px;
      font-weight: bold;

      line-height: 1.56;

      &:not(:first-child) {
        margin-top: 6px;
      }
    }
    .product-price {
      height:40px; 
      margin-top: 6px;

      font-size: 30px;
      font-weight: bold;

      line-height: 1.33;

      text-align: right;
    }
  }
}

.fixed-area {
  height: 144px;
}

/* .content {
  position: absolute;
  top: 188px;
  right: 0;
  bottom :0;
  left: 0;
} */

#Domestic {
  .input-wrap {
    position: relative;
  }

  .card-validity {
    position: absolute;
    // top: 28px;
    left: -2000px;
    height: 50px;

    color: transparent;

    &:focus ~ .validity-date {
      .cursor {
        animation: cursor-literals infinite 1s linear;        
      }
    }

    @keyframes cursor-literals {
      from {
        color: $color-black-30;
      }

      50% {
        color: var(--color);
      }

      to {
        color: $color-black-30;
      }
    }
  }

  .validity-date {
    line-height: 50px;

    .date-number {
      color: $color-black-30;
    }

    .filled {
      color: black;
    }

    
  }
}
</style>