<template>
  <aside id="SideMenu" class="off" :class="{ on: getMenuStatus }">
    <div class="side-menu-background">
      <div class="side-menu-window">
        <div class="menu-list">
          <button 
            class="menu-item"
            :style="{ 'background-image': `url(${menu.icon})` }"
            v-for="(menu, idx) in menuList" :key="idx"
            @click="routeMenu(menu)"
          >{{ menu.text }}
          </button>          
        </div>
      </div>
    </div>
  </aside>
</template>




<script>
import vuex from 'vuex';

export default {
  data () {
    return {
      menuList: [
        {
          id: 1,
          text: this.$t('PLACE_AN_ORDER'),
          icon: require('../assets/images/icon-order.svg'),
          route: '/home/list'
        },
        {
          id: 2,
          text: this.$t('ORDER_HISTORY_INQUIRY'),
          icon: require('../assets/images/icon-order-search.svg'),
          route: `/history/search`
        },
        // { id: 3, text: '스크롤 테스트 3', route: '/' },
        // { id: 4, text: '스크롤 테스트 4', route: '/' },
        // { id: 5, text: '스크롤 테스트 5', route: '/' },
        // { id: 6, text: '스크롤 테스트 6', route: '/' },
        // { id: 7, text: '스크롤 테스트 7', route: '/' },
        // { id: 8, text: '스크롤 테스트 8', route: '/' },
      ]
    }
  },
  computed: {
    ...vuex.mapGetters({
      'getMenuStatus': 'getMenuStatus'
    })
  },
  methods: {
    ...vuex.mapMutations({
      'hideSideMenu': 'hideSideMenu'
    }),
    routeMenu(menu) {
      let url = this.$store.state.gRoot + menu.route;

      this.$router.push(url)
      this.hideSideMenu();
    }
  }
}
</script>

<style lang="scss">
#SideMenu.off {
  position: fixed;
  z-index: 1000;
  top: 44px;
  right: 1600px;
  // right: 0px;
  bottom: 0;
  left: -300px;

  transition: left .5s;

  .side-menu-background {
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0);
    
    transition: all .5s;

    .side-menu-window {
      overflow-y: scroll;
      position: absoluted;
      width: 300px;
      height: 100%;
      padding-left: 20px;
      background-color: white;

      .menu-list {
        padding: 5px 0;

        .menu-item {
          display: inline-block;
          width: 100%;
          height: 84px;
          padding-left: 50px;
          // border-bottom: solid 1px #e1e1e1;

          // font-size: 15px;
          // font-weight: bold;
          font-size: 17.333333px;

          background-image: url('../assets/images/icon-order.svg');
          background-position: left 10px center;
          background-size: 30px;
          background-repeat: no-repeat;

          text-align: left;
        }
      }
    }
  }
  
}

#SideMenu.on {
  position: fixed;
  top: 44px;
  right: 0;
  bottom: 0;
  left: 0px;  

  .side-menu-background {
    background-color: rgba($color: #000000, $alpha: .6);
  }

  @media screen and (min-width: 600px) {
    left: calc(50% - 250px);
  }
}


</style>