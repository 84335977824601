<template>
  <!-- <footer id="Footer" v-if="!footerLoading" :class="{ 'footer-fixed': fixedFooter }"> -->
  <footer id="Footer" >
    <div class="footer-body footer-container">
      <div class="content-wrap">
        <div class="store-information">
          <div class="title">가맹점 정보</div>
          <div class="information-box">
            <div class="informations informations-left">
              <div class="information-item">
                <div class="sub-title">매장명</div>
                <div class="contents">{{ GET_STORE_INFORMATION.store_names | language }}</div>
              </div>
              <div class="information-item">
                <div class="sub-title">주소</div>
                <div class="contents">
                  <div>{{ GET_STORE_INFORMATION.addr1s | language }}</div>
                  <div>{{ GET_STORE_INFORMATION.addr2s | language }}</div>
                </div>
              </div>
              <div class="information-item">
                <div class="sub-title">대표자명</div>
                <div class="contents">{{ GET_STORE_INFORMATION.ceo_names | language }}</div>
              </div>
              <div class="information-item" v-if="GET_STORE_INFORMATION.corporation">
                <div class="sub-title">법인명</div>
                <div class="contents">{{ GET_STORE_INFORMATION.corporation | language }}</div>
              </div>
            </div>
            <div class="informations information-right">
              <div class="information-item">
                <div class="sub-title">전화번호</div>
                <div class="contents">{{ GET_STORE_INFORMATION.phone_no | language }}</div>
              </div>
              <div class="information-item">
                <div class="sub-title">E-mail</div>
                <div class="contents">{{ GET_STORE_INFORMATION.email | language }}</div>
              </div>
              <div class="information-item">
                <div class="sub-title">사업자번호</div>
                <div class="contents">{{ GET_STORE_INFORMATION.business_no | language }}</div>
              </div>
              <div class="information-item" v-if="GET_STORE_INFORMATION.mail_order_sales_registration_number ">
                <div class="sub-title">통신판매업<br/>신고번호</div>
                <div class="contents">{{ GET_STORE_INFORMATION.mail_order_sales_registration_number | language }}</div>
              </div>
              <div class="information-item" v-if="GET_STORE_INFORMATION.open_time_yn === 'Y'">
                <div class="sub-title">영업정보</div>
                <div class="contents">
                  <p>{{ GET_STORE_INFORMATION.open_start_time | timeFormat }} ~ {{ GET_STORE_INFORMATION.open_end_time | timeFormat }}</p>
                  <p v-if="GET_STORE_INFORMATION.break_yn === 'Y'">{{ GET_STORE_INFORMATION.break_start_time | timeFormat }} ~ {{ GET_STORE_INFORMATION.break_end_time | timeFormat }}</p>
                  <p v-if="GET_STORE_INFORMATION.close_yn === 'Y'">{{ GET_STORE_INFORMATION.close_text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-footer footer-container">
      <div class="content-wrap">
        <div class="site-footer">
          <!-- <span class="footer-text" @click="SHOW_POLICY_PIPC">개인정보 취급방침</span> -->
          <span class="footer-text">
            <span 
              class="term-item"
              v-for="term in terms" :key="term.index"
              @click="showTerm(term)"
            >{{term.text}}</span>
          </span>
          <span id="FooterLogo"></span>
        </div>
      </div>
    </div>

    <PolicyPIPC></PolicyPIPC>
    <MasterTerm></MasterTerm>
  </footer>
</template>

<script>
import vuex from 'vuex'
import moment from 'moment'

import PolicyPIPC from './PolicyPIPC'
import MasterTerm from './MasterTerm.vue'

import language from '../../filters/language.js'


export default {
  components: {
    PolicyPIPC, 
    MasterTerm
  },
  data() {
    return {
      fixedFooter: false,
      footerPostionBot: null,
      footerLoading: null,
      footerDone: false,
      terms: [
        { text: '이용약관', index: 1 },
        { text: '개인정보처리방침', index: 2 },
        { text: '청소년보호정책', index: 3 },
      ]
    }
  },
  computed: {
    ...vuex.mapGetters({
      'GET_STORE_INFORMATION': 'im/GET_STORE_INFORMATION',
      'GET_PRODUCT_LIST': 'im/GET_PRODUCT_LIST',
      'GET_TERM_MODAL': 'GET_TERM_MODAL',
    }),
    loadScrollHeight() {
      return document.documentElement.scrollHeight;
    },
  },
  watch: {
    // $route: 'setFooter',
    // 'GET_PRODUCT_LIST': 'setFooter',
    $route: 'setFooterPostion',
    'GET_PRODUCT_LIST': 'setFooterPostion',
  },
  filters: {
    language,
    timeFormat(value) {
      value = moment(value, 'hhmm').format('hh:mm A')
      return value
    }
  },
  created() {
    this.hideTermModal();
  },
  mounted() {
    // this.setFooter();
    this.setFooterPostion();
  },
  destroyed() {
    this.hideTermModal();
  },
  methods: {
    ...vuex.mapActions({
      'SHOW_POLICY_PIPC': 'SHOW_POLICY_PIPC',
      'HIDE_POLICY_PIPC': 'HIDE_POLICY_PIPC',
      'SET_TERM_MODAL': 'SET_TERM_MODAL',
    }),
    hideTermModal() {
      this.SET_TERM_MODAL({
        visible: false,
        term: null,
      })
    },
    setFooter() {
      this.HIDE_POLICY_PIPC()

      // this.fixedFooter = false;

      // let checkTime = [100];

      // for(let time of checkTime) {
      //   setTimeout(()=>{
      //     this.fetchData();
      //   }, time)
      // }
    },
    setFooterPostion() {
      // console.log(this.footerPostionBot)
      clearTimeout(this.footerPostionBot);
      
      this.footerLoading = true;

      const { innerHeight } = window;
      const { clientHeight, scrollHeight } = document.body;

      [800, 1200, 2000, 3200].forEach(time => {
        this.footerPostionBot = setTimeout(() => {
          const isFooter = document.querySelector('#Footer');
          const isFixed = innerHeight >= clientHeight + (isFooter ? 30 : 300);

          // console.log(innerHeight, scrollHeight)
          // console.table({ innerHeight, clientHeight, scrollHeight });

          if(time > 1_000) {
            this.footerLoading = false;

            // console.log(this.fixedFooter, isFixed);

            if(this.fixedFooter !== isFixed) {
              this.fixedFooter = isFixed;
            }
          }
        }, time)
      })

    },
    fetchData() {
      let element = document.querySelector('#Footer').getBoundingClientRect();
      let { y, top, bottom, height } = element;
      let { innerHeight } = window;
      let { scrollHeight, clientHeight } = document.body
      
      //console.log(`
      //   y: ${y}
      //   top: ${top}
      //   bottom: ${bottom}
      //   height: ${height}
      //   scrollHiehgt: ${scrollHeight}
      //   clientHeight: ${clientHeight}
      //   innerHeight: ${innerHeight}
      // `)

      let setTimeList = [400, 800, 1200, 3200];

      for (const delay of setTimeList) {
        this.footerPostionBot = setTimeout(() => {
          // this.fixedFooter = (innerHeight >= bottom);  
          // console.log('####')
          // console.log(clientHeight == scrollHeight)
          
        }, delay);        
      }
    },

    showTerm(term) {
      this.SET_TERM_MODAL({
        visible: true,
        ...term,
      })

      // console.log(this.GET_TERM_MODAL)
    }
  }
}
</script>

<style lang="scss">
#Footer {
  // &.fixed {
  //   position: fiexed;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  // }

  .footer-container {
    color: white;

    &.footer-body {
      /* padding: 30px 50px; */
      background: #212121;
    }
    &.footer-footer {
      /* padding: 10px 50px; */
      background: black;
    }
    .content-wrap {
      max-width: $limitWidth;
      margin: 0 auto;
    }

    .store-information {
      padding: 30px 50px;

      .title {
        height: 24px;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.71;
      }

      .information-box {
        display: flex;
        margin-top: 12px;

        font-size: 12px;
        line-height: 1.5;

        .informations {
          display: flex;
          flex-direction: column;
          flex-basis: 50%;

          &.informations-left {
            padding-right: 39px;
          }
          &.information-right {
            padding-left: 39px;
          }

          .information-item {
            display: flex;

            &:not(:first-child) {
              margin-top: 10px;
            }

            .sub-title {
              flex-basis: 99px;
              flex-grow: 0;
              min-width: 99px;;
              padding-right: 3px;
              font-weight: bold;
            }

            .content {
              flex-grow: 0;
            }

          }

        }
      }
    }

    .site-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 50px;

      .footer-text {
        height: 18px;
        font-size: 12px;
        line-height: 1.5;

        .term-item {
          padding: 0 12px;
        }
      }
      #FooterLogo {
        width: 158px;
        height: 60px;

        background-image: url('../../assets/images/new/elixirpay.svg');
      }
    }
  }
}
</style>
