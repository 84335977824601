<template>
  <div id="DefaultNavigatorStationary">
    <div class="category-navigator">
      <div class="category-navigator-container">
        <div class="category-box">
          <span>
            <button class="menu"></button>
            <button 
              class="category-item"
              v-for="(category, idx) in GET_CATEGORY_LIST" :key="idx"
              @click="lookProductList(category)"
            >{{ category.category_names | language }}</button>
          </span>
        </div>
      </div>
    </div>

    <div class="navigator-step">
      <div class="navigator-step-container">
        <div class="title">{{ titleText }}</div>
        <div class="step-list">
          <span class="step-item" v-for="(step, idx) in stepList" :key="step.text">
            <span class="step-name" :class="{ now: $route.name === step.routeName }" v-if="step.visible[0]">{{ step.text }}</span>
            <span class="step-arrow" v-if="step.visible[1]">＞</span>
          </span>
        </div>
      </div>
    </div>

    <router-view/>
  </div>
</template>

<script>
import vuex from 'vuex'
import language from '../filters/language'

export default {
  data() {
    return {
      stepList: [
        { text: '장바구니', routeName: 'S_CART', visible: [true, true] },
        { text: '주문／결제', routeName: 'S_ORDER', visible: [true, true] },
        { text: '주문완료', routeName: 'OrderSuccess', visible: [true, false] },
        { text: '주문 취소', routeName: 'OrderCancellation', visible: [false, false]}
      ],
    }
  },
  computed: {
    ...vuex.mapGetters({
      'GET_CATEGORY_LIST': 'im/GET_CATEGORY_LIST',
      'GET_CATEOGRY_ID': 'im/GET_CATEOGRY_ID',
    }),
    titleText() {
      return this.stepList.find(step => this.$route.name === step.routeName).text || '';
    }
  },
    filters: {
    language
  },
    methods: {
    ...vuex.mapActions({
      'FETCH_PRODUCT_LIST': 'im/FETCH_PRODUCT_LIST',
      'FETCH_PRODUCT_DETAIL': 'im/FETCH_PRODUCT_DETAIL',
    }),
    async lookProductList(category) {
      let categoryID = category.category_id;
      let res = await this.FETCH_PRODUCT_LIST(categoryID);      
      this.$router.push(`/stationary/home`)
    },
  }
}
</script>

<style lang="scss">
#DefaultNavigatorStationary {
  .category-navigator {
    background-color: #fff;

    .category-navigator-container {
      max-width: $limitWidth;    
      margin: 0 auto;
      padding: 0 30px;
    }
  
    .category-box {
      display: flex;
      align-items: center;
      height: 90px;
  
      .menu {
        width: 60px;
        height: 60px;
        margin-right: 9px;

        background-image: url('../assets/images/new/menu-nor.svg');
        background-position: center;
        background-size: 60px;
        background-repeat: no-repeat;
  
        vertical-align: middle;
        transition: all .2s;
  
        cursor: pointer;
  
        &:active {
          background-image: url('../assets/images/new/menu-pre.svg');
        }
      }
  
      .category-item {
        height: 60px;
        margin: 0 9px;
  
        font-size: 18px;
        font-weight: bold;
        color: black;
  
        cursor: pointer;
      }
  
      .category-item.selected {
        color: $color-main;
      }
    }
  }

  .navigator-step {
    background-color: white;

    border-bottom: 1px solid #dbdbdb;

    .navigator-step-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      max-width: $limitWidth;
      height: 90px;
      margin: 0 auto;
      padding: 0 60px;
    }

    .title {
      font-size: 30px;
      font-weight: bold;
      line-height: 1.33;
    }

    .step-list {
      .step-item {
        font-size: 14px;
        color: #999;

        .now {
          color: black;
        }
        .step-arrow {
          margin: 0 3px;
        }
      }
    }
  }
}
</style>