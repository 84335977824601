<template>
    <div>
        <div class="policy">
            <h1>제 1 장 총칙</h1>
            <h2>제 1 조 [목적]</h2>
            <p>본 약관은 {{ GET_STORE_INFORMATION.corporation || GET_STORE_INFORMATION.store_name }}(이하 “회사”)이 제공하는 {{ GET_STORE_INFORMATION.store_name }}의 이용과 관련하여 회사와 서비스 이용자의 권리•의무, 책임사항, 기타 제반 사항을
                규정함을 목적으로 합니다.</p>
            <h2>제 2 조 [용어의 정의]</h2>
            <p>회사는 필요하다고 인정되는 경우 이 약관을 변경할 수 있으며 이용자에게 공지함으로써 효력을 발생합니다.</p>
            <ul>
                <li>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                    <ul class="in-one">
                        <li>1. “서비스”라 함은 {{ GET_STORE_INFORMATION.store_name }} 사이트를 통하여 이용자의 요청에 따라 회사가 이동통신 이용고객을 대상으로 모바일 상품권을 URL이
                            포함된 SMS 또는 LMS 형식으로 발송하고, 수신고객은 실물상품을 판매 또는 제공하는 매장에서 모바일 상품권을 등가의 실물상품으로 교환할 수 있도록
                            하는 모바일 상품권 발송 서비스를 말합니다.
                        </li>
                        <li>2. “모바일 상품권”이라 함은 실물상품으로 교환할 수 있는 권리가 구체화된 바코드 형태의 모바일형 상품권을 말합니다.</li>
                        <li>3. “교환처”라 함은 모바일 상품권을 실물상품으로 교환할 수 있는 매장 또는 온•오프라인 사용처를 말합니다.</li>
                        <li>4. “수신자”라 함은 모바일 상품권을 전송 받는 자를 말합니다.</li>
                        <li>5. “이용자”라 함은 회사의 서비스에 접속하여 본 이용약관 및 개인정보처리방침에 따라 회사와 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는
                            회원 또는 비회원을 말합니다.
                        </li>
                        <li>6. ”회원등급”이라 함은 회사가 지정하는 기준에 따라 적용되는 회원 별 등급으로서 회사는 회원의 회원등급에 따라 혜택을 차등 지급할 수 있습니다.
                        </li>
                    </ul>
                </li>
                <li>② 본 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계법령, 상•관례 및 서비스별 안내에서 정하는 바에 의합니다.</li>
            </ul>
            <h2>제 3 조 [약관의 효력 및 변경]</h2>
            <ul>
                <li>① 본 약관은 서비스를 이용하고자 하는 모든 이용자에게 그 효력을 발생합니다.</li>
                <li>② 본 약관은 회사가 서비스를 통하여 공지하거나 기타의 방법으로 이용자에게 통지하고, 이에 동의한 이용자가 서비스를 이용함으로써 효력을 발생합니다.</li>
                <li>③ 회사는 필요하다고 인정되는 경우 본 약관을 개정할 수 있으며, 약관을 개정할 경우 그 적용일자 및 개정사유를 명시하여 현행 약관과 함께 개정약관의 적용일자 7일
                    전부터 적용일자 전일까지 제2항과 같은 방법으로 공시합니다. 단, 이용자의 권리 또는 의무에 관한 중요한 규정을 변경하거나 이용자에게 불리하게 약관의 내용을 변경하는
                    경우에는 적용일자로부터 30일 전부터 공시합니다.
                </li>
                <li>④ 본 약관의 적용기간은 이용자의 가입일부터 해지일까지로 규정합니다.</li>
                <li>⑤ 이용자는 변경된 약관 사항에 동의하지 않을 권리가 있으며, 동의하지 않은 경우 서비스 이용이 제한되거나 서비스 이용을 중단하고 이용계약을 해지할 수 있습니다.
                    단, 이용자가 제 3항의 방법으로 고지한 바에 따라 변경된 약관의 적용일로부터 7일 이내에 별도의 의사표시가 없는 경우에는 약관의 변경사항에 동의한 것으로
                    간주합니다.
                </li>
                <li>⑥ 회사와 이용자는 필요에 따라 별도의 계약(서면합의)을 할 수 있으며 해당 내용이 본 약관과 상충할 경우 별도의 계약서에 명시된 내용이 우선하여 적용됩니다.
                </li>
            </ul>
            <h2>제 4 조 [약관 외 준칙]</h2>
            <p>본 약관에서 정하지 않은 사항과 본 약관의 해석에 관하여는 전기통신사업법, 정보통신망법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률 및 기타
                관계법령 또는 상•관례에 따릅니다.</p>
            <h1>제 2 장 서비스 이용계약</h1>
            <h2>제 5 조 [이용계약의 체결]</h2>
            <ul>
                <li>① 이용계약은 회원이 되고자 하는 자가 본 약관의 내용 및 개인정보처리방침에 대하여 동의 후 회원 가입하거나 비회원이 회사가 정한 양식에 따라 모바일 상품권 구매 시
                    본 약관에 동의함으로써 체결됩니다.
                </li>
            </ul>
            <h2>제 6 조 [이용승낙의 제한]</h2>
            <ul>
                <li>① 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 단, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에
                    이용계약을 해지할 수 있습니다.
                    <ul class="in-one">
                        <li>1. 가입신청자가 본 약관에 의하여 이전에 회원 자격을 상실한 적이 있는 경우. 단, 회원이 회사의 재가입 승낙을 얻을 경우에는 예외로 함</li>
                        <li>2. 실명이 아니거나 타인의 명의를 이용한 경우</li>
                        <li>3. 허위의 정보를 기재하거나 회사가 제시하는 내용을 기재하지 않은 경우</li>
                        <li>4. 가입신청자가 만 19세 미만인 경우</li>
                        <li>5. 가입신청자가 서비스의 정상적인 제공을 저해하거나 다른 이용자의 서비스 이용에 지장을 줄 것으로 예상되는 경우</li>
                        <li>6. 가입신청자의 귀책사유로 인하여 승낙이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</li>
                        <li>7. 회사가 제공하는 모든 서비스 중 어느 하나에 대해 제7조[이용계약의 해지] 제3항에 의하여 회사로부터 계약해지를 당한 이후 1년이 경과하지 않은
                            경우
                        </li>
                        <li>8. 부정결제가 의심되는 경우</li>
                    </ul>
                </li>
                <li>② 본 조 제1항에 따른 신청에 있어 회사는 이용자의 종류에 따라 전문기관을 통한 본인인증을 요청할 수 있습니다.</li>
                <li>③ 회사는 본 조 제1항 각호에 따라 이용신청이 이루어지지 않는지 관리•감독할 수 있습니다.</li>
                <li>④ 회사는 다음 각 호에 해당하는 신청에 대하여 승낙을 지연할 수 있습니다.
                    <ul class="in-one">
                        <li>1. 회사의 설비에 여유가 없거나 기술적 장애가 있는 경우</li>
                        <li>2. 서비스상의 장애 또는 서비스 이용요금 결제수단의 장애가 발생한 경우</li>
                        <li>3. 기타 회사가 재정적•기술적으로 필요하다고 인정하는 경우</li>
                    </ul>
                </li>
                <li>⑤ 회사는 제1항과 제4항의 규정에 의하여 승낙을 거부 또는 지연하거나 이용계약을 해지할 경우 이를 이용계약을 신청한 자에게 알려야 합니다. 단, 사전에 통지가
                    어려운 경우 거부•지연•해지 이후 통지할 수 있습니다.
                </li>
            </ul>
            <h2>제 7 조 [이용계약의 해지]</h2>
            <ul>
                <li>① 이용자는 이용계약을 해지하고자 할 때에는 이용자 본인이 직접 서비스를 통하여 탈퇴를 진행할 수 있습니다.</li>
                <li>② 회사는 제1항의 규정에 의하여 이용자의 서비스 탈퇴 즉시 이용계약을 해지합니다. 단, 별도의 채권•채무 관계가 있을 경우에는 그러하지 아니합니다.</li>
                <li>③ 회사는 이용자가 다음 각 호에 해당할 경우 이용자의 동의 없이 이용계약을 해지할 수 있으며 이용계약 해지 7일 전까지 이용자 또는 그 대리인에게 통지합니다. 단,
                    회사가 긴급하게 해지할 필요가 있다고 인정하는 경우나 이용자의 귀책사유로 인하여 통지할 수 없는 경우에는 사후 통지로 대체할 수 있습니다.
                    <ul class="in-one">
                        <li>1. 이용자가 본 약관을 위반하고 일정 기간 이내에 위반 내용을 해소하지 않은 경우</li>
                        <li>2. 회사의 서비스 제공목적 외의 용도로 서비스를 이용하거나 제3자에게 임의로 서비스를 임대한 경우</li>
                        <li>3. 이용자가 타인 명의로 서비스를 이용하였거나 허위의 사실의 기재 또는 허위 서류를 첨부하여 이용계약을 체결한 경우</li>
                        <li>4. 회사의 이용요금 납입청구에 대하여 이용자가 이용요금을 체납할 경우</li>
                        <li>5. 제14조[회원의 의무] 규정을 위반한 경우</li>
                    </ul>
                </li>
                <li>④ 회사는 제3항의 규정에 의하여 해지된 이용자에 대하여는 일정 기간 동안 재가입을 제한할 수 있습니다.</li>
                <li>⑤ 이용자 또는 회사가 이용계약을 해지할 경우 관련법 및 개인정보처리방침에 따라 회사가 이용자 정보를 보유하는 경우를 제외하고는 해지 즉시 이용자의 모든 데이터는
                    소멸됩니다.
                </li>
            </ul>
            <h1>제 3 장 서비스 이용</h1>
            <h2>제 8 조 [서비스의 내용]</h2>
            <ul>
                <li>① 회사가 제공하는 서비스의 내용은 다음과 같습니다.
                    <ul class="in-one">
                        <li>1. 모바일 상품권 발송 서비스</li>
                        <li>2. 기타 회사가 자체 개발하거나 다른 회사와의 협력 계약 등을 통해 이용자에게 제공하는 일체의 서비스</li>
                    </ul>
                </li>
                <li>② 서비스는 연중무휴, 1일 24시간 제공을 원칙으로 합니다. 단, 회사는 서비스 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 회사가 사전에
                    통지한 바에 따릅니다.
                </li>
                <li>③ 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있으며, 이러한 경우에는 그 내용을 사전에 공지합니다. 단, 회사가 사전에
                    통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
                </li>
                <li>④ 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
                    <ul class="in-one">
                        <li>1. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>
                        <li>2. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우</li>
                        <li>3. 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우</li>
                        <li>4. 기타 서비스를 유지할 수 없는 중대한 사유가 발생한 경우</li>
                    </ul>
                </li>
                <li>⑤ 본 조 제4항의 경우 회사는 제17조[회원에 대한 통지]에 정한 방법으로 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우
                    사후에 통지할 수 있습니다.
                </li>
                <li>⑥ 회사는 모바일 상품권 판매 시 다음의 사항을 표시합니다.
                    <ul class="in-one">
                        <li>1. 발행자</li>
                        <li>2. 구매가격(할인된 경우 할인율 및 할인금액)</li>
                        <li>3. 유효기간(유효기간이 설정된 경우에 한함)</li>
                        <li>4. 사용조건(사용 가능 금액, 제공 물품이나 용역, 수량 등)</li>
                        <li>5. 사용가능 가맹점</li>
                        <li>6. 환불 조건 및 방법</li>
                        <li>7. 소비자피해 발생 시 연락할 관련 전화번호 등</li>
                    </ul>
                </li>
            </ul>
            <h2>제 9 조 [서비스의 변경 또는 중단]</h2>
            <ul>
                <li>① 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경 또는 중단할 수 있습니다.</li>
                <li>② 서비스의 내용, 이용방법, 이용시간에 대하여 변경 또는 중단이 있는 경우에는 변경 또는 중단 사유, 변경 또는 중단될 서비스의 내용 및 제공일자 등을 그 변경
                    또는 중단 전에 해당 서비스 초기화면에 게시하여야 합니다.
                </li>
                <li>③ 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며 이에 대하여 관련법에 특별한 규정이 없는 한
                    이용자에게 별도의 보상을 하지 않습니다.
                </li>
            </ul>
            <h2>제 10 조 [서비스 이용의 제한 및 정지]</h2>
            <ul>
                <li>① 회사는 이용자가 주민등록법을 위반한 명의도용 및 결제도용, 저작권법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망법을 위반한 불법통신, 해킹,
                    악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 서비스 이용정지 시 서비스 내의 혜택
                    및 권리 등도 모두 소멸되며 회사는 이에 대해 별도로 보상하지 않습니다.
                </li>
                <li>② 회사는 다음 각 호에 해당하는 경우에 이용자의 서비스 이용을 제한할 수 있습니다.
                    <ul class="in-one">
                        <li>1. 유료서비스를 사용한 이용자가 요금 등을 납입할 의무를 이행하지 아니한 경우</li>
                        <li>2. 제14조[이용자의 의무] 규정에 의한 이용자의 의무를 이행하지 아니한 경우</li>
                        <li>3. 이용자의 이름 등 개인정보가 정확하지 않은 경우</li>
                        <li>4. 다른 이용자 또는 제3자의 지식재산권을 침해하거나 명예를 손상시킨 경우</li>
                        <li>5. 이용자의 모바일 상품권 구매 목적이 사회질서 및 미풍양속에 명백하게 반하는 경우</li>
                        <li>6. 이용자가 서비스를 임의로 재판매 하거나 변형하여 제3자가 이용하도록 하는 경우</li>
                        <li>7. 서비스를 이용하여 얻은 정보를 이용고객의 개인적인 이용 이외에 복사, 가공, 번역, 2차적 저작물 등을 제작하여 복제, 공연, 방송, 전시,
                            배포, 출판 등에 사용하는 경우
                        </li>
                        <li>8. 이용자가 서비스를 통하여 구매한 모바일 상품권을 제3자에게 회사의 사전 동의 없이 재판매 하는 경우</li>
                        <li>9. 회사의 사전 동의 없이 서비스를 도용(자체 이미지 제작, 유효기간 임의 조정 등)하는 경우</li>
                        <li>10. 기타 회사가 이용자로서 부적당하다고 판단한 경우</li>
                    </ul>
                </li>
                <li>③ 회사가 본 조 제2항의 규정에 의하여 서비스를 제한 또는 정지하는 경우 그 사실을 이용자에게 사전 통보하여야 합니다. 단, 사전에 통보하는 것이 곤란할 경우 제한
                    또는 정지 조치 이후 통보할 수 있습니다.
                </li>
                <li>④ 회사는 12개월 이상 서비스를 이용하지 아니하는 이용자의 개인정보를 보호하기 위해 이용자의 계정을 휴면계정으로 분류하고 서비스 이용계약을 해지하거나 개인정보의
                    파기 또는 별도 분리보관 등 필요한 조치를 할 수 있습니다.
                </li>
                <li>⑤ 회사는 휴면계정으로 분류되기 30일 전까지 개인정보가 파기되거나 분리되어 저장되는 사실과 기간 만료일 및 해당 개인정보의 항목을 이메일, 서면, 모사전송, 전화
                    또는 유사한 방법 중 하나의 방법으로 이용자에게 알리도록 합니다. (단, 이용자의 정보가 정확하지 않은 경우 알림 대상에서 제외될 수 있습니다.)
                </li>
                <li>⑥ 제4항에 의한 서비스 이용 계약 해지 시 데이터 삭제에 대한 책임은 이용자가 부담하며, 회사는 고의 또는 과실이 없는 한 데이터 삭제에 따른 책임을 부담하지
                    않습니다.
                </li>
                <li>⑦ 회사가 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우, 회사는 제17조[회원에 대한 통지]에 정한 방법으로
                    이용자에게 통지하고 서비스를 종료할 수 있습니다.
                </li>
            </ul>
            <h1>제 4 장 계약당사자의 권리 및 의무</h1>
            <h2>제 11 조 [회사의 의무]</h2>
            <ul>
                <li>① 회사는 이용자에게 제공하는 유료 및 무료 서비스를 안정적으로 제공하기 위하여 최선을 다해야 합니다.</li>
                <li>② 회사는 안정적인 서비스를 지속적으로 제공하기 위하여 설비에 장애가 생기거나 설비가 멸실 되었을 때 지체 없이 이를 수리 또는 복구합니다. 단, 천재지변이나
                    비상사태 등 부득이한 경우에는 서비스를 일시 중단하거나 영구 중지할 수 있습니다.
                </li>
                <li>③ 회사는 이용자로부터 제기되는 의견이나 불만을 정당하다고 인정할 경우에는 즉시 처리하여야 합니다. 단, 즉시 처리가 곤란한 경우는 이용자에게 그 사유를 이메일 또는
                    전화 등으로 통보하여야 합니다.
                </li>
                <li>④ 회사는 서비스 제공과 관련하여 취득한 이용자의 개인정보를 본인의 동의 없이 타인에게 누설하거나 배포할 수 없으며 서비스 관련 업무 이외의 목적으로는 사용할 수
                    없습니다. 단, 다음 각 호에 해당하는 경우에는 예외로 합니다.
                    <ul class="in-one">
                        <li>1. 금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용 및 보호에 관한 법률, 전기통신기본법, 전기통신사업법, 소비자기본법, 형사소송법 등 관련
                            법령에 특별한 규정이 있는 경우
                        </li>
                        <li>2. 방송통신심의위원회가 이 약관에 의하여 이용 제한을 받은 이용자의 이용 아이디, 성명 및 이용제한 사유 등을 요청하는 경우</li>
                        <li>3. 유료서비스 제공에 따른 요금 정산을 위하여 필요한 경우</li>
                        <li>4. 통계 작성 또는 시장조사를 위하여 필요한 경우로서 특정 이용자를 식별할 수 없는 형태로 제공하는 경우</li>
                    </ul>
                </li>
                <li>⑤ 회사가 제4항의 규정에도 불구하고 공지 없이 명시한 범위를 초과하여 이용자의 개인정보를 이용하거나 제3자에게 제공하는 경우에는 반드시 해당 이용자에게 개별적으로
                    이메일이나 전화 등으로 통지하여 동의를 받아야 합니다.
                </li>
                <li>⑥ 회사는 개인정보의 수집 목적을 달성한 후에는 해당 이용자의 개인정보를 지체 없이 파기하여야 합니다. 단, 다음 각 호에 해당하는 경우에는 예외로 합니다.
                    <ul class="in-one">
                        <li>1. 상법 등 관계 법령의 규정에 의하여 보존할 필요성이 있는 경우</li>
                        <li>2. 본 약관 내 별도 규정에 의하여 보유기간을 미리 이용자에게 고지하거나 명시한 경우</li>
                        <li>3. 이용자에게 개별적으로 동의를 받은 경우</li>
                    </ul>
                </li>
                <li>⑦ 회사는 다음 각 호의 내용을 포함하는 개인정보처리방침을 정하여 지속적으로 실행하여야 하며, 서비스 페이지를 통하여 이용자에게 공지하여야 합니다.
                    <ul class="in-one">
                        <li>1. 개인정보 수집항목 및 목적</li>
                        <li>2. 개인정보보호를 위한 기술적, 관리적 대책</li>
                        <li>3. 개인정보보호 관련 불만처리에 관한 사항</li>
                        <li>4. 개인정보처리방침의 개정에 관한 사항</li>
                    </ul>
                </li>
                <li>⑧ 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약관련 절차 및 내용 등에 있어 이용자에게 편의를 제공하도록 노력합니다.</li>
            </ul>
            <h2>제 12 조 [이용자의 의무]</h2>
            <ul>
                <li>① 이용자는 관계 법령, 본 약관의 규정, 이용안내 및 주의사항 등 회사가 통지하는 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 아니
                    됩니다.
                </li>
                <li>② 이용자는 주소 및 연락처 등 이용계약사항과 관련된 개인정보가 변경된 경우에는 이를 즉시 회사에 통보하여야 합니다.</li>
                <li>③ 이용자는 서비스를 이용함에 있어 공공질서 또는 미풍양속을 해치는 행위, 또는 다음 각 호에 해당하는 행위를 해서는 안 됩니다.
                    <ul class="in-one">
                        <li>1. 다른 이용자의 이용 아이디를 부정 사용하는 행위</li>
                        <li>2. 해킹 행위 또는 컴퓨터 바이러스의 유포행위</li>
                        <li>3. 타인의 의사에 반하여 광고성 정보 등 유사한 내용을 지속적으로 전송하는 행위</li>
                        <li>4. 타인의 지적 재산권 등을 침해하는 행위</li>
                        <li>5. 범죄행위를 목적으로 하거나 범죄행위를 교사하는 행위</li>
                        <li>6. 반국가적 범죄의 수행을 목적으로 하는 행위</li>
                        <li>7. 선량한 풍속 또는 기타 사회질서를 해치는 행위</li>
                        <li>8. 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위</li>
                    </ul>
                </li>
                <li>④ 이용자는 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복사, 복제, 변경, 번역, 출판, 방송 및 기타의 방법으로 사용하거나 이를 타인에게 제공할 수
                    없습니다.
                </li>
            </ul>
            <h2>제 13 조 [관리책임의 의무]</h2>
            <ul>
                <li>① 이용자는 서비스 이용을 위한 아이디, 비밀번호 관리에 대한 책임 및 본인 아이디가 제3자에 의해 부정사용 되는 등 이용자의 고의 또는 과실로 인해 발생하는 모든
                    불이익에 대한 책임을 부담합니다. 단, 회사의 고의 또는 과실로 인하여 야기된 경우는 회사가 책임을 부담합니다.
                </li>
                <li>② 회사는 이용자의 CS 처리 목적을 위하여 이용자가 발송한 모바일 상품권의 발송내역과 수신내역을 열람할 수 있습니다. 이를 위해 회사는 이용자의 모바일 상품권
                    수•발신 내역 및 서비스 이용에 사용된 휴대전화번호 내역을 별도로 관리할 수 있습니다. 단, 회사가 제공하는 서비스를 통한 수•발신 내역에 국한하며 회사의 서비스를
                    이용하지 않은 이용자의 개별적인 수•발신 내역에 대한 관리 책임은 이용자에게 있습니다.
                </li>
                <li>③ 이용자의 고의 또는 과실로 모바일 상품권 수•발신내역이 노출되어 발생하는 손해에 대해 회사는 책임지지 않습니다.</li>
            </ul>
            <h2>제 14 조 [이용자정보의 열람 및 변경]</h2>
            <ul>
                <li>① 이용자는 언제든지 이용 가입 시 기재한 개인정보를 열람하고 수정할 수 있습니다. 단, 원활한 서비스 운영을 위해 회사가 정한 일부 사항은 수정이 제한될 수
                    있습니다.
                </li>
                <li>② 이용자는 가입 시 기재한 사항이 변경되었을 경우 즉시 이를 변경하여야 합니다. 회사는 이용자 정보를 변경하지 아니하여 발생한 손해에 대하여 책임지지 않습니다.
                </li>
            </ul>
            <h2>제 15 조 [이용자에 대한 통지]</h2>
            <ul>
                <li>① 회사가 이용자에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 이용자가 제공한 이메일 주소 또는 휴대전화번호 정보를 통해 할 수 있습니다.</li>
                <li>② 회사는 이용자 전체에 대한 통지의 경우 게시판 등에 게시함으로써 제1항의 통지에 갈음할 수 있습니다. 단, 이용자 본인의 거래와 관련하여 중대한 영향을 미치는
                    사항에 대하여는 제1항의 통지를 합니다.
                </li>
                <li>③ 이용자가 연락 가능한 전자우편 주소 및 휴대전화번호 등을 회사에 제공하지 않거나 이를 유효한 것으로 갱신하지 않음으로써 통지 받지 못하거나 이용자가 회사의 연락을
                    고의 또는 과실로 수신하지 아니하여 발생하는 이용자의 손해에 대하여 회사는 일체의 책임을 부담하지 않습니다.
                </li>
            </ul>
            <h1>제 5 장 개인정보보호</h1>
            <h2>제 16 조 [개인정보 수집]</h2>
            <ul>
                <li>① 회사는 적법하고 공정한 수단에 의하여 이용계약의 성립 및 이행에 필요한 최소한의 개인정보를 수집합니다.</li>
                <li>② 회사는 개인정보의 수집 시 관련법규에 따라 개인정보 수집 동의 화면과 개인정보처리방침에 그 수집범위 및 목적 등을 사전 고지합니다.</li>
                <li>③ 회사는 회원이 서비스 화면에서 자신의 개인정보에 대한 수집, 이용 또는 제공에 대한 동의를 철회할 수 있도록 필요한 조치를 취해야 합니다.</li>
            </ul>
            <h2>제 17 조 [개인정보보호 의무]</h2>
            <ul>
                <li>① 회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다.</li>
                <li>② 개인정보의 보호 및 사용에 대해서는 관련법 및 회사의 개인정보처리방침이 적용됩니다. 단, 회사의 공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보처리방침이
                    적용되지 않습니다.
                </li>
            </ul>
            <h1>제 6 장 이용요금 등</h1>
            <h2>제 18 조 [요금 등의 종류]</h2>
            <p>서비스 이용과 관련하여 이용자가이 납부하여야 할 요금은 서비스 화면에 게재한 바에 따릅니다.</p>
            <h2>제 19 조 [요금 등의 계산]</h2>
            <ul>
                <li>① 이용 요금은 선납을 기본으로 합니다.</li>
                <li>② 이용자의 서비스 이용요금은 이용자의 모바일 상품권 발송 건수와 모바일 상품권의 권면금액(할인율을 제공하는 경우 할인율이 적용된 금액)을 곱하여 산정됩니다.
                </li>
            </ul>
            <h2>제 20 조 [모바일 상품권의 유효기간]</h2>
            <ul>
                <li>① 물품•용역 제공형 모바일 상품권의 유효기간은 발행일로부터 3개월을 원칙으로 하며, 금액형 모바일 상품권의 유효기간은 발행일로부터 1년을 원칙으로 합니다.</li>
                <li>② 수신자는 유효기간 내에 유효기간의 연장을 신청할 수 있으며, 회사는 특별한 사정(유효기간의 연장이 불가능한 경우로서 모바일 상품권에 명시된 사유)이 없는 한
                    최초의 유효기간을 포함하여 최대 5년까지 3개월 단위로 유효기간을 연장합니다.
                </li>
                <li>③ 회사는 유효기간이 도래하기 7일 전 통지를 포함하여 3회 이상 수신자에게 유효기간의 도래, 유효기간의 연장 가능 여부와 그 방법 등을 이메일 또는 문자메시지 등의
                    방법으로 통지합니다.
                </li>
                <li>④ 프로모션 또는 이벤트 경품 등으로 무상 제공되거나 이용자 혜택을 통해 무상 발행된 모바일 상품권의 경우 본 조의 규정이 적용되지 않습니다.</li>
            </ul>
            <h2>제 21 조 [환불 및 주문취소]</h2>
            <ul>
                <li>① 이용자는 모바일 상품권 발송 후 이용자의 선택에 따라 모바일 상품권 정보를 보기 전(핀잠금해제 전)에 한하여 주문을 취소할 수 있습니다. 모바일 상품권 정보가
                    노출된 경우, 유효기간이 남아 있더라도 환불이나 주문취소를 할 수 없습니다.
                </li>
                <li>② 다음 각 호의 사유에 해당하는 경우 유효기간 내에서 이용자는 주문을 취소하거나 환불을 요청할 수 있습니다.
                    <ul class="in-one">
                        <li>1. 모바일 상품권 전송 시 시스템적인 장애가 발생하거나 결번, 서비스 불가 등의 사유로 인해 영구적으로 수신자에게 모바일 상품권이 전송되지 않는
                            경우
                        </li>
                        <li>2. 주문 시 네트워크 문제로 인해 결제만 수행되고, 모바일 상품권이 수신자에게 발송되지 않은 경우</li>
                        <li>3. 유효기간이 만료되지 않고 실물상품의 단종 등으로 실물상품의 제공이 불가능한 물품•용역 제공형 모바일 상품권</li>
                    </ul>
                </li>
                <li>③ 이용자가 구매한 모바일 상품권이 교환처에서 교환된 이후에는 주문 취소 및 환불이 되지 않습니다.</li>
                <li>④ 이용자가 수신자의 전화번호를 잘못 입력하여 모바일 상품권이 오 발송된 후 수신자에 의해 모바일 상품권이 교환된 경우 회사에서는 이용자의 오 발송에 대해 책임지지
                    않습니다.
                </li>
                <li>⑤ 유효기간이 지난 상품에 대해서는 고객센터로 연락하여 사용여부 확인 등을 통하여 환불이 가능합니다.
                </li>
                <li>⑥ 회사와 기업 회원간에 약정을 통해 발송되거나 프로모션 또는 이벤트 경품으로 무상으로 제공된 모바일 상품권은 유효기간 연장 및 환불이 불가 하며, 본 조의 규정이
                    적용되지 않습니다.
                    <ul>
                        <h2>제 22 조 [양도금지]</h2>
                        <p>회원은 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여, 판매, 담보제공 등의 처분행위를 할 수 없습니다.</p>
                        <h1>제 7 장 손해배상 등</h1>
                        <h2>제 23 조 [손해배상의 범위 및 청구]</h2>
                        <ul>
                            <li>① 회사 또는 이용자가 본 약관의 규정을 위반함으로 인하여 상대방에게 손해가 발생하게 되는 경우 본 약관을 위반한 당사자는 상대방에게 발생한
                                손해를 배상하여야 합니다.
                            </li>
                            <li>② 이용자가 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을
                                비롯한 각종 이의제기를 받는 경우 당해 이용자는 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 이용자는 그로
                                인하여 회사에 발생한 손해를 배상하여야 합니다.
                            </li>
                            <li>③ 이용자의 손해배상의 청구는 회사에 청구사유, 청구금액 및 산출근거를 기재하여 이메일, 전화 등으로 신청하여야 합니다.</li>
                        </ul>
                        <h2>제 24 조 [면책사항]</h2>
                        <ul>
                            <li>① 회사는 다음 각 호의 경우로 서비스를 제공하지 못하여 이용자에게 발생한 손해에 대해서는 책임을 면합니다.
                                <ul class="in-one">
                                    <li>1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</li>
                                    <li>2. 서비스의 효율적인 제공을 위한 시스템 개선, 장비 증설 등 계획된 서비스 중지 일정을 사전에 공지한 경우</li>
                                    <li>3. 이용자의 귀책사유로 서비스 이용에 장애가 있는 경우</li>
                                    <li>4. 회사의 고의 과실이 없는 사유로 인한 경우</li>
                                    <li>5. 회사에게 회선, 통신망, 전용선을 제공하고 있는 이동통신사 또는 부가통신사업자 측의 장애•귀책사유로 인한 경우</li>
                                </ul>
                            </li>
                            <li>② 회사는 이용자가 서비스를 통해 얻은 정보 또는 자료 등으로 인해 발생한 손해와 서비스를 이용하거나 이용할 것으로부터 발생하거나 기대하는 손익
                                등에 대하여 책임을 면합니다.
                            </li>
                            <li>③ 회사는 서비스 이용의 장애로 인하여 발생한 이용자의 부가적, 영업적인 손해에 대해서는 책임을 면합니다.</li>
                            <li>④ 회사는 이용자가 게시 또는 전송한 자료의 내용에 대해서는 책임을 면합니다.</li>
                            <li>⑤ 회사는 이용자 상호 간 또는 이용자와 제3자 상호 간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임을 면합니다.</li>
                            <li>⑥ 회사는 무료로 제공하는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 면합니다.</li>
                            <li>⑦ 회사는 제10조[서비스 이용의 제한 및 정지]에 따라 이용자의 서비스 이용을 정지 또는 제한하는 경우, 이용의 제한으로 발생할 수 있는
                                이용자의 손해 등에 대해서 책임
                            </li>
                        </ul>
                        <h2>제 25 조 [분쟁조정]</h2>
                        <ul>
                            <li>① 회사와 이용자 간 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
                            <li>② 서비스 이용과 관련하여 회사와 이용자 간에 발생한 분쟁에 대해서는 민사소송법상의 주소지를 관할하는 법원을 합의관할로 합니다.</li>
                            <li>③ 해외에 주소나 거소가 있는 이용자의 경우 회사와 이용자간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할법원으로
                                합니다.
                            </li>
                        </ul>
                        <h2>* 부 칙 *</h2>
                        <p>① 본 약관은 2020년 4월 5일부터 시행•적용됩니다.</p>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import vuex, { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            'GET_STORE_INFORMATION': 'im/GET_STORE_INFORMATION',
        })
    }
}

</script>

<style lang="scss" scoped>
</style>