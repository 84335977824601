<template>
  <div id="UserAgentBrowser" v-if="GET_BROWSER.deprecated">
    <div class="background-dim">
      <div class="window">
        <div class="guide">
          <p>해당 브라우저는 지원하지 않습니다.</p>
          <p>chrome 기반의 최신 브라우저를 권장합니다.</p>
        </div>
        <div class="button-group">
          <button class="approved" @click="hideBrowserDeprecated">{{ $t('COMMON.AGREE') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vuex from 'vuex'

export default {
  data() {
    return {
      }
  },
  computed: {
    ...vuex.mapGetters({
      'GET_BROWSER': 'GET_BROWSER'
    })
  },
  destroyed() {
    this.HIDE_BROWSER_DEPRECATED();
  },
  methods: {
    ...vuex.mapActions({
      'HIDE_BROWSER_DEPRECATED': 'HIDE_BROWSER_DEPRECATED'
    }),
    hideBrowserDeprecated() {
      this.HIDE_BROWSER_DEPRECATED();
    }
  }
}
</script>

<style lang="scss">
#UserAgentBrowser {
  .background-dim {
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    z-index: 2000;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba($color: #000000, $alpha: .8);

    .window {
      min-width: 300px;
      padding: 30px;

      border-radius: 4px;
      background-color: white;

      .guide {
        margin-top: 20px;
        font-size: 16px;
        line-height: 1.44;
      }

      .button-group {
        margin-top: 30px;
        text-align: right;

        .approved {
          font-size: 16px;
          color: #4a9cdd;
        }
      }
    }


  }


}
</style>