<template>
  <header id="header" v-if="isHeader">
    <div class="header">
      <span>{{ this.headerText }}</span>
      <!-- <button class="home" @click="goHome()" v-if="btn.isHome"></button> -->
      <button class="menu" :class="{ 'menu-on': getMenuStatus }" @click="toggleMenu" v-if="btn.isHome"></button>
      <button class="back" @click="goBack()" v-if="btn.isBack"></button>
      <button class="cart" @click="goCartList" v-if="btn.isCart">
        <span class="cart-quantity"><span>{{ getCart.length }}</span></span>
      </button>
      <!-- <button class="cart" @click="goCartList()" v-if="btn.isCart"><span class="cart-num">
        <div class="outer">
          <div class="inner">
            <div class="centered">
              {{ obCartLength }}
            </div>
          </div>
        </div>
      </span></button> -->
      <button class="product-list" @click="goProductList()" v-if="btn.isProductList"></button>
      <button class="call-store" v-if="btn.isCallStore" @click="callStore()">{{ $t('ORDER_HISTORY.STORE_TEL') }}</button>
    </div>

    <SideMenu />
  </header>
</template>

<script>
import vuex from 'vuex'

import SideMenu from './SideMenu.vue'

import language from '../filters/language.js'

export default {
  components: {
    SideMenu
  },
  data() {
    return {
      isHeader: true,
      btn: {
        isHome: false,
        isBack: false,
        isCart: false,
        isProductList: false,
        isCallStore: false
      },
      backPageHistory: `/main/${this.$store.state.storeID}`
    }
  },
  computed: {
    ...vuex.mapGetters({
      'getMenuStatus': 'getMenuStatus',
      'getStoreImformation': 'getStoreImformation',
      'getCart': 'getCart'
    }),
    headerText() {
      return this.$store.state.preferences.headerText
    },
    obCartLength() {
      return this.$store.state.cart.length;
    },
  },
  watch: {
    '$route' (to, from) {
      this.setHeader(to.path);
    }
  },
  methods: {
    ...vuex.mapMutations({
      'hideSideMenu': 'hideSideMenu'
    }),
    ...vuex.mapActions({
      'TOGGLE_SIDE_MENU': 'TOGGLE_SIDE_MENU'
    }),
    toggleMenu() {
      this.TOGGLE_SIDE_MENU();
    },
    goHome() {
      this.$router.push(`${this.$store.state.gRoot}/main/${this.$store.state.storeID}`);
    },
    goCartList() {
      this.$router.push(`${this.$store.state.gRoot}/cart/list`);
    },
    goProductList() {
      this.$router.push(`${this.$store.state.gRoot}/product/list`);
    },
    goBack() {
      // console.log(this.backPageHistory);
      if(this.backPageHistory === '-1') {
        this.$router.go(-1)
      } else {
        // console.log(this.backPageHistory)
        this.$router.push(`${this.$store.state.gRoot}${this.backPageHistory}`);
      }
    },
    setHeadeText(text) {
      this.$store.commit('setPreference', {
        key: 'headerText',
        value: text
      })
    },
    setHeader(path) {
      // console.log(path)
      if(path === "/" || path === "/cus" || path === "/cus/" || /\/page\/error/.test(path) ) {
        this.isHeader = false;
        this.$router.push(`${this.$store.state.gRoot}/page/error`);
        return;
      }

      this.isHeader = true;
      this.btn.isHome = false;
      this.btn.isBack = false;
      this.btn.isCart = false;
      this.btn.isProductList = false;
      this.btn.isCallStore = false;

      if(/\/main\/*/.test(path)) {
        this.isHeader = false;
      } else if(/\/product\/list/.test(path) || /\/home\/list/.test(path)) {
        this.btn.isHome = true;
        this.btn.isCart = true;
        this.btn.isProductList = false;
        this.setHeadeText(language(this.$store.state.storeInformation.store_names));
      } else if(/\/product\/detail/.test(path)) {
        this.btn.isBack = true;
        this.btn.isCart = true;
        this.backPageHistory = `/home/list`;
        this.setHeadeText(language(this.$store.state.storeInformation.store_names));
      } else if(/\/order\/payment/.test(path)) {
        this.btn.isBack = true;
        this.backPageHistory = `/home/list`;
        this.setHeadeText(this.$t('ORDER_PAYMENT.INDEX'));
      } else if(/\/cart\/list/.test(path)) {
        this.btn.isBack = true;
        this.btn.isProductList = true;
        this.backPageHistory = `/home/list`;
        this.setHeadeText(this.$t('SHOPPING_BASKET.INDEX'));
      } else if(/\/order\/phone/.test(path)) {
        this.btn.isBack = true;
        this.backPageHistory = `/order/payment`;
        this.setHeadeText(this.$t('ORDER_PAYMENT.INDEX'));
      } else if(/\/order\/creditcard/.test(path)) {
        this.isHeader = false;
        this.backPageHistory = `/home/list`
        this.setHeadeText(this.$t('ORDER_PAYMENT.INDEX'));
      } else if(/\/order\/result/.test(path)) {
        this.isHeader = true;
      } else if(/\/history\/search/.test(path)) {
        this.backPageHistory = `/home/list`;
        this.btn.isBack = true;
        this.setHeadeText(this.$t('ORDER_HISTORY.INDEX'));
      } else if(/\/history\/list/.test(path)) {
        this.backPageHistory = `/history/search`;
        this.btn.isHome = true;
        this.setHeadeText(this.$t('ORDER_HISTORY.INDEX'));
      } else if(/\/history\/detail/.test(path)) {
        this.backPageHistory = `/history/list`;
        this.btn.isBack = true;
        this.btn.isCallStore = true;
        this.setHeadeText(this.$t('ORDER_HISTORY.INDEX'));
      } else if(/\/custom\/price/.test(path)) {
        this.backPageHistory = `/home/list`;
        this.btn.isBack = true;
        this.setHeadeText(language(this.$store.state.storeInformation.store_names));
      } else if (/\/order\/failure/.test(path)) {
        this.setHeadeText(this.getStoreImformation.store_name);
      }
    },
    callStore() {
      location.href = `tel:${this.$store.state.storeInformation.phone_no}`;
    }
  },
  created() {
    this.setHeader(this.$route.path);
  },
  destroyed() {
    this.hideSideMenu();
  }
}
</script>

<style lang='scss'>
#header {
  height: 48px;

  .header {
    @include desktop();
    
    position: fixed;
    z-index: 100;

    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 48px;

    font-size: 16pt;
    font-weight: 700;

    background-color: #fff;
    color: #000;

    text-align: center;
    line-height: 48px;

    .menu {
      position: absolute;

      left: 6px;

      width: 38px;
      height: 48px;

      background-image: url('../assets/images/menu_open.svg');
      background-size: 38px;
      background-position: center;
      background-repeat: no-repeat;

      transition: all .5s;
    }

    .menu-on {
      background-image: url('../assets/images/menu_close.svg');
    }

    .home {
      position: absolute;

      left: 6px;

      width: 38px;
      height: 48px;

      background-image: url('../assets/images/nav_home.png');
      background-size: 38px;
      background-position: center;
      background-repeat: no-repeat;
    }
    .back {
      position: absolute;

      left: 6px;

      width: 38px;
      height: 48px;

      background-image: url('../assets/images/nav_back_n.png');
      background-size: 38px;
      background-position: center;
      background-repeat: no-repeat;

      &:active {
        background-image: url('../assets/images/nav_back_p.png');
      }
    }
    // .cart {
    //   position: absolute;

    //   right: 11.333333px;

    //   width: 44px;
    //   height: 44px;
    //   padding-left: 23px;

    //   background-image: url('../assets/images/icon-cart.svg');
    //   background-size: 23px 22px;
    //   background-position: left center;
    //   background-repeat: no-repeat;

    //   .cart-num {
    //     display: inline-block;

    //     width: 18.666667px;
    //     height: 18.666667px;

    //     border-radius: 9.333333px;
    //     font-size: 11pt;
        
    //     background-color: #ff8b45;
    //     color: #fff;

    //     line-height: 18.666667px;
    //   }
    // }
    .cart {
      // position: relative;
      position: absolute;
      right: 0;
      width: 44px;
      height: 48px;

      background-image: url('../assets/images/icon-cart.svg');
      background-position: left 2px;

      .cart-quantity {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 3px;
        bottom: 3px;
        width: 22px;
        height: 22px;
        
        border-radius: 11px;
        background-color: #ff8b45;
        font-size: 12px;
        font-weight: bold;
        color: white;

      }
    }
    .product-list { 
      position: absolute;

      right: 11.333333px;

      width: 38px;
      height: 48px;

      background-image: url('../assets/images/nav_plus_n.png');
      background-size: 38px;
      background-position: left center;
      background-repeat: no-repeat;

      &:active {
        background-image: url('../assets/images/nav_plus_p.png');
      }
    }

    .call-store { 
      position: absolute;

      right: 11.333333px;

      width: 77px;
      height: 48px;
      padding-right: 11px;

      font-size: 13pt;

      background-image: url('../assets/images/bt_nav_shop_n.png');
      background-size: 11px 24px;
      background-position: right center;
      background-repeat: no-repeat;
      color: #5e5e5e;

      &:active {
        background-image: url('../assets/images/bt_nav_shop_p.png');
        color: rgba(#5e5e5e, .3)
      }
    }
  }
}
</style>
