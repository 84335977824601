<template>
  <div id="NewHeader">
    <div class="header-area"></div>
    <header class="header">
      <div class="left-button-group">
        <button class="menu" :class="{ 'menu-on': getMenuStatus }" @click="toggleMenu"></button>
      </div>
      <span class="title">{{ getStoreImformation.store_names | language }} </span>
      <div class="right-button-group">
        <button class="language-list" @click="showLanguageList" v-if="GET_MULTILINGUAL_BUTTON">
          <div class="language-flags" :class="{[GET_LANG]: true}"></div>
        </button>
        <button class="cart" @click="routeCartList">
          <span class="cart-quantity"><span>{{ getCart.length }}</span></span>
        </button>
      </div>
    </header>

    <SideMenu></SideMenu>
    <Multilingual></Multilingual>
  </div>
</template>

<script>
import vuex from 'vuex'

import language from '../filters/language.js'

import SideMenu from './SideMenu.vue'
import Multilingual from './Multilingual.vue'

export default {
  components: {
    SideMenu,
    Multilingual
  },
  computed: {
    ...vuex.mapGetters({
      'getMenuStatus': 'getMenuStatus',
      'getStoreImformation': 'getStoreImformation',
      'getCart': 'getCart',
      'GET_LANG': 'lang/GET_LANG',
      'GET_MULTILINGUAL_BUTTON': 'lang/GET_MULTILINGUAL_BUTTON'
    }),
  },
  filters: {
    language
  },
 destroyed() {
   this.HIDE_MULTILINGUAL();
 },
  methods: {
    ...vuex.mapMutations({
      'hideSideMenu': 'hideSideMenu'
    }),
    ...vuex.mapActions({
      'TOGGLE_SIDE_MENU': 'TOGGLE_SIDE_MENU',
      'SET_LANGUAGE': 'lang/SET_LANGUAGE',
      'SHOW_MULTILINGUAL': 'lang/SHOW_MULTILINGUAL',
      'HIDE_MULTILINGUAL': 'lang/HIDE_MULTILINGUAL'
    }),
    setLanguage() {
      let language = this.getStoreImformation.language;
      let languageList = this.getStoreImformation.language_list;

      for(let lang of languageList) {
        if(language.default === lang.lang) {
          this.SET_LANGUAGE(lang)
        }
      }
    },
    toggleMenu() {
      this.TOGGLE_SIDE_MENU();
    },
    routeCartList() {
      this.$router.push(`${this.$store.state.gRoot}/cart/list`);
    },
    showLanguageList() {
      this.SHOW_MULTILINGUAL();
    }
  }
}
</script>

<style lang="scss">
#NewHeader {
  .header-area {
    height: 48px;
  }

  .header {
    @include desktop();
    
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 48px;

    background-color: white;

    .title {
      display: inline-block;
      height: 100%;
      padding-left: 10px;

      font-size: 16pt;
      font-weight: bold;

      line-height: 48px;
    }

    .left-button-group {
      float: left;
      height: 100%;

      .menu {
        width: 44px;
        height: 48px;

        background-image: url('../assets/images/menu_open.svg');
        background-size: 38px;
        background-position: center;
        background-repeat: no-repeat;

        transition: all .5s;
      }

      .menu-on {
        background-image: url('../assets/images/menu_close.svg');
      }
    }
    .right-button-group {
      display: flex;
      float: right;
      height: 100%;

      .language-list {
        width: 44px;
        height: 44px;
        margin-right: 2px;
        padding: 7px;

        background-image: url('../assets/images/flag-korean.jpg');
        background-size: 44px;
        background-position: center;
        background-repeat: no-repeat;

        .language-flags {
          width: 100%;
          height: 100%;
          border: solid 1px #e1e1e1;
          border-radius: 15px;
          background-size: 30px;
          background-position: center;
        }

        .ko {
          background-image: url('../assets/images/flags/korea.jpg');
        }
        .en {
          background-image: url('../assets/images/flags/united_state.jpg');
        }
        .ja {
          background-image: url('../assets/images/flags/japan.svg');
        }
        .zh {
          background-image: url('../assets/images/flags/china.svg');
        }
      }

      

      .cart {
        position: relative;
        width: 44px;
        height: 48px;

        background-image: url('../assets/images/icon-cart.svg');
        background-position: left 2px;

        .cart-quantity {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: 3px;
          bottom: 3px;
          width: 22px;
          height: 22px;
          
          border-radius: 11px;
          background-color: #ff8b45;
          font-size: 12px;
          font-weight: bold;
          color: white;

        }
      }
    }
  }


}
</style>