<template>
  <div id="productList">
    <div class="category-list">
      <button v-for="(category, index) in obCategoryList" :key="index" @click="getProductList(category.category_id)" class="category-item" :class="{ checked: onCategoryItem(category.category_id) }">{{ category.category_name | language }}</button>
    </div>

    <div class="product-list">
      <div class="product-item-wrap" v-for="(product, index) in obProductList" :key="index" @click="goProductDetail(product.menu_id)">
        <div class="product-item">
          <div class="sold-out" v-if="product.sold_out_yn === 'Y'">
            <div class="outer">
              <div class="inner">
                <div class="centered sold-out-text">품절</div>
              </div>
            </div>
          </div>
          <span class="discount-percent" v-if="product.dc_percent>0">{{ product.dc_percent }}%</span>
          <div class="product-image-wrap">
            <!-- <div class="product-image" :style="{ backgroundImage: 'url(' + product.menu_img + ')' }"></div> -->
            <div class="product-image" :style="{ 'background-image': 'url(' + product.menu_img + ')' }"></div>
          </div>
          <div class="product-digest">
            <div class="product-name">{{ product.menu_names | language }}</div>
            <div class="product-price">
              <span class="product-origin-price" v-if="product.dc_price !== 0">{{ product.price + product.dc_price | commaNumber }}원</span>
              <span class="product-discount-price">{{ product.price | commaNumber }}원</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import vuex from 'vuex';

import language from '../filters/language.js'

export default {
  computed: {
    ...vuex.mapGetters({
      'getStoreID': 'getStoreID'
    }),
    obCategoryList() {
      return this.$store.state.categoryList;
    },
    obProductList() {
      return this.$store.state.productList;
    },
  },
  filters: {
    commaNumber(value) {
      value = '' + value;
      let floatingPoint = value.split('.');
      value = floatingPoint[0];

      return `${value.split('').reverse().reduce((acc, digit, i) => {
        if (i > 0 && i % 3 === 0) acc.push(',')
        return [...acc, digit]
      }, []).reverse().join('')}`;
    },
    language
  },
  methods: {
    // 카테고리 리스트를 불러온다.
    getCategoryList() {
      let self = this;

      axios({
        method: 'post',
        url: `${this.$store.state.menucApi}/category/list`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          store_id: this.$store.state.storeID
        }
      })
        .then(function(res) {
          let categoryList = res.data.data;

          self.$store.commit('setState', {
            key: 'categoryList',
            value: categoryList
          });

          // 저장되거나 선택된 카테고리가 없으면 첫번째 카테고리를 선택해준다.
          if(self.$store.state.categoryID === '') {
            self.$store.commit('setState', {
              key: 'categoryID',
              value: categoryList[0].category_id
            })

            self.getProductList(categoryList[0].category_id);
          } else {
            self.getProductList(self.$store.state.categoryID);
          }

        })
        .catch(function(err) {
          console.log(err);  
        })        
    },
    // 선택된 카테고리를 확인한다.
    onCategoryItem(index) {
      return  this.$store.state.categoryID == index;
    },
    // 상품 리스트를 불러온다.
    getProductList(categoryID) {
      let self = this
      // console.log(categoryID)
      // if(categoryID === this.$store.state.categoryID) {
      //   return;
      // }

      axios({
        method: 'post',
        url: `${this.$store.state.menucApi}/menu/list`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          store_id: this.$store.state.storeID,
          category_id: categoryID
        }
      })
        .then(function(res) {
          self.$store.commit('setState', {
            key: 'categoryID',
            value: categoryID
          })
          
          let productList = res.data.data;
          // console.log(productList);
          self.$store.commit('setState', {
            key: 'productList',
            value: productList
          });
        })
        .catch(function(err) {
          console.log(err);
        })
    },
    goProductDetail(productID) {
      let self = this;
      let storeID = this.getStoreID.replace(regex, '');

      let regex = /./gi;

      axios({
        method: 'post',
        url: `${this.$store.state.menucApi}/menu/detail`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          store_id: this.$store.state.storeID.replace(regex, ''),
          menu_id: productID
        }
      })
        .then(function(res) {
          self.$store.commit('setState', {
            key: 'productID',
            value: productID
          })
          
          let productDetail = res.data;

          for(let option of productDetail.option) {
            if(option.option_type === "OT001") {
              for(let [index, content] of option.content.entries()) {
                if(index === 0) {
                  content.selected = true;
                }
              }
            }
          }

          console.log(productDetail)

          self.$store.commit('setState', {
            key: 'productDetail',
            value: productDetail
          });

          self.$router.push(`${self.$store.state.gRoot}/product/detail`);
        })
        .catch(function(err) {
          console.log(err);
        })      
    }
  },
  created() {
    this.getCategoryList();
  }
}
</script>

<style lang="scss">
#productList {
  .category-list {
    overflow: auto;
    width: 100%;
    height: 54px;

    border: {
      top: 1px solid #ebebeb;
      bottom: 1px solid #ebebeb;
    }

    background-color: #fff;
    white-space: nowrap;    

    .category-item {
      display: inline-block;

      height:100%;
      padding: 0 9px;

      font-size: 13pt;

      color: #5e5e5e;

      &:active {
        color: #ff8b45;
      }

      &:first-child {
        padding-left: 9px;
      }
      &:last-childe {
        padding-right: 9px;
      }
    }
    .checked {
      color: #ff8b45;
    }
  }

  .product-list {
    overflow: hidden;

    padding-top: 6px;
    padding-bottom: 4px;

    .product-item-wrap {
      float: left;

      width: 50%;
      padding-bottom: 6px;

      &:nth-child(2n-1) {
        padding-right: 3px;
        padding-left: 10px;
      }
      &:nth-child(2n) {
        padding-right: 10px;
        padding-left: 3px;
      }

      .product-item {
        position: relative;

        background-color: #fff;

        .sold-out {
          position: absolute;
          z-index: 60;

          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          background-color: rgba(#000, .55);

          .sold-out-text {
            font-size: 20pt;
            color: #fff;
          }
        }

        .discount-percent {
          position: absolute;
          z-index: 40;

          top: 0;
          left: 0;

          width: 36px;
          height: 21px;

          background-color: #ff8b45;
          color: #fff;

          text-align: center;
          line-height: 21px;
        }

        .product-image-wrap {
          padding: 10px;

          border-bottom: 1px solid #f7f7f7;

          .product-image {
            padding-top: 100%;

            background-position: center;
            background-size: contain;
          }
        }

        .product-digest {
          padding: 14px 12.666667px 13px;

          .product-name {
            display: -webkit-box;
            overflow: hidden;

            width: 100%;
            height: 24px;

            font-size: 13pt;            

            color: #7c7c7c;

            line-height: 1.44;
            word-wrap: break-word;
            white-space: normal;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          .product-price {
            overflow: hidden;

            margin-top: 6px;

            height: 24px;

            white-space: nowrap;
            text-overflow: ellipsis;

            .product-origin-price {
              margin-right: 6px;
              
              font-size: 11pt;
              color: #b3b3b3;

              line-height: 1.44;
              text-decoration: line-through;
            }

            .product-discount-price {
              font-size: 12pt;
              color: #424242;

              line-height: 1.44;
            }
          }
        }
      }
    }
  }
}
</style>
