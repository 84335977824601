import axios from 'axios'

const state = {

}

const getters = {

}

const mutations = {

}

const actions = {
  async PAY_QR_CODE (context, data) {
    let url = context.rootGetters.GET_LOCATION_URL;
    // console.log(url)
    data.return_url = url.origin;
    // console.log(data)

    if(data.user_tel === undefined) {
      data.user_tel = ' '
    }

    let config = {
      method: 'post',
      url: `${process.env.VUE_APP_API_URL}/menu/order`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    }

    try {
      let res = await axios(config)
      return res;
    } catch (error) {
      return error.response;
    }
  },
  // async PAY_QR_CODE (context, data) {
  //   console.log(data)
  //   console.log( `${context.rootGetters.getAPIURL}/qr/request`,)

  //   let config = {
  //     method: 'post',
  //     url: `${context.rootGetters.getAPIURL}/qr/request`,
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     data: data
  //   }

  //   try {
  //     let res = await axios(config)
  //     return res;
  //   } catch (error) {
  //     return error.response;
  //   }
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}