<template>
  <div>
    <div role="tabpanel" class="tab-pane" id="private">
      <div class="policy mgt40">
        <p>{{ GET_STORE_INFORMATION.corporation || GET_STORE_INFORMATION.store_name }} (이하 “회사”라 한다)의 신유형 상품권 선물 서비스인 {{ GET_STORE_INFORMATION.store_name }}는 고객님의 개인정보를 보호하기 위해 관련 법령(「정보통신망
          이용촉진 및 정보보호 등에 관한 법률」, 「개인정보 보호법」등)을 준수하고 있으며 개인정보 처리방침을 정하여 고객님의 개인정보 보호를 최우선으로 하고 있습니다. </p>
        <h2>1. 개인정보 수집/이용 등에 대한 동의</h2>
        <p>회사는 홈페이지, 전화/팩스를 통한 서비스 가입, 경품행사 응모, 배송 요청, 개인정보 처리업무 수탁사 또는 제휴사 제공, 생성정보 수집 툴 등을 통해 개인정보를 수집하고
          있으며 고객님은 다음의 동의 방법 중 하나를 선택하실 수 있습니다.</p>
        <ul>
          <li>① 회사에서 운영하는 유무선 {{ GET_STORE_INFORMATION.store_name }} 사이트, 상담게시판 등을 통한 직접 동의하는 방법</li>
          <li>② 전화({{ GET_STORE_INFORMATION.phone_no }})로 상담하는 경우 상담사의 안내로 본인의 동의를 표시하는 방법</li>
          <li>③ 웹사이트(모바일 포함)에 가입 또는 온라인 상에 기록된 ‘개인정보의 수집 및 이용동의’에 기재된 ‘개인정보 처리방침’을 숙지하신 후 「동의」버튼 또는 「동의하지
            않음」버튼을 클릭하는 방법
          </li>
          <li>④ 우편 또는 팩스를 통해 고객님의 동의 내용에 서명 또는 날인 후 제출하는 방법</li>
        </ul>
        <h2>2. 개인정보 수집/이용 목적 · 항목 · 보유기간</h2>
        <p>{{ GET_STORE_INFORMATION.corporation || GET_STORE_INFORMATION.store_name }}이 고객의 개인정보를 수집/이용하는 목적은 다음과 같습니다.</p>
        <table>
          <colgroup>
            <col style="width:15%">
            <col style="width:35%">
            <col style="width:auto">
            <col style="width:20%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col">대상</th>
              <th scope="col">목적</th>
              <th scope="col">항목</th>
              <th scope="col">보유기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>일반회원(필수)</th>
              <td>
                <div>
                  <ul>
                    <li>① 본인 여부 및 가입의사 확인</li>
                    <li>② 부정이용 방지</li>
                    <li>③ 통합서비스 제공(구매, 결제 및 배송 서비스, ID/PW 찾기, 쿠폰 기간 연장 등), 연령확인</li>
                    <li>④ 계약 이행 및 약관 변경 등의 고지를 위한 연락, 본인 의사 확인 및 민원 등의 고객 불만 처리</li>
                  </ul>
                </div>
              </td>
              <td>이름, 이메일주소, 연계정보(CI), 생년월일, 성별, 전화번호, {{ GET_STORE_INFORMATION.store_name }} 구매내역, 기본 배송주소</td>
              <td>5년(전자상거래 등에서의 소비자 보호에 관한 법률)</td>
            </tr>
            <tr>
              <th>일반회원(선택)</th>
              <td>
                <div>
                  <ul>
                    <li>① 통합서비스 제공 등</li>
                    <li>② 맞춤서비스 제공 및 마케팅, 광고에의 활용</li>
                  </ul>
                </div>
              </td>
              <td>계좌번호, 주소, 이메일 주소, 전화번호</td>
              <td>5년(전자상거래 등에서의 소비자 보호에 관한 법률)</td>
            </tr>
            <tr>
              <th>기업회원(필수)</th>
              <td>
                <div>
                  <ul>
                    <li>① 사업자 서비스 제공 및 서비스 제공에 따른 요금정산
                      <br> (거래명세서 발행, 금융거래 본인인증 및 금융서비스 제공 등)
                    </li>
                    <li>② 고객관리(회원제 서비스 이용에 따른 기업정보확인, 부정 이용 방지와 비인가 사용방지, 가입의사 확인, 불만처리 등 민원처리, 고지사항
                      전달, 마케팅에의 활용 등)
                    </li>
                  </ul>
                </div>
              </td>
              <td>법인명, 사업자등록번호, 담당자명, 대표번호, 전화 번호, 이메일 주소</td>
              <td>5년(전자상거래 등에서의 소비자 보호에 관한 법률)</td>
            </tr>
            <tr>
              <th>기업회원(선택)</th>
              <td>
                <div>
                  <ul>
                    <li>① 통합서비스 제공 등</li>
                    <li>② 맞춤서비스 제공 및 마케팅, 광고에의 활용</li>
                  </ul>
                </div>
              </td>
              <td>계좌번호, 이메일 주소, 전화번호</td>
              <td>5년
                <br>(전자상거래 등에서의 소비자 보호에 관한 법률)
              </td>
            </tr>
          </tbody>
        </table>
        <p>∙ 회사는 개인정보를 수집함에 있어, 서비스 제공에 필요한 최소한의 개인정보를 ‘필수 동의 항목’으로, 그 외 개인정보는 ‘선택 동의 항목’으로 구분하여 이에 대해 개별적으로
          동의할 수 있는 절차를 마련합니다. 회사는 이용자가 필요한 최소한의 개인정보 이외의 개인정보를 제공하지 아니한다는 이유로 그 서비스의 제공을 거부하지 않습니다.</p>
        <p>∙ 회사는 회원 가입을 만 14세 이상인 경우에 가능하도록 하며 개인정보의 수집 이용에 법정대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지
          않습니다.</p>
        <h2>3. 개인정보의 보유 및 이용기간</h2>
        <ul>
          <li>① 회사는 고객님의 개인정보를 서비스 가입기간 또는 분쟁처리 기간 동안 이용하고, 요금정산, 요금 과오납 등 분쟁 대비를 위해 해지 후 6개월까지 보유 합니다.
          </li>
          <li>단, 요금의 과납 또는 미납이 있을 경우와 분쟁이 있을 경우 해결 시까지 보유 합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이
            관계법령에서 정한 일정한 기간 동안 고객정보를 보관합니다.
            <ul class="in-one">
              <li>- 보존 항목: 이름, 청구지 주소, 휴대전화번호, {{ GET_STORE_INFORMATION.store_name }} 구매내역(청구액, 수납액, 수납일시, 요금납부 방법)</li>
              <li>- 보존 근거: 국세기본법 제85조의3</li>
              <li>- 보존 기간: 5년</li>
            </ul>
          </li>
          <li>② 기타
            <ul class="in-one">
              <li>- 표시/광고에 관한 기록: 6개월(전자상거래 등에서의 소비자 보호에 관한 법률)</li>
              <li>- 계약 또는 청약철회 등에 관한 기록: 5년(전자상거래 등에서의 소비자 보호에 관한 법률)- 대금결제 및 재화 등의 공급에 관한 기록:
                5년(전자상거래 등에서의 소비자 보호에 관한 법률)
              </li>
              <li>- 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래 등에서의 소비자 보호에 관한 법률)</li>
              <li>- 신용정보의 수집/처리 및 이용 등에 관한 기록: 3년(신용정보의 이용 및 보호에 관한 법률)</li>
            </ul>
          </li>
        </ul>
        <h2>4. 개인정보의 제3자 제공</h2>
        <ul>
          <li>① 회사는 고객님의 개인정보를 가입신청서 및 개인정보 처리방침에 고지한 범위 내에서 사용합니다.</li>
          <li>② 회사는 개인정보 수집 시 고객이 명시적으로 동의한 범위를 초과하여 제3자에게 개인정보를 제공하지 않습니다. 다만, 다음의 경우에는 예외로 할 수 있습니다.
            <ul class="in-one">
              <li>- 예외사유
                <ul class="in-two">
                  <li>∙ 고객님이 사전에 동의하였거나 서비스 제공 관련 계약 이행을 위하여 필요한 개인정보로 동의를 받기가 경제적, 기술적으로 현저히 곤란한
                    경우
                  </li>
                  <li>∙ 서비스 제공에 따른 요금 정산을 위해 필요한 경우</li>
                  <li>∙ 통신비밀보호법, 국세기본법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용
                    및 보호에 관한 법률, 전기통신기본법, 전기통신사업법, 지방세법, 소비자기본법, 한국은행법, 형사소송법 등 다른 법률에 특별한 규정이
                    있는 경우
                  </li>
                  <li>(단, 이 경우에 행정, 수사 등의 목적으로 개인정보 제공을 요청한 경우라도 반드시 법률에서 정한 절차에 따라 영장 및 서면을 확인한 후
                    적법절차에 따라 제공)
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>③ 제공 정보의 보유 및 이용 기간
            <ul class="in-one">
              <li>- 보유기간: 이용기간 종료 후 파기(단, 법령에 특별한 규정이 있을 경우 법령에 따라 보관)</li>
              <li>- 이용기간: 정보 제공일(해당 서비스 가입일)부터 서비스 해지 또는 제공계약 종료일 중 먼저 도래하는 시점</li>
            </ul>
          </li>
        </ul>
        <h2>5. 개인정보의 파기절차 및 방법</h2>
        <p>회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기하며 그 절차 및 방법은 다음과 같습니다.</p>
        <ul>
          <li>- 파기절차: 고객님이 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 잠금장치) 내부 방침 및 관련 법령에 의한 정보보호 사유에 따라
            일정 기간 저장된 후 파기됩니다. 또한, 별도 DB로 옮겨진 개인정보는 법령 등에 의한 보유 이외 다른 목적으로 이용되지 않습니다.
          </li>
          <li>- 파기방법: 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하고, 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각하는 등의
            방법으로 파기합니다.
          </li>
        </ul>
        <h2>6. 업무위탁에 따른 개인정보의 처리</h2>
        <p>회사는 기본적인 서비스의 제공, 고객 편의 제공 등 원활한 업무 수행을 위하여 다음과 같이 개인정보 처리업무를 외부 전문업체(또는 회사의 다른 서비스)에 위탁하여 운영하고
          있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다. 또한 위탁 처리하는 정보는 원활한 서비스를 제공하기 위하여
          필요한 최소한의 정보에 국한됩니다. 구체적인 회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 다음과 같습니다.</p>
        <table>
          <colgroup>
            <col style="width:35%">
            <col style="width:auto">
          </colgroup>
          <thead>
            <tr>
              <th scope="col">위탁업체</th>
              <th scope="col">위탁업무의 목적 및 범위</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>(주) 피에이치피스쿨닷컴</td>
              <td>문자 발송 서비스</td>
            </tr>
            <tr>
              <td>NICE 평가정보</td>
              <td>휴대폰인증, 아이핀(I-PIN) 중복가입확인정보, 연계정보 확인, 본인인증, 계좌인증</td>
            </tr>
          </tbody>
        </table>
        <h2>7. 이용자 및 법정 대리인의 권리와 그 행사방법</h2>
        <ul>
          <li>① 고객님은 언제든지 등록되어 있는 고객님의 개인정보를 조회하거나 수정할 수 있으며 가입 해지를 요청할 수도 있습니다.</li>
          <li>② 고객님의 개인정보 조회, 수정을 위해서는 ‘개인정보 변경’(또는 ‘고객정보 수정’ 등)을, 가입 해지(동의 철회)를 위해서는 ‘해지 신청서’를 작성하여 회사에
            제출하거나, 웹사이트 고객님의 경우 ‘가입 탈퇴’를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴할 수 있습니다.
          </li>
          <li>③ 이를 위해, {{ GET_STORE_INFORMATION.store_name }} 고객센터({{ GET_STORE_INFORMATION.phone_no }})에 연락하시면 지체 없이 처리하겠습니다.</li>
          <li>④ 고객님의 개인정보 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한, 잘못된 개인정보를 제3자에게
            이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
          </li>
          <li>⑤ 회사는 고객님의 요청에 의해 해지 또는 삭제된 개인정보는 「회사가 수집하는 개인정보의 보유 및 이용기간」에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는
            이용할 수 없도록 처리하고 있습니다.
          </li>
        </ul>
        <h2>8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</h2>
        <ul>
          <li>① 회사는 고객님의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운영합니다. 쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 고객님의 브라우저에
            보내는 아주 작은 텍스트 파일로써 고객님의 컴퓨터 하드디스크에 저장됩니다.
            <ul class="in-one">
              <li> - 운영목적
                <ul class="in-two">
                  <li>∙ 개인의 관심 분야에 따라 차별화된 정보 제공</li>
                  <li>∙ 회원과 비회원의 접속 빈도 또는 머문 시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 타겟 마케팅에 활용</li>
                  <li>∙ 쇼핑한 품목들에 대한 정보와 관심 있게 둘러본 품목들에 대한 자취를 추적하여 다음 번 쇼핑 때 개인 맞춤 서비스를 제공 유료서비스
                    이용 시 이용기간 안내
                  </li>
                  <li>∙ 고객님들의 습관을 분석하여 서비스 개편 등의 척도로 활용</li>
                  <li>∙ 게시판글 등록</li>
                  <li>∙ 회사의 패밀리사이트간 고객정보 공유</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h2>9. 개인정보에 관한 상담 서비스</h2>
        <ul>
          <li>① 회사는 고객님의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 회사 관련 부서 및 개인정보 관리 책임자를 지정하고 있습니다.</li>
          <table>
            <colgroup>
              <col style="width:15%">
              <col style="width:35%">
              <col style="width:auto">
              <col style="width:20%">
            </colgroup>
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">책임자</th>
                <th scope="col">전화번호</th>
                <th scope="col">고객센터</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>개인정보관리</td>
                <td>{{ GET_STORE_INFORMATION.ceo_name }}</td>
                <td>{{ GET_STORE_INFORMATION.phone_no }}</td>
                <td><a href="javascript:parent.customerInquiry();">고객센터 문의하기</a></td>
              </tr>
              <tr>
                <td>고객서비스</td>
                <td>{{ GET_STORE_INFORMATION.ceo_name }}</td>
                <td>{{ GET_STORE_INFORMATION.phone_no }}</td>
                <td><a href="javascript:parent.customerInquiry();">고객센터 문의하기</a></td>
              </tr>
            </tbody>
          </table>
          <li>② 고객님은 회사의 서비스를 이용하며 발생하는 개인정보보호 관련 불만을 개인정보관리 책임자 또는 고객서비스 담당부서로 신고할 수 있고, 회사는 고객님의 신고사항에
            대해 신속하고 충분한 답변을 드릴 것입니다.
          </li>
          <li>③ 기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
            <ul class="in-one">
              <li>- 개인분쟁 조정위원회 (www.kopico.go.kr/1833-6972)</li>
              <li>- 대검찰청 인터넷범죄수사센터 (cybercid@spo.go.kr / 02-3480-3571)</li>
              <li>- 경찰청 사이버테러대응센터 (www.netan.go.kr/1566-0112)</li>
              <li>- 개인정보 침해신고센터(http://privacy.kisa.or.kr / 국번없이 118)</li>
            </ul>
          </li>
        </ul>
        <h2>10. 기타 개인정보 처리에 관한 방침</h2>
        <ul>
          <li>① 링크사이트
            <ul class="in-one">
              <li>- 회사는 고객님께 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대한 아무런 통제권이
                없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다.
              </li>
              <li>- 회사의 홈페이지에 포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보 처리방침은 회사와
                무관합니다.
              </li>
            </ul>
          </li>
          <li>② 게시물 등
            <ul class="in-one">
              <li>- 회사는 고객님의 게시물을 소중하게 생각하며 변조, 훼손, 삭제되지 않도록 최선을 다하여 보호합니다. 그러나 다음의 경우는 그렇지 아니합니다.
                <ul class="in-two">
                  <li>ㆍ 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 글</li>
                  <li>ㆍ 동의 없는 타인의 신상공개, 회사의 저작권, 제 3자의 저작권 등 권리를 침해하는 내용, 스팸성 게시물</li>
                  <li>ㆍ 기타 게시판 주제와 다른 내용의 게시물</li>
                </ul>
              </li>
              <li>- 회사는 바람직한 게시판 문화를 활성화하기 위하여 동의 없는 타인의 신상 공개시 특정부분을 삭제하거나 기호 등으로 수정하여 게시할 수 있습니다.
              </li>
              <li>- 회사는 다른 주제의 게시판으로 이동 가능한 내용일 경우 해당 게시물에 이동 경로를 밝혀 오해가 없도록 하고 있습니다.</li>
              <li>- 게시물에 관련된 제반 권리와 책임은 작성자 개인에게 있습니다. 또한, 게시물을 통해 자발적으로 공개된 정보는 보호받기 어려우므로 정보공개 전에
                심사숙고 하시기 바랍니다.
              </li>
              <li>- 가입 해지 시 고객님께서 작성하신 게시물은 자동으로 파기되므로 보관을 원하시는 게시물은 미리 고객님의 컴퓨터에 저장하셔야 합니다.</li>
              <li>- 일부 서비스에 의해서 발생되는 정보는 가입 해지의 경우 삭제됩니다.</li>
              <li>- 그 외의 경우 명시적 또는 개별적인 경고 후 삭제 조치할 수 있습니다.</li>
            </ul>
          </li>
          <li>③ 패밀리 사이트 가입에 대한 안내
            <ul class="in-one">
              <li>- 회사가 제공하는 {{ GET_STORE_INFORMATION.store_name }} 사이트에서
                가입 동의절차를 거치면 동일한 ID로 해당 패밀리사이트의 서비스를 이용할 수 있습니다.
              </li>
              <li>- 회사는 패밀리 사이트에서 고객님의 로그인, 로그아웃, 가입과 같은 고객정보 관리 및 로그 기록을 공유합니다.</li>
              <li>- 회사는 패밀리 사이트에 가입 또는 탈퇴하실 때 일괄 가입동의 절차를 거치고 있습니다.</li>
              <li>- 회사의 SSO(Single Sign On)를 수행하는 로그인시 SSL적용, DB저장 시 주요필드 암호화, 쿠키 암호화 등과 같은 보안조치를 취하고
                있습니다.
              </li>
              <li>- 개별 패밀리 사이트에서는 고객님의 아이디와 자체 서비스를 위한 정보만을 보유하며 패밀리 사이트 전체 기본 정보 등에 대해서는 회사에서 통제 및
                관리를 하게 됩니다. 또한 패밀리 서비스 이용 중 불건전 행위의 엄중한 처벌을 위한 회원경고, 정지이력 등을 공유할 수 있습니다.
              </li>
            </ul>
          </li>
        </ul>
        <h2>11. 광고성 정보 전송</h2>
        <ul>
          <li>① 회사는 고객님의 명시적인 수신거부의사에 반하여 영리목적의 광고성 정보를 전송하지 않습니다. 회사는 고객님이 뉴스레터 등 전자우편 전송에 대한 동의를 한 경우
            전자우편의 제목란 및 본문란에 다음과 같이 고객님이 쉽게 알아 볼 수 있도록 조치합니다.
            <ul class="in-one">
              <li>- 전자우편의 제목란: (동의)라는 문구를 제목란에 빈칸 없이 표시하고 전자우편 본문란의주요 내용을 기재합니다.</li>
              <li>- 전자우편의 본문란: 고객님이 수신거부의 의사표시를 할 수 있는 용이한 방법, 전송자의 명칭 및 연락처(전화번호 또는 전자우편 주소)</li>
            </ul>
          </li>
          <li>② 회사는 상품정보 안내 등 온라인 마케팅을 위해 광고성 정보를 전자우편 등으로 전송하는 경우 전자우편의 제목란 및 본문란에 다음 사항과 같이 고객님이 쉽게 알아 볼
            수 있도록 조치합니다.
            <ul class="in-one">
              <li>- 전자우편의 제목란: (광고), (성인광고)라는 문구를 제목란에 빈칸 없이 표시하고 전자우편 본문란의 주요 내용을 기재합니다.</li>
              <li>- 전자우편의 본문란: 고객님이 수신거부의 의사표시를 할 수 있는 용이한 방법, 전자우편 주소를 수집한 경로와 전송자의 명칭 및 연락처(전화번호 또는
                전자우편 주소), 팩스ㆍ휴대폰 문자전송 등 전자우편 이외의 문자전송을 통해 영리목적의 광고성 정보를 전송하는 경우에는 전송내용 처음에 (광고),
                (성인광고) 문구를 표기하고 전송 내용 중에 전송자의 연락처를 명기하도록 조치합니다.
              </li>
            </ul>
          </li>
        </ul>
        <h2>12. 개인정보의 기술적, 관리적 보호 대책</h2>
        <ul>
          <li>① 회사는 고객님의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 위조, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을
            강구하고 있습니다.
            <ul class="in-one">
              <li>- 고객님의 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송 데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의
                보안기능을 통해 보호되고 있습니다.
              </li>
              <li>- 회사는 백신프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트 되며 갑작스런
                바이러스가 출현할 경우 백신이 나오는 즉시 이를 적용함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
              </li>
              <li>- 회사는 암호화 응용프로그램을 설치하여 네트워크 상에 개인정보 및 개인인증 정보를 안전하게 전송할 수 있는 보안장치를 채택하고 있습니다.</li>
              <li>- 회사는 해킹 등 외부침입에 대비하여 침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다.</li>
            </ul>
          </li>
          <li>② 회사는 고객님의 개인정보에 대한 접근권한을 최소로 제한하는 등의 관리적 대책을 시행하고 있습니다.
            <ul class="in-one">
              <li>- 접근권한 최소한의 인원에 해당하는 자는 다음과 같습니다.</li>
              <li>- 이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자</li>
              <li>- 업무 담당자 및 상담업무를 수행하는 자</li>
              <li>- 기타 업무상 개인정보의 취급이 불가피한 자</li>
              <li>- 개인정보를 취급하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호 의무 등에 관해 정기적인 사내 교육 및 외부 위탁교육을 실시하고
                있습니다.
              </li>
              <li>- 회사는 전 직원의 년1회 정보 보호 서약서 작성을 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보 처리방침에 대한 이행사항 및 직원의
                준수여부를 감사하기 위한 내부절차를 마련하고 있습니다.
              </li>
              <li>- 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확히 하고
                있습니다.
              </li>
              <li>- 회사는 전산실 및 자료보관실 등을 통제구역으로 설정하여 출입을 통제합니다.</li>
              <li>- 회사는 고객님 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않으므로 고객님은 ID와 비밀번호를 철저히 관리하고
                책임을 져야 합니다.
              </li>
            </ul>
          </li>
          <li>③ ID 및 비밀번호관리
            <ul class="in-one">
              <li>- 회사는 고객님의 ID, 비밀번호의 도용을 방지하기 위하여 회사의 웹사이트에 연속으로 1년 이상 접속한 이력이 없을 경우 '휴면ID'로 정하여 「고객
                본인확인 절차를 수행한 후 비밀번호를 신규 등록하도록」 로그인을 제한할 수 있습니다.
              </li>
              <li>- 회사는 3개월 단위로 비밀번호를 재설정 하도록 조치함에 따라, 고객님은 3개월 단위로 비밀번호를 강제로 재설정할 수 있습니다.</li>
            </ul>
          </li>
        </ul>
        <h2>13. 고지의 의무</h2>
        <p>현 개인정보 처리방침은 2018년 4월 1일에 제정되었으며 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가ㆍ삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 홈페이지의
          ''공지''란을 통해 고지할 것입니다. 부칙 본 개인정보처리방침은 2020년 4월 5일부터 시행합니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import vuex, { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            'GET_STORE_INFORMATION': 'im/GET_STORE_INFORMATION',
        })
    }
}

</script>

<style lang="scss" scoped>
</style>