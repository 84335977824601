<template>
  <div id="historySearch">
    <div class="search-title">{{ $t('ORDER_HISTORY.GUIDE_MSG_1') }}</div>
    <input class="input-phone-number" type="text" :placeholder="$t('ORDER_HISTORY.PLACEHOLDER_MSG_1')" v-model="phoneNumber">
    <div class="search-explain">{{ $t('ORDER_HISTORY.GUIDE_MSG_2') }}</div>
    <div class="button-box">
      <button class="btn-next-step" @click="goHistoryList()">{{ $t('COMMON.CONFIRM') }}</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      phoneNumber: ''
    }
  },
  methods: {
    goHistoryList() {
      this.$router.push(`${this.$store.state.gRoot}/history/list`);
    },
    goHistoryList() {
      let self = this;

      let regExp = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
      let phoneNumValid = regExp.test(this.phoneNumber);

      if(phoneNumValid === false) {
        this.$store.commit('showToastMsg', this.$t('ORDER_HISTORY.POPUP_MSG_1'));
        return;
      }

      let storeID = this.$store.state.storeID;

      axios({
        method: 'post',
        url: `${process.env.VUE_APP_API_URL}/order/list`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          store_id: storeID,
          tel: this.phoneNumber
        }
      })
        .then(function(res) {
          let historyList = res.data.data;

          if(historyList.length === 0) {
            self.$store.commit('showToastMsg', this.$t('ORDER_HISTORY.POPUP_MSG_2'));
            return;
          }

          self.$store.commit('setStates', [
            { target: 'history', key: 'phoneNumber', value: self.phoneNumber },
            { target: 'history', key: 'list', value: historyList}
          ]);

          self.$router.push(`${self.$store.state.gRoot}/history/list`);          
        })
        .catch(function(err) {
          console.log(err);
          let res = err.response;
        })      
    }
  }
}
</script>

<style lang="scss">
@mixin placeholders($color: #000) {
  &::placeholder { color: $color }
  &::-webkit-input-placeholder { color: $color }
  &::-moz-placeholder { color: $color }
  &:-ms-input-placeholder { color: $color }
  &:-moz-placeholder { color: $color }
}

#historySearch {
  .search-title {
    height: 55px;

    font-size: 15pt;

    color: #424242;

    text-align: center;
    line-height: 55px;
  }

  .input-phone-number {
    display: inline-block;

    width: 100%;
    height: 60px;
    padding: 0 20px;

    border-bottom: 1px solid #ebebeb;
    font-size: 14pt;

    background-color: #fff;
    color: #424242;

    @include placeholders(#b3b3b3);
  }

  .search-explain {
    margin-top: 10px;

    height: 18px;

    font-size: 11pt;

    color: #a8a8a8;

    text-align: center;
    line-height: 18px;
  }

  .button-box {
    @include desktop();
    
    position: fixed;

    right: 0;
    bottom: 0;
    left: 0;

    .btn-next-step {
      width: 100%;
      height: 50px;

      font-size: 17.333333px;

      background-color: #ff8b45;
      color: #fff;

      &:active {
        background-color: #d7773c;
      }
    }
  }
}
</style>
