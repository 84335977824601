import {
  fetchStoreInformation,
  fetchCategoryList,
  fetchProductList,
  fetchProductDetail,
  setProduct,
  payOrder
} from '../../api/im'

const state = {
  storeID: '',
  storeInformation: {},
  categoryList: [],
  categoryID: '',
  productList: [],
  product: {},
  productDetail: {},
  cart: [],
  order: []
}

const getters = {
  GET_STORE_ID(state) {
    return state.storeID;
  },
  GET_STORE_INFORMATION(state) {
    return state.storeInformation
  },
  GET_CATEGORY_LIST(state) {
    return state.categoryList;
  },
  GET_CATEOGRY_ID(state) {
    return state.categoryID;
  },
  GET_PRODUCT_LIST(state) {
    return state.productList;
  },
  GET_PRODUCT(state) {
    return state.product;
  },
  GET_PRODUCT_DETAIL(state) {
    return state.productDetail;
  },
  GET_CART(state) {
    return state.cart
  },
  GET_CART_TOTAL(state) {
    let total = {
      originPrice: 0,
      discountPrice: 0,
      count: 0,
      finalPaymentAmount: 0
    }

    state.cart.map(item => {
      let { totalCountPrice, totalDiscountPrice, count } = item;
      total.originPrice = total.originPrice + totalCountPrice + totalDiscountPrice;
      total.discountPrice += totalDiscountPrice;
      total.count += count;
      total.finalPaymentAmount += totalCountPrice;
    })

    return total;
  },
  GET_ORDER(state) {
    return state.order;
  },
  GET_ORDER_TOTAL(state) {
    let total = {
      originPrice: 0,
      discountPrice: 0,
      count: 0,
      finalPaymentAmount: 0
    }

    state.order.map(item => {
      let { totalCountPrice, totalDiscountPrice, count } = item;
      total.originPrice = total.originPrice + totalCountPrice + totalDiscountPrice;
      total.discountPrice += totalDiscountPrice;
      total.count += count;
      total.finalPaymentAmount += totalCountPrice;
    })

    return total;
  }
}

const mutations = {
  INIT_VUEX_STORE(state) {
    state.category = '';
    state.categoryID = '';
  },
  SET_STORE_ID(state, data) {
    state.storeID = data
  },
  SET_STORE_INFORMATION(state, data) {
    state.storeInformation = data;
  },
  SET_CATEGORY_LIST(state, data) {
    state.categoryList = data;
  },
  SET_CATEGORY_ID(state, data) {
    state.categoryID = data;
  },
  SET_PRODUCT_LIST(state, data) {
    state.productList = data;
  },
  SET_PRODUCT(state, data) {
    state.product = data;
  },
  SET_PRODUCT_DETAIL(state, data) {
    state.productDetail = data;
  },
  SET_CART(state, data) {
    state.cart = data;
  },
  SET_ORDER(state, data) {
    state.order = data;
  }
}

const actions = {
  INIT_VUEX_STORE(context) {
    context.commit('INIT_VUEX_STORE')
  },
  async FETCH_STORE_INFORMATION(context, data) {
    context.commit('SET_STORE_ID', data)

    let res = await fetchStoreInformation(data);

    context.commit('SET_STORE_INFORMATION', res.data);

    return res;
  },
  async FETCH_CATEGORY_LIST(context) {
    let storeID = context.getters.GET_STORE_ID;
    let categoryID = context.getters.GET_CATEOGRY_ID;
    let productList = context.getters.GET_PRODUCT_LIST;

    console.log(storeID)

    let res = await fetchCategoryList(storeID);
    let categoryList = res.data.data;

    context.commit('SET_CATEGORY_LIST', categoryList);

    let categoryFind = categoryList.find(category => category.category_id === categoryID);
    if(categoryFind === undefined || categoryID === '' || productList.length === 0) {
      context.dispatch('FETCH_PRODUCT_LIST', categoryList[0].category_id);
    }

    return res;
  },
  async FETCH_PRODUCT_LIST(context, data) {
    let storeID = context.getters.GET_STORE_ID;

    console.log(storeID)

    context.commit('SET_CATEGORY_ID', data);

    let res = await fetchProductList({ storeID, categoryID: data });

    console.log(res)

    context.commit('SET_PRODUCT_LIST', res.data.data)

    return res;
  },
  SET_PRODUCT_ITEM(context, data) {
    context.commit('SET_PRODUCT', data)
  },
  async FETCH_PRODUCT_DETAIL(context, data) {
    let storeID = context.getters.GET_STORE_ID;
    let product = context.getters.GET_PRODUCT;
   
    let res = await fetchProductDetail({ storeID, productID: product.menu_id })

    let productDetail = res.data;
    productDetail.count = 1;

    productDetail.option.map((option, optionIdx) => {
      option.content.map((optionItem, optionItemIdx) => {
        // console.log(option)
        // console.log(optionItem, optionItemIdx);

        productDetail.option[optionIdx].content[optionItemIdx] = {
          ...optionItem,
          checked: (option.option_type === "OT001" && optionItemIdx === 0)
        }
      })        
    })

    productDetail = setProduct(productDetail)
    
    context.commit('SET_PRODUCT_DETAIL', productDetail)
    return res;
  },
  TOGGLE_PRODUCT_OPTION(context, data) {
    let productDetail = context.getters.GET_PRODUCT_DETAIL;
    let { option, optionIndex, content, contentIndex } = data

    if(option.option_type === 'OT001') {
      for(const [index, value] of productDetail.option[optionIndex].content.entries()) {
        productDetail.option[optionIndex].content[index].checked = false;        
      }
      productDetail.option[optionIndex].content[contentIndex].checked = true;

      productDetail = setProduct(productDetail)

      context.commit('SET_PRODUCT_DETAIL', productDetail)
    }

    if(option.option_type === 'OT002') {
      productDetail.option[optionIndex].content[contentIndex].checked = !content.checked;

      productDetail = setProduct(productDetail)

      context.commit('SET_PRODUCT_DETAIL', productDetail)
    }
  },
  CHANGE_PRODUCT_QUANTITY(context, action) {
    let productDetail = context.getters.GET_PRODUCT_DETAIL;

    switch(action.type) {
      case 'DECREMENT':
        if(productDetail.count === 1) {
          return;
        }
        productDetail.count--;
        productDetail = setProduct(productDetail)
        context.commit('SET_PRODUCT_DETAIL', productDetail);
        return;
      case 'INCREMENT':
        productDetail.count++;
        productDetail = setProduct(productDetail)
        context.commit('SET_PRODUCT_DETAIL', productDetail);
        return;
      default:
        return;
    }
  },
  ADD_CART(context) {
    let productDetail = context.getters.GET_PRODUCT_DETAIL;
    let cart = context.getters.GET_CART;

    let res = setProduct(productDetail)

    cart.push(productDetail);

    context.commit('SET_CART', cart);
  },
  TOGGLE_CART_ITEM(context, data) {
    let cart = context.getters.GET_CART;

    let newCart = cart.map((item, index) => {
      if(data.index === index) {
        item.checked = data.value
      }
      return item;
    })

    context.commit('SET_CART', newCart);
  },
  REMOVE_CART_ITEM(context, data) {
    let cart = context.getters.GET_CART;
    let index = data.index.reverse();

    for(let idx of index) {
      cart.splice(idx, 1)
    }

    context.commit('SET_CART', cart);
  },
  CHNAGE_CART_QUANTITY(context, data) {
    let cart = context.getters.GET_CART;

    let product = cart[data.index];

    if(data.type === 'DECREMENT') {
      if(product.count === 1) {
        return;
      }
      product.count--;
    } else if(data.type === 'INCREMENT') {
      product.count++;
    }

    product = setProduct(product)
    
    cart[data.index] = product;

    context.commit('SET_CART', cart);
  },
  CONTAIN_ORDER(context, data) {
    let orders = [];
    let processedOrder = [];

    if(data.type === 'cart') {
      orders = context.getters.GET_CART;    
    } else {
      orders = [context.getters.GET_PRODUCT_DETAIL]
    }
    
    for(let order of orders) {
      if(order.checked) {
        let isOption = [];

        for(let option of order.option) {
          for(let content of option.content) {
            if(content.checked) {
              isOption.push({ ...option, ...{ content: content.name, price: content.price, count: 1 } })
            }
          }
        }

        processedOrder.push({ ...order, option: isOption })
      }
    }

    context.commit('SET_ORDER', processedOrder);
  },
  async PAY_ORDER(context, data) {
    let storeID = context.getters.GET_STORE_ID;
    let store = context.getters.GET_STORE_INFORMATION;
    let order = context.getters.GET_ORDER;    

    data = {
      store_id: storeID,
      store_name: store.store_name,
      payment_type_code: data.paymentTypeCode,
      payment_type_extra: data.paymentTypeExtra,
      user_tel: data.urserTel,
      title: data.title,
      packing_yn: data.packingYN,
      order_fail_time: 60,
      request_t: data.requests,
      language: data.language,
      return_url: data.returnURL,
      order
    }

    let res = await payOrder(data);
    return res;
  },
  ADD_ORDER() {

  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}