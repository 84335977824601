<template>
  <div id="app" ref="rootApp">
    <Header></Header>
<!--    <Header v-if="selectHeader"></Header>-->
<!--    <NewHeader v-if="newHeader"></NewHeader>-->
    <div class="rotuer-view">
      <router-view/>
    </div>
    <Toast></Toast>
    <Screen></Screen>
  </div>
</template>

<script>
import Header from './components/TheHeader'
import NewHeader from './components/Header'
import Toast from './components/ToastMsg'
import Screen from './components/TheScreen'

export default {
  name: 'App',
  components: {
    Header,
    NewHeader,
    Toast,
    Screen
  },
  data() {
    return {
      selectHeader: true,
      newHeader: false
    }
  },
  watch: {
    '$route' (to) {
      this.renderHeader(to.name)
    }
  },
  created() {
  },
  mounted() {
    // this.renderHeader(this.$route.name)
  },
  methods: {
    renderHeader(name) {
      if(name === 'Home') {
        this.selectHeader = false;
        this.newHeader = true;
      } else {
        this.selectHeader = true;
        this.newHeader = false;
      }

      this.initApplication();
    },
    initApplication() {      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];

      let isMobile = toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem);
      });

      if(!isMobile) {
      // if(!(navigator.userAgent.indexOf('Mobi') > -1)) {
        this.selectHeader = false;
        this.newHeader = false;
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  .rotuer-view {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
}
</style>
