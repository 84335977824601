<template>
  <div id="DefaultLayout">
    <StationaryHeader/>
    <div id="routerView">
      <router-view/>
    </div>
    <StationaryFooter/>
  </div>
</template>

<script>
import StationaryHeader from '../components/stationary/Header'
import StationaryFooter from '../components/stationary/Footer'

export default {
  components: {
    StationaryHeader,
    StationaryFooter
  }

}
</script>

<style lang="scss">
#DefaultLayout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  #StationaryHeader {
  }

  #routerView {
    flex-grow: 1;
  }

  #Footer {
  }
}
</style>