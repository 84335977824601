<template>
  <div id="historyList">
    <div class="history-list-header">
      <div class="user-phone-number">{{ this.$store.state.history.phoneNumber }} {{ $t('ORDER_HISTORY_LIST.WHO_PAID_HISTORY_LIST') }}</div>
      <div class="list-guide-box">
        <div class="list-guide-row">{{ $t('ORDER_HISTORY_LIST.GUIDE_MSG_1') }}</div>
        <div class="list-guide-row">{{ $t('ORDER_HISTORY_LIST.GUIDE_MSG_2') }}</div>
      </div>
    </div>

    <div class="history-list-container">
      <div class="history-item" v-for="(history, index) in obHistoryList" :key="index">
        <div class="history-header">
          <div class="history-datetime">{{ history.wait_dt }}</div>
          <div class="history-detail-view" @click="onHistoryDetail(history)">{{ $t('ORDER_HISTORY_LIST.VIEW_ORDER_HISTORY') }}</div>
        </div>
        <div class="history-order-number">{{ history.order_no }}</div>
        <div class="history-name">{{ history.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import vuex from 'vuex'
import axios from 'axios';

export default {
  computed: {
    obHistoryList() {
      return this.$store.state.history.list;
    }
  },
  methods: {
    ...vuex.mapMutations({
      'setStates': 'setStates',
      'setDetail': 'setDetail'
    }),
    onHistoryDetail(history) {    
      let self = this;

      let storeID = this.$store.state.storeID;

      console.log(history)

      this.setDetail(history)

      console.log(this.$store.state.history.detail)
      console.log(this.$store.state.history.key)

      this.$router.push(`${self.$store.state.gRoot}/history/detail`);
      
      // axios({
      //   method: 'post',
      //   url: `${this.$store.state.menucApi}/order/detail`,
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   data: {
      //     store_id: storeID,
      //     order_id: history.order_id
      //   }
      // })
      //   .then(function(res) {
      //     let historyDetail = res.data;

      //     self.$store.commit('setStates', [
      //       { target: 'history', key: 'detail', value: history }
      //       // { target: 'history', key: 'detail', value: historyDetail }
      //     ])

      //     self.$store.commit('setStates', [
      //       { target: 'history', key: 'key', value: history }
      //     ])

      //     // self.$router.push(`${self.$store.state.gRoot}/history/detail`)
      //   })
      //   .catch(function(err) {
      //     console.log(err);
      //     let res = err.response;
      //   })      
    }
  },
  created() {
    console.log(this.obHistoryList)
  }

}
</script>

<style lang="scss">
#historyList {
  .history-list-header {
    .user-phone-number {
      height: 47px;
      padding: 0 10px;

      border: {
        top: 1px solid #ebebeb;
        bottom: 1px solid #ebebeb;
      }
      font-size: 15pt;

      background-color: #fff;
      color: #8a8a8a;

      text-align: center;
      line-height: 47px;
    }

    .list-guide-box {
      padding: 12px 14px;

      .list-guide-row {
        font-size: 11pt;

        color: #a8a8a8;

        text-align: center;
        line-height: 18px;
      }
    }
  }

  .history-list-container {
    .history-item {
      margin-bottom: 8px;
      padding: 10px 14px 27px;

      background-color: #fff;

      .history-header {
        height: 18px;

        font-size: 11pt;

        line-height: 18px;

        .history-datetime {
          float: left;

          color: #a8a8a8;
        }

        .history-detail-view {
          float: right;

          padding-right: 8px;

          background-image: url('../assets/images/ic_next.png');
          background-position: right center;
          background-size: 8px 18px;
          background-repeat: no-repeat;
          color: #ff8b45;
        }
      }

      .history-order-number {
        height: 18px;
        margin-top: 15px;

        font-size: 11pt;

        color: #808080;

        text-align: center;
        line-height: 18px;

      }

      .history-name {
        height: 26px;

        font-size: 14pt;
        font-weight: 700;

        color: #5c5c5c;

        text-align: center;
        line-height: 26px;
      }
    }
  }


}
</style>
