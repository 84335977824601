<template>
  <div id="spinner-background">
    <div id="spinner"></div>
    <div>{{ progress }}%</div>
  </div>
</template>

<script>
import { Spinner } from '../assets/javascripts/spin.js'

export default {
  data () {
    return {
      opts: {
        lines: 15, // The number of lines to draw
        length: 38, // The length of each line
        width: 17, // The line thickness
        radius: 45, // The radius of the inner circle
        scale: 0.8, // Scales overall size of the spinner
        corners: 1, // Corner roundness (0..1)
        color: '#ff8b45', // CSS color or array of colors
        fadeColor: 'transparent', // CSS color or array of colors
        speed: 2, // Rounds per second
        rotate: 0, // The rotation offset
        animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
        direction: 1, // 1: clockwise, -1: counterclockwise
        zIndex: 2e9, // The z-index (defaults to 2000000000)
        className: 'spinner', // The CSS class to assign to the spinner
        top: '50%', // Top position relative to parent
        left: '50%', // Left position relative to parent
        shadow: '0 0 1px transparent', // Box-shadow for the lines
        position: 'absolute' // Element positioning
      },
      progress: 0
    }
  },
  mounted () {
    let target = document.querySelector('#spinner')
    let spinner = new Spinner(this.opts).spin(target);

    let progressInterval = setInterval(() => {
      this.progress += 1;
      if(this.progress === 100) {
        clearInterval(progressInterval);
      }
    }, 3)
  }
}
</script>

<style lang="scss">
#spinner-background {
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: #f2f2f2;

  #spinner {
  }
}

@keyframes spinner-line-fade-more {
  0%, 100% {
    opacity: 0; /* minimum opacity */
  }
  1% {
    opacity: 1;
  }
}

@keyframes spinner-line-fade-quick {
  0%, 39%, 100% {
    opacity: 0.25; /* minimum opacity */
  }
  40% {
    opacity: 1;
  }
}

@keyframes spinner-line-fade-default {
  0%, 100% {
    opacity: 0.22; /* minimum opacity */
  }
  1% {
    opacity: 1;
  }
}

@keyframes spinner-line-shrink {
  0%, 25%, 100% {
    /* minimum scale and opacity */
    transform: scale(0.5);
    opacity: 0.25;
  }
  26% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>