<template>
  <div id="productDetail">
    <div class="product-digest-box">
      <span class="product-discount-percent" v-if="obProductDetail.dc_percent !== 0">{{ obProductDetail.dc_percent }}%</span>
      <div class="product-image-box" @click="showEnlargeImage()">
        <div class="product-image" :style="{ backgroundImage: 'url(' + obProductDetail.menu_img + ')' }"></div>
        <!-- <img class="product-image" :src="obProductDetail.menu_img" alt=""> -->
      </div>
      <div class="product-digest-text">
        <div class="product-name">{{ obProductDetail.menu_names | language }}</div>
        <div class="product-price">
          <span class="product-origin-price">{{ obProductDetail.price + obProductDetail.dc_price | commaNumber }} {{ $t('COMMON.NONEYARY_UNIT') }}</span>
          <span class="product-price">{{ obProductDetail.price | commaNumber }} {{ $t('COMMON.NONEYARY_UNIT') }}</span>
        </div>
      </div>
    </div>

    <div class="product-option">
      <div class="option-box" :class="[{ 'required-box': option.option_type===OptRequired, 'selected-box': option.option_type==='OT002' }]" v-for="(option, idxOpt) in productDetail.option" :key=idxOpt>
        <div class="option-title">
          <span class="title-name">{{ option.option_names | language }} </span>
          <span class="title-guide" v-if="option.select_max !== 999">
            <span v-if="option.select_min !== 0">(<span v-if="option.select_min !== option.select_max">{{option.select_min}}~</span>{{ option.select_max }}개 필수 선택)</span>
            <span v-if="option.select_min === 0">({{ option.select_max }}개 선택 가능)</span>
          </span>
        </div>
        <div class="option-item-list">
          <div class="option-item-box" @click="toggleOpt(option, idxOpt, idxContent)" v-for="(content, idxContent) in option.content" :key="idxContent">
            <!-- <input :type="option.option_type==='OT001'? 'radio' : 'checkbox'" :name="option.option_id" id="" class="option-input" :ref="`opt_${idxOpt}_${idxContent}`" v-model="content.selected" :value="true"> -->
            <input type="checkbox" :name="option.option_id" id="" class="option-input" :ref="`opt_${idxOpt}_${idxContent}`" v-model="content.selected">
            <div class="option-item">
              <div class="option-name">{{ content.name }}</div>
              <div class="float-right">
                <div class="option-price">{{ content.price | commaNumber }} {{ $t('COMMON.NONEYARY_UNIT') }}</div>
              </div>
            </div>
          </div>
        </div
        >
      </div>
      <div class="selected-box option-box">

      </div>
    </div>

    <div class="product-control-box">
      <div class="control-box-text">{{ $t('ORDER.QUANTITY') }}</div>
      <div class="control-switch-box">
        <button class="control-btn btn-minus" @click="subCount()" :disabled="count==1"></button>
        <span class="order-quantity">{{ count }}</span>
        <button class="control-btn btn-plus" @click="addCount()"></button>
      </div>
    </div>

    <div class="product-order-container">
      <div class="total-order-state">
        <span class="total-price-title">총 금액</span>
        <span class="total-price">{{ totalPrice | commaNumber }} {{ $t('COMMON.NONEYARY_UNIT') }}</span>
      </div>

      <div class="order-btn-box" v-if="obProductDetail.sold_out_yn === 'N'">
        <button class="order-btn btn-add-cart" @click="addCard()">{{ $t('PRODUCT_PAGE.SHOPPING_BASKET') }}</button>
        <button class="order-btn btn-immediately-order" @click="goOrderPayment()">{{ $t('PRODUCT_PAGE.ORDER_IMMEDIATELY') }}</button>
      </div>
      <div class="order-btn-box" v-if="obProductDetail.sold_out_yn === 'Y'">
        <button class="soldout-btn" disabled>품절</button>
      </div>
    </div>
    <div class="product-order-area"></div>

    <DetailImages></DetailImages>
  </div>
</template>

<script>
import vuex from 'vuex';

import DetailImages from '../components/DetailImages.vue'

import language from '../filters/language.js'

export default {
  components: {
    DetailImages
  },
  data() {
    return {
      OptRequired: 'OT001', // 반드시 요구되는 옵션 1개만 선택 가능 
      OptSelected: 'OT002',
      price: this.$store.state.productDetail.price,
      count: 1,
      productDetail: {}
    }
  },
  computed: {
    obProductDetail() {
      return this.$store.state.productDetail;
    },
    totalPrice() {
      this.calPrice();
      return this.price*this.count
    },
  },
  filters: {
    commaNumber(value) {
      // add
      let negative = false;
      if(value < 0) {
        negative = true;
        value *= -1;
      }
      
      value = '' + value;
      let floatingPoint = value.split('.');
      value = floatingPoint[0];

      return `${negative? '- ' : ''}${value.split('').reverse().reduce((acc, digit, i) => {
        if (i > 0 && i % 3 === 0) acc.push(',')
        return [...acc, digit]
      }, []).reverse().join('')}`;
    },
    language
  },
  methods: {
    ...vuex.mapActions({
      'SHOW_ENLARGE_DETAIL_IMGS': 'SHOW_ENLARGE_DETAIL_IMGS'
    }),
    setBaiscOpt() {
      
    },
    toggleOpt(option, idxOpt, idxContent) {
      let content = option.content;

      if (option.option_type === this.OptRequired) {
        for(let c=0; c<content.length; c++) {
          if(content[c].selected) {
            this.$refs[`opt_${idxOpt}_${c}`][0].click();
          }
          content[c].selected = false;      
        }
        this.$refs[`opt_${idxOpt}_${idxContent}`][0].click();
      } else if (option.option_type === this.OptSelected) {
        if(content[idxContent].selected) {
          this.$refs[`opt_${idxOpt}_${idxContent}`][0].click();
          this.calPrice()
          return;
        }

        let selectedCount=0;
        for(let c=0; c<content.length; c++) {
          if(content[c].selected) {
            selectedCount+=1;
          }
        }

        if(selectedCount == option.select_max) {
          window.alert(`최대 선택할수 있는 수량을 넘었습니다.`);
          return;
        } else {
          this.$refs[`opt_${idxOpt}_${idxContent}`][0].click();
        }
      } 

      this.calPrice();
    },
    calPrice() {
      this.price = this.$store.state.productDetail.price;

      let options = this.obProductDetail.option;
      
      for(let o=0; o<options.length; o++) {
        let contents = options[o].content;

        for(let c=0; c<contents.length; c++) {
          if(contents[c].selected) {
            this.price += contents[c].price;
          }
        }
      }
    },
    addCount() {
      this.count+=1;
    },
    subCount() {
      this.count-=1;
    },
    checkOption() {
      let option = this.obProductDetail.option;
      let processedOpt = [];

      for(let o=0; o<option.length; o++) {
        let content = option[o].content;
        let requiredCount = 0;
        let selectedCount = 0;

        if(option[o].option_type === this.OptRequired) {
          for(let c=0; c<content.length; c++) {
            if(content[c].selected === true) {
              processedOpt.push({
                ...option[o],
                content: content[c].name,
                price: content[c].price,
                count: 1
              })
              requiredCount++;
            }
          }

          if(requiredCount === 0) {
            return {
              result: false,
              msg: this.$i18n.locale === 'en' ? this.$t('PRODUCT_PAGE.ERROR_MSG_1')+language(option[o].option_names) : language(option[o].option_names)+this.$t('PRODUCT_PAGE.ERROR_MSG_1')
            };
          }
        } else if (option[o].option_type === this.OptSelected) {
          for(let c=0; c<content.length; c++) {
            if(content[c].selected === true) {
              processedOpt.push({
                ...option[o],
                content: content[c].name,
                price: content[c].price,
                count: 1
              })
              selectedCount++;
            }
          }

          // if(selectedCount < option[o].select_min) {
          //   return false;
          // } else if(selectedCount > option[o].select_max) {
          //   return false;
          // }
          if(selectedCount < option[o].select_min || selectedCount > option[o].select_max) {
            return {
              result: false,
              msg: `${language(option[o].option_names)}에서 ${option[o].select_min}~${option[o].select_max}개를 선택해주세요.`
            }
          }
        }        
      }

      return processedOpt;
    },
    orderProcessing() {
      let checkOption = this.checkOption();
      if(checkOption.result === false) {
        return checkOption;
      }

      let product = this.obProductDetail;

      let bucket = {
        ...product,
        count: this.count,
        option: this.checkOption()
      }

      if(bucket.tax_yn === "Y") {
        bucket.tax_rate = 10;
        bucket.service_rate = 0;
      } else {
        bucket.tax_rate = 0;
        bucket.service_rate = 0;
      }

      return bucket;
    },
    goOrderPayment() {
      let orderSpecification = this.orderProcessing();
      if(orderSpecification.result === false) {
        this.$store.commit('showToastMsg', orderSpecification.msg);
        return;
      }

      this.$store.commit('setState', {
        key: 'order',
        value: [orderSpecification]
      })

      this.$router.push(`${this.$store.state.gRoot}/order/payment`)
    },
    addCard() {
      let orderSpecification = this.orderProcessing();

      if(orderSpecification.result === false) {
        this.$store.commit('showToastMsg', orderSpecification.msg);
        return;
      }

      let cart = this.$store.state.cart;
      cart.push(orderSpecification);

      this.$store.commit('setState', {
        key: 'cart',
        value: cart
      })

      this.$store.commit('showToastMsg', this.$t('NOTIFICATION.TOAST_2'));
    },
    async showEnlargeImage () {
      await this.SHOW_ENLARGE_DETAIL_IMGS(this.obProductDetail);
    },
    initOptionsStatus() {
      console.log(this.productDetail)
      for(let option of this.productDetail.option) {
        if(option.option_type === "OT001") {
          for(let [index, content] of option.content.entries()) {
            console.log(index, content)
            if(index === 0) {
              content.selected = true;
            }
          }
        }
      }

      console.log(this.productDetail.option)
    }
  },
  created() {
    this.productDetail = this.$store.state.productDetail;
  },
  mounted() {
    this.initOptionsStatus();
  }
}
</script>

<style lang="scss">
#productDetail {
  .product-digest-box {
    overflow: hidden;
    position: relative;
    margin-top: 6px;
    border-bottom: 1px solid #f7f7f7;
    background-color: #fff;

    .product-discount-percent {
      display: inline-block;

      position: absolute;
      top: 0;
      left: 0;

      width: 36px;
      height: 21px;

      font-size: 12pt;

      background-color: #ff8b45;
      color: #fff;

      text-align: center;
      line-height: 21px;
    }

    .product-image-box {
      float: left;
      padding: 11px 13px;

      width: 122px;
      height: 122px;

      border-right: 1px solid #f7f7f7;

      .product-image {
        display: inline-block;

        width: 100%;
        height: 100%;

        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .product-digest-text {
      float: left;

      width: 100%;
      padding: 34px 20px 30px;
      margin-right: -123px;
      // padding-right: 143px;
      padding-right: 142px;

      .product-name {
        // display: -webkit-box;
        overflow: hidden;
        
        height: 24px;

        font-size: 15pt;

        color: #424242;

        line-height: 24px;
        text-overflow: ellipsis;
        // word-wrap: break-word;
        // white-space: normal;
        white-space: nowrap; 
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
      }

      .product-price {
        height: 30px;

        font-size: 0;

        line-height: 30px;

        .product-origin-price {
          font-size: 14pt;
          color: #b3b3b3;
          text-decoration: line-through;
        }

        .product-price {
          margin-left: 10px;

          font-size: 18pt;
          color: #424242;
        }
      }
    }
  }

  .product-control-box {
    overflow: hidden;

    width: 100%;
    height: 60px;
    padding: {
      right: 18px;
      left: 20px;
    }

    border-bottom: 1x solid #ebebeb;

    background-color: #fff;

    line-height: 60px;

    .control-box-text {
      float: left;

      height: 100%;

      font-size: 16pt;
      font-weight: 700;

      color: #424242;
    }

    .control-switch-box {
      overflow: hidden;
      float: right;

      height: 100%;

      font-size: 0;

      .control-btn {
        display: inline-block;
        
        width: 41.333333px;
        height: 41.333333px;

        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        vertical-align: middle;
      }
      .btn-minus {
        background-image: url('../assets/images/bt_minus_n.png');

        &:active {
          background-image: url('../assets/images/bt_minus_p.png');
        }

        &:disabled {
          background-image: url('../assets/images/bt_minus_d.png');
        }
      }
      .btn-plus {
        background-image: url('../assets/images/bt_plus_n.png');

        &:active {
          background-image: url('../assets/images/bt_plus_p.png');
        }
      }
      .order-quantity {
        display: inline-block;

        width: 66px;

        font-size: 17pt;
        font-weight: 700;

        color: #ff8b45;

        text-align: center;
        vertical-align: middle;
      }
    }
  }

  .product-option {
    padding-bottom: 20px;
    
    .option-box {
        border-bottom: 1px solid #ebebeb;

      .option-title {
        height: 42px;
        padding: 0 20px;

        line-height: 42px;

        .title-name {
          font-size: 13pt;

          color: #808080;
        }
        .title-guide {
          font-size: 13pt;

          color: #ff8b45;
        }
      }

      .option-item-list {
        border-bottom: #ebebeb;

        background-color: #fff;

        .option-item-box {          
          &:not(:last-child) {
            border-bottom: 1px solid #f7f7f7;
          }

          padding: {
            left: 20px;
            right: 12px;
          }

          .option-input {
            display: none;
          }

          .option-item {
            overflow: hidden;

            height: 60px;
            padding-right: 45px;
            
            background-size: 36px 44.666667px;
            background-position: right center;
            background-repeat: no-repeat; 

            transition: all .5s;

            .option-name {
              float: left;

              height: 60px;

              font-size: 14pt;

              color: #424242;

              line-height: 60px;
            }

            .option-price {
              float: left;

              font-size: 14pt;

              color: #b3b3b3;

              line-height: 60px;
            }
          }
        }
      }
    }

    .required-box {
      .option-item-list {
        .option-item-box {
          .option-item {
            background-image: url('../assets/images/bt_radio_n.png');            
          }

          .option-input:checked + .option-item {
            background-image: url('../assets/images/bt_radio_a.png');
          }
        }
      }
    }
    .selected-box {
      .option-item-list {
        .option-item-box {
          .option-item {
            background-image: url('../assets/images/bt_check_n.png');
          }

          .option-input:checked + .option-item {
            background-image: url('../assets/images/bt_check_a.png');
          }
        }
      }
    }
  }

  .product-order-container {
    @include desktop();
    
    position: fixed;

    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;

    border-top: 1px solid #ebebeb;
    font-size: 0;

    background-color: #fff;

    .total-order-state {
      height: 66px;

      text-align: center;
      line-height: 66px;

      .total-price-title {
        font-size: 18pt;

        color: #999;
      }
      .total-price {
        margin-left: 11px;
        font-size: 22pt;

        color: #424242;
      }
    }

    .order-btn-box {
      .order-btn {
        width: 50%;
        height: 50px;

        font-size: 17.333333px;

        background-color: #ff8b45;
        color: #fff;

        &:active {
          background-color: #d7773c;
        }

        &:not(:first-child) {
          border-left: 1px solid rgba(#000, .08)
        }
      }

      .soldout-btn {
        width: 100%;
        height: 50px;

        font-size: 17.333333px;
        
        &:disabled {
          background-color: #ccc;
          color: #fff;
        }
      }
    }
  }

  .product-order-area {
    height: 118px;
  }
}
</style>