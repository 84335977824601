import axios from 'axios'

const fetchStoreInformation = async (params) => {
  let data = {
    store_id: params
  }

  let res = await axios({
    method: 'POST',
    url: `${process.env.VUE_APP_API_URL}/store/info`,
    data
  })

  return res;
}

const fetchCategoryList = async (params) => {
  let data = {
    store_id: params
  }

  let res = await axios({
    method: 'POST',
    url: `${process.env.VUE_APP_API_URL}/category/list`,
    data
  })

  return res;
}

const fetchProductList = async (params) => {
  let data = {
    store_id: params.storeID,
    category_id: params.categoryID,
  }

  let res = await axios({
    method: 'POST',
    url: `${process.env.VUE_APP_API_URL}/menu/list`,
    data
  })

  return res;
}

const fetchProductDetail = async (params) => {
  let data = {
    store_id: params.storeID,
    menu_id: params.productID
  }

  let res = await axios({
    method: 'POST',
    url: `${process.env.VUE_APP_API_URL}/menu/detail`,
    data
  })

  return res;
}

const setProduct = (product) => {
  product.checked = true;
  
  let count = product.count;
  let price = product.price;
  let discountPrice = product.dc_price;

  product.option.map(option => {
    option.content.map(content => {
      if(content.checked) {
        price += content.price
      }
    })
  })

  product.totalPrice = price;
  product.totalCountPrice = price*count;
  product.totalDiscountPrice = discountPrice*count;
  product.tax_rate = 10
  product.service_rate = 0;

  return product;
}

const payOrder = async (data) => {
  if(data.user_tel === undefined) {
    data.user_tel = ' '
  }

  let config = {
    method: 'post',
    url: `${process.env.VUE_APP_API_URL}/menu/order`,
    headers: {
      'Content-Type': 'application/json'
    },
    data
  }

  try {
    let res = await axios(config)
    return res;
  } catch (error) {
    return error.response;
  }
}

export {
  fetchStoreInformation,
  fetchCategoryList,
  fetchProductList,
  fetchProductDetail,
  setProduct,
  payOrder
}

