<template>
  <div id="orderResult">
    <div class="display-area">
      <div class="outer">
        <div class="inner">
          <div class="centered window">
            <div class="result-title">{{ status!='fail' ? $t('RESULT.RESULT_TITLE_1') : $t('RESULT.RESULT_TITLE_2') }}</div>
            <div class="result-number-box">
              <div v-if="this.$store.state.regNumber || regNumber" class="result-number">{{ $t('RESULT.REGISTRATION_NUMBER') }} : {{ this.$store.state.regNumber || regNumber }}</div>
            </div>
            <div class="result-guide">
              <div class="guide-row">{{ $t('RESULT.GUIDE_MSG_1') }}</div>
              <div class="guide-row">{{ $t('RESULT.GUIDE_MSG_2_1') }}</div>
              <div class="guide-row">{{ $t('RESULT.GUIDE_MSG_2_2') }}</div>
              <div class="guide-row">{{ $t('RESULT.GUIDE_MSG_2_3') }}</div>
              <div class="guide-row">{{ $t('RESULT.GUIDE_MSG_2_4') }}</div>
            </div>
            <div class="result-guide">
              <div class="guide-row">{{ $t('RESULT.GUIDE_MSG_3') }}</div>
              <div class="guide-row">{{ $t('RESULT.GUIDE_MSG_4') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="result-btn-box">
      <button class="result-btn home" @click="goHome()">{{ $t('COMMON.HOME') }}</button>
<!--      <button class="result-btn history" @click="goOrderHistory()">{{ $t('ORDER_HISTORY.INDEX') }}</button>-->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      regNumber: '',
      status: 'success'
    }
  },
  methods: {
    goHome() {
      this.$router.push(`${this.$store.state.gRoot}/main/${this.$store.state.storeID}`);
    },
    goOrderHistory() {
      this.$router.push(`${this.$store.state.gRoot}/history/search`); 
    },
    calcRegNumber () {

    }
  },
  created() {
    document.querySelector('body').style.backgroundColor = '#fff';
    this.regNumber = this.$route.query.reg_number;
    this.status = this.$route.query.status;
  },
  mounted() {

  },
  destroyed() {
    document.querySelector('body').style.backgroundColor = '#f2f2f2';    
  }
}
</script>

<style lang="scss">
#orderResult {
  .display-area {
    @include desktop();
    
    position: fixed;
    top: 50px;
    right: 0;
    bottom: 50px;
    left: 0;

    border-top: 1px solid #ebebeb;

    .window {
      .result-title {
        padding: 0 20px;

        font-size: 45pt;
        font-weight: lighter;

        color: #5c5c5c;
      }

      .result-number-box {
        padding: 25px 35px;

        font-size: 16pt;

        color: #ff8b45;

        .result-number {
          height: 50px;

          border-radius: 25px;

          background-color: #f2f2f2;

          line-height: 50px;
        }
      }

      .result-guide {
        padding: 0 20px;

        font-size: 13pt;

        color: #8a8a8a;

        line-height: 20px;

        &:not(:first-child) {
          margin-top: 26px;
        }
      }
    }
  }

  .result-btn-box {
    @include desktop();

    overflow: hidden;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;

    .result-btn {
      float: left;
      width: 100%;
      height: 50px;

      font-size: 17.333333pt;

      background-color: #ff8b45;
      color: #fff;

      &:active {
        background-color: #d7773c;
      }

      &:not(:last-child) {
        border-right: 1px solid rgba(#000, .08);
      }
    }

    .home {

    }
    .history {

    }
  }
}
</style>
