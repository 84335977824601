<template>
  <div>
    <div class="policy">
      <h1>01.청소년보호를 위한 활동</h1>
      <p>회사는 각종 청소년 유해정보로부터 청소년을 보호하고자 관련 법률에 따라 19세 미만의 청소년들이 유해정보에 접근할 수 없도록 청소년 보호정책을 마련하여 시행하고 있습니다.
        또한 회사는 청소년들의 건전한 성장을 위하여 다음과 같이 활동하고 있습니다.</p>
      <ul>
        <li>
          ① 청소년 유해정보로부터의 청소년 보호계획
          <ul class="in-one">
            <li>- 회사는 청소년 보호계획을 회사의 개인정보 보호 계획에 포함하여 수립하여 시행하고 있습니다.</li>
          </ul>
        </li>
        <li>② 청소년 유해정보에 대한 청소년 접근제한 및 관리 조치
          <ul class="in-one">
            <li>- 회사는 청소년이 아무런 제한장치 없이 청소년 유해정보에 노출되지 않도록 청소년 유해매체물에 대해서는 별도의 인증장치를 마련, 적용하며 청소년
              유해정보가 노출되지 않기 위한 예방차원의 조치를 취합니다.
            </li>
          </ul>
        </li>
        <li>③ 정보통신업무 종사자에 대한 청소년 유해정보로부터의 청소년 보호를 위한 교육
          <ul class="in-one">
            <li>- 개인정보 취급자를 대상으로 청소년보호 관련 법령 및 제재기준, 유해정보 발견 시 대처법, 위반사항 처리에 대한 보고절차 등을 교육하고 있습니다.
            </li>
          </ul>
        </li>
        <li>④ 청소년 유해정보로 인한 피해상담 및 고충처리
          <ul class="in-one">
            <li>- 고객님은 하단에 명시한 ‘청소년보호 책임자 및 담당자’의 연락처를 참고하여 전화나 메일을 통하여 피해상담 및 고충처리를 요청할 수 있습니다.
            </li>
          </ul>
        </li>
        <li>⑤ 그 밖에 청소년 유해정보로부터 청소년을 보호하기 위하여 필요한 사항</li>
      </ul>
      <h1>02. 청소년보호 책임자 및 담당자</h1>
      <h2><span class="material-icons">person</span> 청소년보호 책임자</h2>
      <p>
        성 명 : {{ GET_STORE_INFORMATION.ceo_name }}
        <br> 소 속 : 서비스 운영팀
        <br> 직 책 : 대표
        <br> 전 화 : {{ GET_STORE_INFORMATION.phone_no }}
        <br> 전자우편주소 : <a :href="`mailto:${GET_STORE_INFORMATION.email}`">{{ GET_STORE_INFORMATION.email }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import vuex, { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            'GET_STORE_INFORMATION': 'im/GET_STORE_INFORMATION',
        })
    }
}

</script>

<style lang="scss" scoped>
</style>