<template>
  <div id="OrderFailure">
    <div class="order-failure-page">
      <span class="failure-text">Error!!</span>
      <span class="failure-text">Error Code : {{ GET_ERROR_STATUS.code }}</span>
      <span class="failure-text"></span>
      <span class="failure-text"><p>Error Message : </p>{{ GET_ERROR_STATUS.message }}</span>
    </div>

    <div class="button-group">
      <button class="bottom-button" @click="returnHome()">
        {{ $t('COMMON.HOME') }}
      </button>
    </div>
  </div>
</template>

<script>
import vuex from 'vuex';

export default {
  computed: {
    ...vuex.mapGetters({
      'GET_ERROR_STATUS': 'GET_ERROR_STATUS'
    })
  },
  methods: {
    returnHome() {
      this.$router.push(`${this.$store.state.gRoot}/main/${this.$store.state.storeID}`);
    }
  },
  mounted() {
    console.log(this.GET_ERROR_STATUS)
  }
}
</script>

<style lang="scss">
#OrderFailure {

  .order-failure-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    position: fixed;
    top: 100px;
    right: 0;
    bottom: 100px;
    left: 0;
  
    .failure-text {
      font-size: 23.333333pt;
      color: #5c5c5c;

      text-align: center;

      &:first-child {
        margin-bottom: 32px;
      }
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }

  .button-group {
    @include desktop();

    position: fixed;
    z-index: 2000;
    right: 0;
    bottom: 0;
    left: 0;

    .bottom-button {
      width: 100%;
      height: 50px;

      font-size: 17.333333pt;

      background-color: #ff8b45;
      color: white;

      &:active {
        background-color: #d7773c;
      }
    }
  }

}

</style>