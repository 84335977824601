<script src="../store/modules/store.js"></script>
<template>
  <div id="OrderFailure">
    <div class="order-failure-page">
      <span class="failure-text">주문이 취소되었습니다.</span>
      <p v-if="$route.query.errorMsg" class="error-message">취소  사유: {{ errorMessage }}</p>
    </div>

    <div class="button-group">
      <button class="bottom-button" @click="returnHome()">
        {{ $t('COMMON.HOME') }}
      </button>
    </div>
  </div>
</template>

<script>
import vuex from 'vuex'

export default {
  data() {
    return {
      errorMessage: '',
    }
  },
  created() {
    this.redirectDesktop();

    this.fetchErrorMessage();
  },
  methods: {
    ...vuex.mapActions({
      'SHOW_BROWSER_DEPRECATED': 'SHOW_BROWSER_DEPRECATED',
      'HIDE_BROWSER_DEPRECATED': 'HIDE_BROWSER_DEPRECATED',
    }),
    returnHome() {
      this.$router.push(`${this.$store.state.gRoot}/main/${this.$store.state.storeID}`);
    },
    redirectDesktop() {
      // this.detectAgentBrowser();
    },
    detectAgentBrowser() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];

      let isMobile = toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });

      // if(!(navigator.userAgent.indexOf('Mobi') > -1)) {
      if(!isMobile) {
        this.$router.push(`/stationary/page/order-cancellation`)
        return true;
      }

      if(navigator.userAgent.indexOf("Chrome") !== -1 || navigator.userAgent.indexOf("Mozilla" !== -1)) {
        this.HIDE_BROWSER_DEPRECATED();
      } else {
        this.SHOW_BROWSER_DEPRECATED();
      }
    },
    fetchErrorMessage() {
      this.errorMessage = this.$route.query.errorMsg && decodeURIComponent(this.$route.query.errorMsg);
    }
  }
}
</script>

<style lang="scss">
#OrderFailure {

  .order-failure-page {
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 100px;
    right: 0;
    bottom: 100px;
    left: 0;

    .failure-text {
      font-size: 23.333333pt;
      color: #5c5c5c;

      text-align: center;
    }

    .error-message {
      margin-top: 20px;
      color: #5e5e5e;
      font-size: 17px;
    }
  }

  .button-group {
    @include desktop();

    position: fixed;
    z-index: 2000;
    right: 0;
    bottom: 0;
    left: 0;

    .bottom-button {
      width: 100%;
      height: 50px;

      font-size: 17.333333pt;

      background-color: #ff8b45;
      color: white;

      &:active {
        background-color: #d7773c;
      }
    }
  }

}

</style>