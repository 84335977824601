<template>
  <div id="orderPhone">
    <div class="input-phone">
      <div class="input-phone-guide">
        <div class="title">{{ $t(INPUT_PHONE.GUIDE_MSG_1) }}</div>
        <div class="warning">{{ $t(INPUT_PHONE.WARNING_MSG_1) }}</div>
      </div>
      <input type="text" inputmode="numeric" name="" id="" placeholder="휴대폰 번호 입력 (-없이)" class="input-phone-number" v-model="phoneNumber" :maxlength="11">
      <div class="agree-collection">
        <input type="checkbox" name="" id="agree-phone-collection" ref="phoneCollection" v-model="phoneCollection" @change="activeBtn">
        <div class="agree-label" @click="togglePhoneCollection()">{{ $t('INPUT_PHONE.CHECKBOX_TEXT') }}</div>
        <div class="agree-collection-explain">보기</div>
      </div>
    </div>

    <div class="btn-box">
      <button class="btn-order-payment" @click="goOrderCreditcard()" :disabled="buttonDisabled">{{ $t('ORDER_PAYMENT.DO') }}</button>
    </div>
  </div>
</template>

<script>
import vuex from 'vuex';

import language from '../filters/language.js'

export default {
  data() {
    return {
      phoneCollection: false,
      phoneNumber: '',
      buttonDisabled: true
    }
  },
  computed: {
    ...vuex.mapGetters({
      'getOrderInform': 'getOrderInform',
      'GET_LANG': 'lang/GET_LANG',
      'getStoreImformation': 'getStoreImformation'
    })
  },
  filters: {
    language
  },
  methods: {
    ...vuex.mapActions({
      'PAY_QR_CODE': 'qr/PAY_QR_CODE'
    }),
    togglePhoneCollection() {
      this.$refs['phoneCollection'].click();
    },
    async goOrderCreditcard() {
      // let win = window.open();
      console.log(this.getOrderInform);

      this.buttonDisabled = true;
      let regExp = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
      let phoneNumValid = regExp.test(this.phoneNumber);

      if(phoneNumValid === false) {
        this.$store.commit('showToastMsg', this.$t('INPUT_PHONE.POPUP_MSG_1'));
        return;
      }

      if(this.phoneCollection === false) {
        this.$store.commit('showToastMsg', this.$t('INPUT_PHONE.POPUP_MSG_2'));
        return;
      }

      this.$store.commit('setStates', [
        { target: 'orderInform', key: 'phoneNumber', value: this.phoneNumber }
      ])

      console.log(this.getOrderInform);

      let orderMethod = this.getOrderInform.code;
      // if (orderMethod == 'creditCard') {
      //   this.$router.push(`${this.$store.state.gRoot}/order/creditcard`);
      // } else if (orderMethod =='QR') {
        let data = {
          language: this.GET_LANG,
          store_id: this.$store.state.storeID,
          store_name: language(this.getStoreImformation.store_names),
          payment_type_code: this.getOrderInform.code,
          payment_type_extra: this.getOrderInform.extra,
          user_tel: this.$store.state.orderInform.phoneNumber,
          title: this.getOrderTitle(),
          packing_yn: this.$store.state.orderInform.packing? "Y" : "N",
          order_fail_time: this.$store.state.storeSetting.order_fail_time,
          request_t: this.$store.state.orderInform.requestMsg,
          order: this.$store.state.order
        }
        this.buttonDisabled = true;

        console.log(data)

        let res = await this.PAY_QR_CODE(data);

        if (res.status == 200) {
          let url = res.data.qr_url;

          location.replace(url)
          // let newWindow = window.open(res.data.qr_url, '_blank');
          // newWindow.focus();
          // location.href = res.data.qr_url, '_blank';
          // win.location = url;
          // this.$router.push('/cus/product/list')
        }

      // }
    },
    getOrderTitle() {
      let order = this.$store.state.order;
      let title = '';
      if(order.length > 1) {
        title = `${language(order[0].menu_names)} 외 ${order.length-1}건`
      } else {
        title = `${language(order[0].menu_names)}`;
      }

      return title;
    },
    activeBtn () {
      if (this.phoneCollection === true) {
        this.buttonDisabled = false;
      }
    }
  }
}
</script>

<style lang="scss">
@import '../assets/stylesheets/common.scss';

#orderPhone {
  .input-phone {
    .input-phone-guide {
      padding: 30px 0;

      text-align: center;

      .title {
        font-size: 15pt;

        color: #424242;

        line-height: 25px;
      }

      .warning {
        margin-top: 10px;
        font-size: 12pt;

        color: #ff4a64;

        line-height: 19px;
      }
    }

    .input-phone-number {
      display: inline-block;

      width: 100%;
      height: 60px;
      padding: 0 20px;

      border-bottom: 1px solid #ebebeb;
      font-size: 14pt;

      background-color: #fff;
      color: #424242;

      @include placeholders(#b3b3b3);
    }

    .agree-collection {
      overflow: hidden;
      padding: 15px 10px;

      #agree-phone-collection {
        display: none;
      }

      .agree-label {
        float: left;

        height: 27px;
        padding-left: 38px;

        font-size: 12pt;

        background-image: url('../assets/images/bt_s_check_n.png');
        background-size: 38px 27px;
        background-position: left center;
        background-repeat: no-repeat;
        color: #808080;

        line-height: 27px;
      }

      #agree-phone-collection:checked + .agree-label {
        background-image: url('../assets/images/bt_s_check_a.png');        
      }

      .agree-collection-explain {
        float: right;

        width: 54px;
        height: 27px;

        font-size: 12pt;

        color: #ff8b45;

        line-height: 27px;
        text-decoration: underline;
      }
    }
  }

  .btn-box {
    position: fixed;
    @include desktop();

    right: 0;
    bottom: 0;
    left: 0;

    .btn-order-payment {
      display: block;

      width: 100%;
      height: 50px;

      font-size: 17.333333pt;

      background-color: #ff8b45;
      color: #fff;

      &:active {
        background-color: #d7773c;
      }

      &:disabled {
        background-color: #808080;
      }
    }
  }
}
</style>

