<template>
  <div id="TheScreen">
    <div class="curtain left-curtain"></div>
    <div class="curtain right-curtain"></div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
#TheScreen {
  display: none;

  /* @media all and (min-width: 600px) {
    display: block;

    .curtain {
      position: fixed;
      z-index: 1000;
      top: 0;
      bottom: 0;

      background-color: #e6e6e6;
    }
    .left-curtain {
      right: calc(50% + 250px);
      left: 0;
    }
    .right-curtain {
      right: 0;
      left: calc(50% + 250px);
    }
  } */
}
</style>