<script src="../router/index.js"></script>
<script src="../store/modules/im.js"></script>
<script src="../store/modules/store.js"></script>
<template>
  <div id="productList">
    <div class="content-layout">
      <div v-if="getStoreImformation.ad_list && getStoreImformation.ad_list.length>0">
        <hooper :autoPlay="true" :playSpeed="6000">
          <slide v-for="(ad, idx) in getStoreImformation.ad_list" :key="idx">
            <div class="slide-item" :style="{ backgroundImage: `url('${ad.ad_img}')` }"></div>
          </slide>
          <pagination slot="hooper-addons" v-if="getStoreImformation.ad_list && getStoreImformation.ad_list.length>1"></pagination>
        </hooper>
      </div>


      <div class="category-list">
        <button v-for="(category, index) in obCategoryList" :key="index" @click="getProductList(category.category_id)" class="category-item" :class="{ checked: onCategoryItem(category.category_id) }">{{ category.category_names | language}}</button>
      </div>

      <div class="product-list">
        <div class="product-item-wrap" v-for="(product, index) in obProductList" :key="index" @click="goProductDetail(product)">
          <div class="product-item">
            <div class="sold-out" v-if="product.sold_out_yn === 'Y'">
              <div class="outer">
                <div class="inner">
                  <div class="centered sold-out-text">품절</div>
                </div>
              </div>
            </div>
            <span class="discount-percent" v-if="product.dc_percent>0">{{ product.dc_percent }}%</span>
            <div class="product-image-wrap">
              <!-- <div class="product-image" :style="{ backgroundImage: 'url(' + product.menu_img + ')' }"></div> -->
              <div class="product-image" :style="{ 'background-image': 'url(' + product.menu_img + ')' }"></div>
            </div>
            <div class="product-digest">
              <div class="product-name">{{ product.menu_names | language }}</div>
              <div class="product-price">
                <span class="product-origin-price" v-if="product.dc_price !== 0">{{ product.price + product.dc_price | commaNumber }}{{ $t('COMMON.NONEYARY_UNIT') }}</span>
                <span class="product-discount-price">{{ product.price | commaNumber }}{{ $t('COMMON.NONEYARY_UNIT') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <HomeFooter></HomeFooter>
    <UserAgentBrowser></UserAgentBrowser>
  </div>
</template>

<script>
import vuex from 'vuex';
import axios from 'axios';
import { Hooper, Slide, Pagination } from 'hooper';
import 'hooper/dist/hooper.css';

import HomeFooter from '../components/HomeFooter.vue'
import UserAgentBrowser from '../components/UserAgentBrowser.vue'

import language from '../filters/language.js'

export default {
  name: 'ProductList',
  components: {
    Hooper,
    Slide,
    Pagination,
    HomeFooter,
    UserAgentBrowser
  },
  data () {
    return {
      autoplay: true,
      loop: true,
      navigationEnabled: true,
      perPage: 1,
      flickityOptions: {
        autoPlay: 500,
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: true,
        wrapAround: true
        
        // any options from Flickity can be used
      }
    }
  },
  computed: {
    ...vuex.mapGetters({
      'getStoreImformation': 'getStoreImformation',
      'getStoreID': 'getStoreID'
    }),
    obCategoryList() {
      return this.$store.state.categoryList;
    },
    obProductList() {
      return this.$store.state.productList;
    },
  },
  filters: {
    commaNumber(value) {
      value = '' + value;
      let floatingPoint = value.split('.');
      value = floatingPoint[0];

      return `${value.split('').reverse().reduce((acc, digit, i) => {
        if (i > 0 && i % 3 === 0) acc.push(',')
        return [...acc, digit]
      }, []).reverse().join('')}`;
    },
    language
  },
  methods: {
    ...vuex.mapActions({
      'SET_PRODUCT_DETAIL': 'SET_PRODUCT_DETAIL',
      'SHOW_BROWSER_DEPRECATED': 'SHOW_BROWSER_DEPRECATED',
      'HIDE_BROWSER_DEPRECATED': 'HIDE_BROWSER_DEPRECATED',
      'SAVE_LOCATION_URL': 'SAVE_LOCATION_URL',
    }),
    // 카테고리 리스트를 불러온다.
    getCategoryList() {
      let self = this;

      axios({
        method: 'post',
        url: `${process.env.VUE_APP_API_URL}/category/list`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          store_id: this.$store.state.storeID
        }
      })
        .then(function(res) {
          let categoryList = res.data.data;

          self.$store.commit('setState', {
            key: 'categoryList',
            value: categoryList
          });

          // 저장되거나 선택된 카테고리가 없으면 첫번째 카테고리를 선택해준다.
          if(self.$store.state.categoryID === '') {
            self.$store.commit('setState', {
              key: 'categoryID',
              value: categoryList[0].category_id
            })

            self.getProductList(categoryList[0].category_id);
          } else {
            self.getProductList(self.$store.state.categoryID);
          }

        })
        .catch(function(err) {
          console.log(err);  
        })        
    },
    // 선택된 카테고리를 확인한다.
    onCategoryItem(index) {
      return  this.$store.state.categoryID == index;
    },
    // 상품 리스트를 불러온다.
    getProductList(categoryID) {
      let self = this
      // if(categoryID === this.$store.state.categoryID) {
      //   return;
      // }

      axios({
        method: 'post',
        url: `${process.env.VUE_APP_API_URL}/menu/list`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          store_id: this.$store.state.storeID,
          category_id: categoryID
        }
      })
        .then(function(res) {
          self.$store.commit('setState', {
            key: 'categoryID',
            value: categoryID
          })
          
          let productList = res.data.data;
          
          self.$store.commit('setState', {
            key: 'productList',
            value: productList
          });
        })
        .catch(function(err) {
          console.log(err);
        })
    },
    goProductDetail(product) {
      let self = this;
      let productID = product.menu_id
      let storeID = this.getStoreID.replace(regex, '');
      let regex = /./gi;

      axios({
        method: 'post',
        url: `${process.env.VUE_APP_API_URL}/menu/detail`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          store_id: storeID,
          menu_id: productID
        }
      })
        .then((res) => {
          self.$store.commit('setState', {
            key: 'productID',
            value: productID
          })
          
          let productDetail = res.data;
          let productOption = productDetail.option;

          for(let option=0; option<productOption.length; option++) {
            let optionContent = productOption[option].content;

            if(productOption[option].option_type === 'OT001') {
              for(let content=0; content<optionContent.length; content++) {
                if(content===0) {
                  optionContent[content].selected = true;
                } else {
                  optionContent[content].selected = false;
                }
              }
            }            
          }

          self.$store.commit('setState', {
            key: 'productDetail',
            value: productDetail
          });

          if(product.custom_price_yn === "Y") {
            this.SET_PRODUCT_DETAIL({
              productID,
              storeID: this.getStoreID
            });
            this.$router.push(`${this.$store.state.gRoot}/custom/price`)
            return 0;
          }

          self.$router.push(`${self.$store.state.gRoot}/product/detail`);
        })
        .catch(function(err) {
          console.log(err);
        })      
    },
    next() {
      this.$refs.flickity.next();
    },    
    previous() {
      this.$refs.flickity.previous();
    },
    detectAgentBrowser() {
      // console.log(navigator.userAgent)
      if(navigator.userAgent.indexOf("Chrome") !== -1 || navigator.userAgent.indexOf("Mozilla" !== -1)) {
        this.HIDE_BROWSER_DEPRECATED();
      } else {
        this.SHOW_BROWSER_DEPRECATED();
      }
    },
    checkHostname() {
      // console.log(location)
      // console.log(location.host)
      // console.log(location.hostname)
      // console.log(location.origin)
      this.SAVE_LOCATION_URL(location)
    },
    adaptiveWeb() {
      let storeID = this.getStoreID;

      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];

      let isMobile = toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem);
      });

      // if(!(navigator.userAgent.indexOf('Mobi') > -1)) {
      if(!isMobile) {
        this.$router.push(`/stationary/home/${storeID}`)
        return true;
      }
    }
  },
  created() {
    this.getCategoryList();
    this.detectAgentBrowser();
    this.checkHostname();
    // this.adaptiveWeb();
  },
}
</script>

<style lang="scss">
@import "../assets/stylesheets/hooper.scss";

#productList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 44px);

  .category-list {
    overflow: auto;
    width: 100%;
    height: 54px;

    border: {
      top: 1px solid #ebebeb;
      bottom: 1px solid #ebebeb;
    }

    background-color: #fff;
    white-space: nowrap;    

    .category-item {
      display: inline-block;

      height:100%;
      padding: 0 9px;

      font-size: 13pt;

      color: #5e5e5e;

      &:active {
        color: #ff8b45;
      }

      &:first-child {
        padding-left: 9px;
      }
      &:last-childe {
        padding-right: 9px;
      }
    }
    .checked {
      color: #ff8b45;
    }
  }

  .product-list {
    overflow: hidden;

    padding-top: 6px;

    .product-item-wrap {
      float: left;

      width: 50%;
      padding-bottom: 6px;

      &:nth-child(2n-1) {
        padding-right: 3px;
        padding-left: 10px;
      }
      &:nth-child(2n) {
        padding-right: 10px;
        padding-left: 3px;
      }

      .product-item {
        position: relative;

        background-color: #fff;

        .sold-out {
          position: absolute;
          z-index: 60;

          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          background-color: rgba(#000, .55);

          .sold-out-text {
            font-size: 20pt;
            color: #fff;
          }
        }

        .discount-percent {
          position: absolute;
          z-index: 40;

          top: 0;
          left: 0;

          width: 36px;
          height: 21px;

          background-color: #ff8b45;
          color: #fff;

          text-align: center;
          line-height: 21px;
        }

        .product-image-wrap {
          padding: 10px;

          border-bottom: 1px solid #f7f7f7;

          .product-image {
            padding-top: 100%;

            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }

        .product-digest {
          padding: 14px 12.666667px 13px;

          .product-name {
            display: -webkit-box;
            overflow: hidden;

            width: 100%;
            height: 24px;

            font-size: 13pt;            

            color: #7c7c7c;

            line-height: 1.44;
            word-wrap: break-word;
            white-space: normal;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          .product-price {
            overflow: hidden;

            margin-top: 6px;

            height: 24px;

            white-space: nowrap;
            text-overflow: ellipsis;

            .product-origin-price {
              margin-right: 6px;
              
              font-size: 11pt;
              color: #b3b3b3;

              line-height: 1.44;
              text-decoration: line-through;
            }

            .product-discount-price {
              font-size: 12pt;
              color: #424242;

              line-height: 1.44;
            }
          }
        }
      }
    }
  }

  .hooper {
    height: 340px;

    .hooper-list {
      .hooper-slide {
        .slide-item {
          background-position: center;
        }
      }
    }
  }
}
</style>
