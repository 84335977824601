<template>
  <div id="DetailImages" v-if="getProductDetailImages.display" @click.self="closeDetailImages">
    <button class="close-detail-images" @click="closeDetailImages"></button>
    <div class="images-container">
      <div class="images-box">
        <hooper>
          <slide v-for="(img, idx) in getProductDetailImages.img" :key="idx">
            <div class="slide-item" :style="{ backgroundImage: `url('${img}')` }"></div>
          </slide>
          <pagination slot="hooper-addons"></pagination>
        </hooper>
      </div>
    </div>
  </div>
</template>

<script>
import vuex from 'vuex'
import { Hooper, Slide, Pagination } from 'hooper';

export default {
  components: {
    Hooper,
    Slide,
    Pagination
  },
  computed: {
    ...vuex.mapGetters({
      'getProductDetailImages': 'getProductDetailImages'
    })
  },
  destroyed() {
    this.HIDE_ENLARGE_DETAIL_IMGS();
  },
  methods: {
    ...vuex.mapActions({
      'HIDE_ENLARGE_DETAIL_IMGS': 'HIDE_ENLARGE_DETAIL_IMGS'
    }),
    closeDetailImages() {
      this.HIDE_ENLARGE_DETAIL_IMGS();
    }
  }
}
</script>

<style lang="scss">
#DetailImages {
  display: flex;
  align-items: center;

  position: fixed;
  z-index: 2000;
  top: 0; right: 0; bottom: 0; left: 0;
  background-color: rgba($color: #000000, $alpha: .84);

  .close-detail-images {
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    width: 44px;
    height: 44px;

    background: none;
    background-image: url('../assets/images/close-nor.svg');

    &:active {
      background-image: url('../assets/images/close-pre.svg');
    }

    @media all and (min-width: 600px) {
      right: calc(50% - 250px)
    }
  }

  .images-container {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding-top: 100%;
    // background-color: red;

    .images-box {
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;

      .hooper {
        height: 100%;

        .hooper-list {
          .hooper-track {
            // max-width: 500px;
            // margin: 0 auto;
            // overflow: hidden;
            
            .hooper-slide {
              // width: 100%;

              .slide-item {
                background-size: contain;
                background-repeat: no-repeat;
              }
            }
          }
        }

        .hooper-pagination {
          position: fixed;
          padding: 8px 10px;          
        }
      }
    }
  }
}

</style>