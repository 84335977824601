import { loadBanner } from '../../api/store';

const state = {
  banners: [],
}

const getters = {
  GET_BANNERS(state) {
    return state.banners;
  }
}

const mutations = {
  SET_BANNERS(state, data) {
    state.banners = data;
  }
}

const actions = {
  async LOAD_BANNER({ commit, rootState }, data) {
    const { im: { storeID} } = rootState;

    try {
      const result = await loadBanner(storeID);
      console.log(result)
      commit('SET_BANNERS', result.data.data)
    } catch (error) {
      console.error(error);
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}