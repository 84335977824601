import Vue from 'vue'
import Router from 'vue-router'

import EntryPoint from '@/pages/EntryPoint'
import TheMain from '@/pages/TheMain'
import Home from '@/pages/TheHome'
import ProductList from '@/pages/ProductList'
import ProductDetail from '@/pages/ProductDetail'
import CartList from '@/pages/CartList'
import OrderPayment from '@/pages/OrderPayment'
import OrderPhone from '@/pages/OrderPhone'
import OrderCreditcard from '@/pages/OrderCreditcard'
import OrderResult from '@/pages/OrderResult'
import HistorySearch from '@/pages/HistorySearch'
import HistoryList from '@/pages/HistoryList'
import HistoryDetail from '@/pages/HistoryDetail'
import CustomPrice from '@/pages/CustomPrice'
import OrderFailure from '@/pages/OrderFailure'

import DefaultStationary from '@/container/DefaultStationary'
import DefaultNavigatorStationary from '@/container/DefaultNavigatorStationary'
import DefaultTest from '@/container/DefaultTest'

import StationaryHome from '@/pages/stationary/Home'
import S_ProductDetail from '@/pages/stationary/ProductDetail'
import S_CART from '@/pages/stationary/Cart'
import S_ORDER from '@/pages/stationary/Order'
import OrderCancellation from '@/pages/stationary/OrderCancellation'

// import Error from '@/layouts/Error'
import Error from '@/pages/ErrorPage'
import RequiredFull from '@/pages/test/RequiredFull'
import TestFailure from '@/pages/test/Failure';

Vue.use(Router)

let gRoot = '/cus'

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: gRoot+'/:storeID',
      name: 'EntryPoint',
      component: EntryPoint
    },
    {
      path: gRoot+'/main/:storeID',
      name: 'TheMain',
      component: TheMain
    },
    {
      path: gRoot+'/product/list',
      name: 'ProductList',
      component: ProductList
    },
    {
      path: gRoot+'/home/list',
      name: 'Home',
      component: Home
    },
    {
      path: gRoot+'/product/detail',
      name: 'ProductDetail',
      component: ProductDetail
    },
    {
      path: gRoot+'/cart/list',
      name: 'CartList',
      component: CartList
    },
    {
      path: gRoot+'/order/payment',
      name: 'OrderPayment',
      component: OrderPayment
    },
    {
      path: gRoot+'/order/phone',
      name: 'OrderPhone',
      component: OrderPhone
    },
    {
      path: gRoot+'/order/creditcard',
      name: 'OrderCreditcard',
      component: OrderCreditcard
    },
    {
      path: gRoot+'/order/result',
      name: 'OrderResult',
      component: OrderResult
    },
    {
      path: gRoot+'/history/search',
      name: 'HistorySearch',
      component: HistorySearch
    },
    {
      path: gRoot+'/history/list',
      name: 'HistoryList',
      component: HistoryList
    }, 
    {
      path: gRoot+'/history/detail',
      name: 'HistoryDetail',
      component: HistoryDetail
    },
    {
      path: gRoot+`/custom/price`,
      name: 'CustomPrice',
      component: CustomPrice
    },
    {
      path: gRoot+'/page/error',
      name: 'Error',
      component: Error
    },
    {
      path: gRoot+'/order/failure',
      name: 'OrderFailure',
      component: OrderFailure
    },
    {
      path: '/stationary',
      redirect: '',
      name: 'DefaultStationary',
      component: DefaultStationary,
      children: [
        {
          path: 'home/:storeID',
          name: 'SHome',
          component: StationaryHome
        },
        {
          path: 'home',
          name: 'SHome',
          component: StationaryHome
        },
        {
          path: 'product/detail',
          name: 'S_ProductDetail',
          component: S_ProductDetail
        },
        {
          path: 'cart',
          name: 'S_CART',
          component: S_CART
        },
        {
          path: 'order',
          name: 'S_ORDER',
          component: S_ORDER
        },
        {
          path: 'page',
          name: 'DefaultNavigatorStationary',
          component: DefaultNavigatorStationary,
          children: [
            {
              path: 'order-cancellation',
              name: 'OrderCancellation',
              component: OrderCancellation
            }
          ]
        }
      ]
    },
    // {
    //   path: '/stationary',
    //   redirect: '',
    //   name: 'DefualtTest',
    //   component: DefaultTest,
    //   children: [
    //     {
    //       path: 'test',
    //       name: 'RequiredFull',
    //       component: RequiredFull,
    //     },
    //     {
    //       path: 'failure',
    //       name: 'TestFailure',
    //       component: TestFailure
    //     }
    //   ]
    // }
  ]
})
