import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';

import VueCookies from 'vue-cookies'

// stylesheets
import '@/assets/stylesheets/reset.css';
import '@/assets/stylesheets/common.scss';
import '@/assets/stylesheets/spin.css';
// import '@/assets/stylesheets/nanumbarungothic.css';
import i18n from './i18n'

Vue.use(VueCookies);
VueCookies.config('7d');

Vue.config.devtools = true;

// Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
  axios
}).$mount('#app')

// // The Vue build version to load with the `import` command
// // (runtime-only or standalone) has been set in webpack.base.conf with an alias.



// /* eslint-disable no-new */
// new Vue({
//   el: '#app',

//   components: { App },
//   template: '<App/>'
// })
