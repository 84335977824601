<template>
  <div id="GuideSelection" v-if="getInputPhoneModal">
    <div class="background-dim">
      <div class="window">
        <div class="contents">
          <div class="title">{{ $t('INPUT_PHONE.POPUP_TITLE') }}</div>
          <div class="question">
            <!-- <div>주문 내역 조회와 SMS 안내를</div>
            <div>휴대폰 번호로 받으시겠어요?</div> -->
            <div>{{ $t('INPUT_PHONE.POPUP_QUESTION') }}</div>
          </div>
          <div class="guide">
            <!-- <div>휴대폰 번호 미입력시</div>
            <div><span class="strong">주문내역 조회와 알림 수신이 불가</span>합니다.</div> -->
            <div>{{ $t('INPUT_PHONE.POPUP_GUIDE') }}</div>
          </div>
        </div>

        <div class="button-group">
          <button class="reject" @click="openPayment">{{ $t('INPUT_PHONE.BTN_REJECTION') }}</button>
          <button class="approved" @click="routeInputPhone">{{ $t('INPUT_PHONE.BTN_CONFIRM') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vuex from 'vuex'

import language from '../filters/language.js'

export default {
  computed: {
    ...vuex.mapGetters({
      'getOrderInform': 'getOrderInform',
      'getInputPhoneModal': 'getInputPhoneModal',
      'GET_LANG': 'lang/GET_LANG',
      'getStoreImformation': 'getStoreImformation'
    })
  },
  filters: {
    language
  },
  destroyed() {
    this.HIDE_INPUT_PHONE_MODAL();
  },
  methods: {
    ...vuex.mapActions({
      'PAY_QR_CODE': 'qr/PAY_QR_CODE',
      'SHOW_INPUT_PHONE_MODAL': 'SHOW_INPUT_PHONE_MODAL',
      'HIDE_INPUT_PHONE_MODAL': 'HIDE_INPUT_PHONE_MODAL',
      'SET_ERROR': 'SET_ERROR'
    }),
    routeInputPhone() {
      this.$router.push(`${this.$store.state.gRoot}/order/phone`);
      this.HIDE_INPUT_PHONE_MODAL();
    },
    async openPayment() {
      console.log('open payment')
      // let win = window.open();

      let data = {
        language: this.GET_LANG,
        store_id: this.$store.state.storeID,
        store_name: language(this.getStoreImformation.store_names),
        payment_type_code: this.getOrderInform.code,
        payment_type_extra: this.getOrderInform.extra,
        user_tel: this.$store.state.orderInform.phoneNumber,
        title: this.getOrderTitle(),
        packing_yn: this.$store.state.orderInform.packing? "Y" : "N",
        order_fail_time: this.$store.state.storeSetting.order_fail_time,
        request_t: this.$store.state.orderInform.requestMsg,
        order: this.$store.state.order
      }

      let res = await this.PAY_QR_CODE(data);

      if(res.data && res.data.result && res.data.result.message) {
        this.SET_ERROR({
          message: res.data.result.message,
          code: res.data.result.code
        })
        
        this.$router.push(`${this.$store.state.gRoot}/page/error`);
        return;
      }

      if (res.status == 200) {
        this.HIDE_INPUT_PHONE_MODAL();
        let url = res.data.qr_url;

        // location.href = url
        location.replace(url);
        // console.log(url)
        // win.location = url;

      }
      this.HIDE_INPUT_PHONE_MODAL();
    },
    getOrderTitle() {
      let order = this.$store.state.order;
      let title = '';
      if(order.length > 1) {
        title = `${language(order[0].menu_names)} 외 ${order.length-1}건`
      } else {
        title = `${language(order[0].menu_names)}`;
      }

      return title;
    },
  }
}
</script>

<style lang="scss">
#GuideSelection {
  .background-dim {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2000;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba($color: #000000, $alpha: .8);

    .window {
      min-width: 300px;
      padding: 30px;

      border-radius: 4px;
      background-color: white;

      .contents {
        .title {
          font-size: 16px;
          font-weight: bold;
        }

        .question {
          margin-top: 20px;
          font-size: 15px;
          line-height: 1.44;
        }

        .guide {
          margin-top: 20px;
          font-size: 14px;

          color: #828282;

          line-height: 1.44;

          .strong {
            font-weight: bold
          }
        }
      }

      .button-group {
        margin-top: 30px;
        text-align: right;

        .reject {
          font-size: 15px;
          color: #828282;
        }
        .approved {
          margin-left: 16px;
          font-size: 15px;
          color: #4a9cdd;
        }
      }
    }
  }
}


</style>