<template>
  <footer id="footer" :class="{ 'footer-fixed': obFixed }">
    <div class="company-logo" @click="homepage()"></div>
    <div class="company-text">엘릭서 메뉴판으로 제작된 홈페이지입니다.</div>
    <div class="company-text" @click="callPhone()">{{ phoneNumber }}</div>
    <div class="company-text">version 1.2.1</div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      phoneNumber: '02-0000-0000'
    }
  },
  computed: {
    obFixed() {
      return this.$store.state.preferences.fixedFooter;
    }
  },
  methods: {
    callPhone() {
      location.href = `tel:${this.phoneNumber}`
    },
    homepage() {
      window.open('https://www.elixirpay.com/');
    }
  },
  created() {
    
  },
}
</script>

<style lang="scss">
#footer {
  padding: 6px 0 16.666667px;

  background-color: #333;

  .company-logo {
    height: 32px;

    background-image: url('../assets/images/footer_logo.png');
    background-position: center;
    background-size: 92px 32px;
    background-repeat: no-repeat;
  }

  .company-text {
    font-size: 10pt;

    color: rgba(#fff, .4);

    text-align: center;
    line-height: 1.44;
  }
}

.footer-fixed {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
