import store from '../store/index.js'

export default (texts) => {
  const type = typeof texts;

  if(type !== 'array' && type !== 'object') {
    return texts;
  }

  let lang = store.getters['lang/GET_LANG'];
  texts = Object.values(texts);
  
  for(let obj of texts) {
    if(obj.lang === lang) {
      return obj.value;
    }
  }
}