const state = {
  lang: 'ko',
  multilingual: {
    display: false,
    show: true
  }
}

const getters = {
  GET_LANG(state) {
    return state.lang
  },
  GET_MULTILINGUAL(state) {
    return state.multilingual
  },
  GET_MULTILINGUAL_BUTTON(state) {
    return state.multilingual.show
  }
}

const mutations = {
  SET_LANGUAGE(state, data) {
   state.lang = data.lang;
  },
  SET_MULTILINGUAL_DISPLAY(state, data) {
    state.multilingual.display = data;
  },
  SET_LANGUAGE_LIST(state, data) {
    state.multilingual.show = data;
  }
}

const actions = {
  SET_LANGUAGE(context, data) {
    context.commit('SET_LANGUAGE', data)
  },
  SET_LANGUAGE_LIST(context, data) {
    let count = 0;
    let display = true;

    for(let lang of data) {
      if(lang.use === true) {
        count++;
      }
    }

    if(count <= 1) {
      display = false
    }

    context.commit('SET_LANGUAGE_LIST', display)    
  },
  SHOW_MULTILINGUAL(context) {
    context.commit('SET_MULTILINGUAL_DISPLAY', true);
  },
  HIDE_MULTILINGUAL(context) {
    context.commit('SET_MULTILINGUAL_DISPLAY', false);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}