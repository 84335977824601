<template>
  <div id="cartList">
    <div class="cart-list" v-if="obCart.length !== 0">
      <div class="cart-item" v-for="(item, index) in obCart" :key='index'>
        <button class="remove-item" @click="removeItem(index)"></button>
        <div class="product-digest-box">
          <div class="product-image-box">
            <div class="product-image" :style="{ backgroundImage: 'url(' + item.menu_img + ')' }"></div>
            <!-- <img class="product-image" :src="item.menu_img" alt=""> -->
          </div>
          <div class="product-digest-text">
            <div class="product-name">{{ item.menu_names | language }}</div>
            <div class="product-price">
              <div class="product-origin-price">{{ item.price + item.dc_price | commaNumber }} 원</div>
              <div class="product-price">{{ item.price | commaNumber }} 원</div>
            </div>
          </div>
        </div>
        <div class="item-price">
          <div class="item-price-row">
            <div class="row-title">{{ $t('SHOPPING_BASKET.MENU_AMOUNT') }}</div>
            <div class="row-content">{{ item.price + item.dc_price | commaNumber }} 원</div>
          </div>
          <div class="item-price-row">
            <div class="row-title">{{ $t('SHOPPING_BASKET.DISCOUNT_AMOLUNT') }}</div>
            <div class="row-content"><span v-if="item.dc_price>0">-</span> {{ item.dc_price | commaNumber }} 원</div>
          </div>
        </div>
        <div class="item-option-list" v-if="item.option.length !== 0">
          <div class="option-item" v-for="(option, index) in item.option" :key="index">
            <div class="option-name">{{ option.content }}</div>
            <div class="option-price">{{ option.price | commaSymbolNumber }} 원</div>
          </div>
        </div>
        <div class="item-synthesis">
          <div class="item-count-box">
            <div class="count-title">{{ $t('ORDER.QUANTITY') }}</div>
            <div class="control-switch-box">
              <button class="control-btn btn-minus" @click="subCount(index)" :disabled="item.count === 1"></button>
              <span class="order-quantity">{{ item.count }}</span>
              <button class="control-btn btn-plus" @click="addCount(index)"></button>
            </div>
          </div>
          <div class="item-total-price">
            <div class="total-price-title">{{ $t('ORDER.SUB_TOTAL') }}</div>
            <div class="total-price">{{ item | itemPrice }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="cart-synthesis-box" v-if="obCart.length !== 0">
      <div class="cart-total-price">
        <div class="total-price-row">
          <div class="row-title">{{ $t('ORDER.TOTAL_MENU_AMOUNT') }}</div>
          <div class="row-content">{{ totalCartInform(obCart, 'originPrice') | commaNumber }} 원</div>
        </div>
        <div class="total-price-row">
          <div class="row-title">{{ $t('ORDER.TOTAL_DISCOUNT_AMOUNT') }}</div>
          <div class="row-content">- {{ totalCartInform(obCart, 'DCPrice') | commaNumber }} 원</div>
        </div>
      </div>
      <div class="cart-synthesis-horizon"></div>
      <div class="cart-total-inform">
        <div class="inform-title">{{ $t('ORDER.FINAL_PAYMENT_AMOUNT') }}</div>
        <div class="inform-amount">
          <div class="inform-count">{{ totalCartInform(obCart, 'count') | commaNumber }}</div>
          <div class="inform-price">{{ totalCartInform(obCart, 'price') | commaNumber }} 원</div>
        </div>
      </div>
    </div>

    <div class="btn-box" v-if="obCart.length !== 0">
      <button class="pay-order" @click="goOrderPayment()">{{ $t('PLACE_AN_ORDER') }}</button>
    </div>

    <div class="empty-cart-container" v-if="obCart.length === 0">
      <div class="outer">
        <div class="inner">
          <div class="centered empty-cart">장바구니에 담긴 상품이 없습니다</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import language from '../filters/language.js'

export default {
  computed: {
    obCart() {
      return this.$store.state.cart;
    }
  },
  filters: {
    commaSymbolNumber(value) {
      let symbol = '';
      
      if (value > 0) {
        symbol = '+';
      } else if (value < 0) {
        symbol = '-';
        value = -value;
      }
      
      value = '' + value;
      let floatingPoint = value.split('.');
      value = floatingPoint[0];

      return `${symbol} ${value.split('').reverse().reduce((acc, digit, i) => {
        if (i > 0 && i % 3 === 0) acc.push(',')
        return [...acc, digit]
      }, []).reverse().join('')}`;
    },
    commaNumber(value) {
      value = '' + value;

      return value.split('').reverse().reduce((acc, digit, i) => {
        if (i > 0 && i % 3 === 0) acc.push(',')
        return [...acc, digit]
      }, []).reverse().join('');
    },
    itemPrice(item) {
      let count = item.count;
      let price = item.price;

      for(let opt in item.option) {
        price += item.option[opt].price;
      }

      return (count*price + '').split('').reverse().reduce((acc, digit, i) => {
        if (i > 0 && i % 3 === 0) acc.push(',')
        return [...acc, digit]
      }, []).reverse().join('');
    },
    language
  },
  methods: {
    addCount(idx) {
      this.$store.commit('cartCount', {
        idx,
        calc: 'add'
      })
    },
    subCount(idx) {
      this.$store.commit('cartCount', {
        idx,
        calc: 'sub'
      })
    },
    removeItem(idx) {
      this.$store.commit('removeCartItem', {
        idx
      })
      this.$store.commit('showToastMsg', this.$t('SHOPPING_BASKET.NOTIFICATION_TOAST'));
    },
    totalCartInform(cartList, key) {
      let totalPrice = 0;
      let totalDCPrice = 0;
      let totalCount = 0;

      for(let cart in cartList) {
        let itemTotalPrice = 0;
        let itemTotalDCPrice = 0;
        let itemTotalCount = 0;

        let cartItem = cartList[cart];

        itemTotalPrice += cartItem.price;
        itemTotalDCPrice += cartItem.dc_price;
        itemTotalCount += cartItem.count;

        for(let opt in cartItem.option) {
          let optionItem = cartItem.option[opt];

          itemTotalPrice += optionItem.price;
        }

        itemTotalPrice = itemTotalPrice*itemTotalCount;
        itemTotalDCPrice = itemTotalDCPrice*itemTotalCount;

        totalPrice += itemTotalPrice;
        totalDCPrice += itemTotalDCPrice;
        totalCount += itemTotalCount;
      }

      if (key === 'originPrice') {
        return totalPrice+totalDCPrice;
      } else if (key === 'DCPrice') {
        return totalDCPrice;
      } else if (key === 'count') {
        return totalCount;
      } else if (key === 'price') {
        return totalPrice;
      }

      return totalPrice;
    },
    goOrderPayment() {
      this.$store.commit('raplaceCartOrder', this.$store.state.cart);

      this.$router.push(`${this.$store.state.gRoot}/order/payment`);
    }
  },
  created() {
  }
}
</script>

<style lang="scss">
#cartList {
  .cart-list {
    padding-bottom: 6px;

    .cart-item {
      position: relative;

      background-color: #fff;

      .remove-item {
        display: inline-block;

        position: absolute;

        z-index: 100;

        top: 0;
        right: 0;

        width: 38px;
        height: 38px;

        background-image: url('../assets/images/bt_close_g_n.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        transition: all 5s;

        &:active {
          background-image: url('../assets/images/bt_close_g_p.png');
        }
      }

      .product-digest-box {
        overflow: hidden;
        position: relative;
        margin-top: 6px;
        border-bottom: 1px solid #f7f7f7;
        background-color: #fff;

        .product-discount-percent {
          display: inline-block;

          position: absolute;
          top: 0;
          left: 0;

          width: 36px;
          height: 21px;

          font-size: 12pt;

          background-color: #ff8b45;
          color: #fff;

          text-align: center;
          line-height: 21px;
        }

        .product-image-box {
          float: left;
          padding: 11px 13px;

          width: 108px;
          height: 94px;

          border-right: 1px solid #f7f7f7;

          .product-image {
            display: inline-block;

            width: 100%;
            height: 100%;

            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }

        .product-digest-text {
          float: left;

          width: 100%;
          padding: {
            top: 18px;
            right: 38px;
            bottom: 17px;
            left: 20px;
          }
          margin-right: -108px;
          padding-right: 146px;

          .product-name {
            // display: -webkit-box;
            overflow: hidden;
            
            height: 19px;

            font-size: 13pt;

            color: #424242;

            line-height: 19px;
            text-overflow: ellipsis;
            // word-wrap: break-word;
            // white-space: normal;
            white-space: nowrap; 
            // -webkit-line-clamp: 1;
            // -webkit-box-orient: vertical;
          }

          .product-price {
            margin-top: 5px;

            .product-origin-price {
              height: 16px;

              font-size: 12pt;
              color: #b3b3b3;
              line-height: 16px;
              text-decoration: line-through;
            }

            .product-price {
              height: 19px;

              font-size: 13pt;
              color: #424242;

              line-height: 19px;
            }
          }
        }
      }

      .item-price {
        padding: 12px 20px;

        border-bottom: 1px solid #f7f7f7;

        .item-price-row {
          overflow: hidden;

          height: 24px;

          font-size: 12pt;

          line-height: 24px;

          .row-title {
            float: left;

            color: #999;
          }

          .row-content {
            float: right;

            color: #424242;
          }
        }
      }

      .item-option-list {
        padding: 12px 20px;

        border-bottom: 1px solid #f7f7f7;

        .option-item {
          overflow: hidden;

          height: 18px;

          font-size: 12pt;

          color: #a8a8a8;

          line-height: 18px;

          &:not(:first-child) {
            margin-top: 4px;
          }

          .option-name {
            float: left;
          }
          .option-price {
            float: right;
          }
        }
      }

      .item-synthesis {
        .item-count-box {
          overflow: hidden;

          height: 43px;
          padding: 0 20px;

          border-bottom: 1px solid #f7f7f7;

          line-height: 43px;

          .count-title {
            float: left;

            font-size: 13pt;
            color: #424242;
          }
          .control-switch-box {
            overflow: hidden;
            float: right;

            height: 100%;

            font-size: 0;

            .control-btn {
              display: inline-block;
              
              width: 32px;
              height: 32px;

              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;

              vertical-align: middle;
            }
            .btn-minus {
              background-image: url('../assets/images/bt_minus_n.png');

              &:active {
                background-image: url('../assets/images/bt_minus_p.png');
              }

              &:disabled {
                background-image: url('../assets/images/bt_minus_d.png');
              }
            }
            .btn-plus {
              background-image: url('../assets/images/bt_plus_n.png');

              &:active {
                background-image: url('../assets/images/bt_plus_p.png');
              }
            }
            .order-quantity {
              display: inline-block;

              width: 66px;

              font-size: 17pt;
              font-weight: 700;

              color: #ff8b45;

              text-align: center;
              vertical-align: middle;
            }
          }
        }

        .item-total-price {
          overflow: hidden;

          height: 43px;
          padding: 0 20px;

          border-bottom: 1px solid #f7f7f7;

          line-height: 43px;


          .total-price-title {
            float: left;

            border-bottom: 1px solid #ebebeb;
            font-size: 13pt;

            color: #424242;
          }
          .total-price {
            float: right;

            font-size: 13pt;
            color: #4a9cdd;
          }
        }
      }
    }
  }

  .cart-synthesis-box {
    margin-top: 20px;
    margin-bottom: 70px;

    border-bottom: 1px solid #ebebeb;

    background-color: #fff;

    .cart-total-price {
      padding: 14px 20px 12px;

      .total-price-row {
        overflow: hidden;
        
        height: 24px;

        font-size: 13pt;

        line-height: 24px;

        .row-title {
          float: left;

          color: #999;
        }

        .row-content {
          float: right;
          
          color: #424242;
        }
      }
    }

    .cart-synthesis-horizon {
      margin: 0 11px;
      border-bottom: 1px solid #f7f7f7;
    }

    .cart-total-inform {
      overflow: hidden;
      
      height: 60px;
      padding: 0 20px;

      font-size: 13pt;

      color: #424242;

      line-height: 60px;

      .inform-title {
        float: left;
      }
      .inform-amount {
        float: right;

        .inform-count {
          float: left;

          width: 30px;
          margin: 0 4px;

          text-align: center;
        }
        .inform-price {
          float: left;

          width: 92px;

          font-size: 14pt;
          font-weight: 700;

          color: #4a9cdd;

          text-align: right;
        }
      }
    }
  }

  .btn-box {
    @include desktop();

    position: fixed;
    z-index: 1000;

    right: 0;
    bottom: 0;
    left: 0;

    .pay-order {
      width: 100%;
      height: 50px;

      font-size: 17.333333pt;

      background-color: #ff8b45;
      color: #fff;

      &:active {
        background-color: #d7773c;
      }
    }
  }

  .empty-cart-container {
    @include desktop();

    position: absolute;

    top: 0;

    width: 100%;
    height: 100%;

    .empty-cart {
      font-size: 16pt;

      color: #808080;
    }
  }
}
</style>
