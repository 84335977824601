<template>
  <div id="StationaryCart">
    <div class="category-navigator">
      <div class="category-navigator-container">
        <div class="category-box">
          <span>
            <button class="menu"></button>
            <button 
              class="category-item"
              v-for="(category, idx) in GET_CATEGORY_LIST" :key="idx"
              @click="lookProductList(category)"
            >{{ category.category_names | language }}</button>
          </span>
        </div>
      </div>
    </div>

    <div class="navigator-step">
      <div class="navigator-step-container">
        <div class="title">장바구니</div>
        <div class="step-list">
          <span class="step-item" v-for="(step, idx) in stepList" :key="step.text">
            <span class="step-name" :class="{ now: $route.name === step.routeName }">{{ step.text }}</span>
            <span class="step-arrow" v-if="stepList.length-1 !== idx">＞</span>
          </span>
        </div>
      </div>
    </div>

    <div class="cart-body-container">
      <div class="cart-body">
        <div class="multiple-controller" :class="{ checked: true }">
          <span class="status">전체선택(4/4)</span>
          <button class="delete-select-item">선택메뉴 삭제</button>
        </div>
        
        <div class="cart-list">
          <div class="cart-item" v-for="(cart, cartIdx) in GET_CART" :key="cartIdx">
            <button class="remove" @click="removeCartItem(cart, cartIdx)"></button>
            <div class="item-core">
              <div class="core-left">
                <button class="toggle-btn" :class="{ 'checked': cart.checked }" @click="toggleCartItem(cartIdx, cart.checked)"></button>
                <div class="core-img" :style="{ backgroundImage: `url('${cart.menu_img}')` }"></div>
                <div class="item-name">
                  <span>{{ cart.menu_name }}</span>
                </div>
              </div>
              <div class="core-right">
                <div class="control-box">
                  <button class="quantity-controller" @click="changeQuantity(cartIdx, 'DECREMENT')"><span>－</span></button>
                  <span class="product-quantity"><span>{{ cart.count }}</span></span>
                  <button class="quantity-controller" @click="changeQuantity(cartIdx, 'INCREMENT')">＋</button>
                </div>
                <div class="price-box">
                  <div class="origin-price">{{ cart.totalCountPrice + cart.totalDiscountPrice | commaPrice }}원</div>
                  <div class="price">{{ cart.totalCountPrice | commaPrice }}원</div>
                </div>
              </div>
            </div>

            <div class="item-detail" v-if="isDetail(cart)">
              <div class="menu-detail common-detail">
                <div class="product-origin-price product-price-item">
                  <span class="title">메뉴금액</span>
                  <span class="number">{{ cart.dc_price + cart.price | commaPrice }}원</span>
                </div>
                <div class="product-discount-price product-price-item" v-if="cart.dc_price !== 0">
                  <span class="title">할인금액</span>
                  <span class="number">-{{ cart.dc_price | commaPrice }}원</span>
                </div>
              </div>
              <div class="option-detail common-detail">
                <div v-for="(option, optionIdx) in cart.option" :key="optionIdx">
                  <div v-for="(content, contentIdx) in option.content" :key="contentIdx">
                    <div class="option-item" v-if="content.checked">
                      <span class="title">{{ content.names | language }}</span>
                      <span class="price">{{ content.price | symbolNumber }}원</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cart-statistics">
          <div class="left">
            <div class="price-box">
              <div class="row">
                <span class="title">총 메뉴 금액</span>
                <span class="price">{{ GET_CART_TOTAL.originPrice | commaPrice }} 원</span>
              </div>
              <div class="row">
                <span class="title">총 할인 금액</span>
                <span class="price">{{ GET_CART_TOTAL.discountPrice | commaPrice }} 원</span>
              </div>
            </div>
            <div class="count-box">
              <div class="title">총 주문개수</div>
              <div class="count"><span class="number">{{ GET_CART_TOTAL.count }}</span> 개</div>
            </div>
          </div>
          <div class="right">
            <div class="title">최종 결제금액</div>
            <div class="total-price"><span class="number">{{ GET_CART_TOTAL.finalPaymentAmount | commaPrice }}</span> 원</div>
          </div>
        </div>

        <div class="cart-button-box">
          <button class="cart-button main" @click="routeMain">메인으로 가기</button>
          <button class="cart-button pay" @click="payOrder">결제하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vuex from 'vuex'
import language from '../../filters/language'
import commaPrice from '../../filters/commaPrice'
import symbolNumber from '../../filters/symbolNumber'

export default {
  data() {
    return {
      stepList: [
        { text: '장바구니', routeName: 'S_CART' },
        { text: '주문／결제', routeName: 'S_ORDER' },
        { text: '주문완료' }
      ],
    }
  },
  computed: {
    ...vuex.mapGetters({
      'GET_CATEGORY_LIST': 'im/GET_CATEGORY_LIST',
      'GET_CATEOGRY_ID': 'im/GET_CATEOGRY_ID',
      'GET_CART': 'im/GET_CART',
      'GET_CART_TOTAL': 'im/GET_CART_TOTAL'
    }),
  },
  filters: {
    language,
    commaPrice,
    symbolNumber
  },
  created() {
    this.setBackgroundColor("#f2f2f2");
    console.log(this.GET_CART)
  },
  destroyed() {
    this.setBackgroundColor("white");

  },
  methods: {
    ...vuex.mapActions({
      'FETCH_PRODUCT_LIST': 'im/FETCH_PRODUCT_LIST',
      'TOGGLE_CART_ITEM': 'im/TOGGLE_CART_ITEM',
      'REMOVE_CART_ITEM': 'im/REMOVE_CART_ITEM',
      'CHNAGE_CART_QUANTITY': 'im/CHNAGE_CART_QUANTITY',
      'CONTAIN_ORDER': 'im/CONTAIN_ORDER'
    }),
    setBackgroundColor(color) {
      document.body.style.backgroundColor = color;
    },
    async lookProductList(category) {
      let categoryID = category.category_id;
      let res = await this.FETCH_PRODUCT_LIST(categoryID);      
      this.$router.push(`/stationary/home`)
    },
    toggleCartItem(idx, value) {
      this.TOGGLE_CART_ITEM({ index: idx, value: !value })
    },
    removeCartItem(item, idx) {
      this.REMOVE_CART_ITEM({ index: [ idx ] })
    },
    isDetail(item) {
      if(item.dc_price !== 0) {
        return true;
      }

      for(let option of item.option) {
        for(let content of option.content) {
          if(content.checked) {
            return true;
          }
        }        
      }
    },
    changeQuantity(index, type) {
      this.CHNAGE_CART_QUANTITY({
        index,
        type
      })
    },
    routeMain() {
      this.$router.push('/stationary/home')
    },
    payOrder() {
      this.CONTAIN_ORDER({ type: 'cart' })
      this.$router.push('/stationary/order')
    }
  }
}
</script>

<style lang="scss">
@import './common.scss';

#StationaryCart {
  @include category;

  .navigator-step {
    background-color: white;

    border-bottom: 1px solid #dbdbdb;

    .navigator-step-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      max-width: $limitWidth;
      height: 90px;
      margin: 0 auto;
      padding: 0 60px;
    }

    .title {
      font-size: 30px;
      font-weight: bold;
      line-height: 1.33;
    }

    .step-list {
      .step-item {
        font-size: 14px;
        color: #999;

        .now {
          color: black;
        }
        .step-arrow {
          margin: 0 3px;
        }
      }
    }
  }

  .cart-body-container {
    max-width: $limitWidth;
    margin: 0 auto;

    .cart-body {      
      padding: 20px 30px 40px;
  
      .multiple-controller {
        display: flex;
        align-items: center;

        padding: 0 30px;
        padding-left: 64px;

        background-image: url('../../assets/images/new/option-unchecked.svg');
        background-position: left 30px center;
        background-size: 24px;
        background-repeat: no-repeat;

        .status {
          font-size: 14px;
          font-weight: bold;
        }
        .delete-select-item {
          width: 100px;
          height: 30px;
          margin-left: 10px;

          border: 1px solid #e1e1e1;
          border-radius: 15px;
          background-color: white;
        }

        &.checked {
        background-image: url('../../assets/images/new/option-checked.svg');
        }
      }

      .cart-list {
        margin-top: 20px;

        .cart-item {
          position: relative;
          margin-top: 10px;
          padding: 0 30px;
          border: 1px solid #e1e1e1;
          border-radius: 18px;
          background-color: white;

          .remove {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 40px;
            height: 40px;

            background: url('../../assets/images/new/cancel-nor.svg');
          }

          .item-core {
            display: flex;
            padding: 20px 0px;

            

            .core-left {
              display: flex;
              flex-shrink: 0;
              align-items: center;
              flex-basis: 50%;
              max-width: 50%;

              .toggle-btn {
                flex-shrink: 0;
                width: 44px;
                height: 44px;

                background-image: url('../../assets/images/new/option-unchecked.svg');
                background-position: center;
                background-size: 24px;
                background-repeat: no-repeat;

                &.checked {
                  background-image: url('../../assets/images/new/option-checked.svg');
                }
              }

              .core-img {
                flex-shrink: 0;
                width: 60px;
                height: 60px;

                margin-left: 4px;
                margin-right: 10px;

                background-size: cover;
                background-position: center;
              }
              .item-name {
                flex-grow: 1;
                overflow: hidden;

                font-size: 14px;

                text-overflow: ellipsis;
              }
            }
            .core-right {
              display: flex;
              justify-content: space-between;
              align-items: center;

              flex-shrink: 0;
              flex-basis: 50%;

              padding: 0 30px;

              .control-box {
                display: flex;

                .quantity-controller {
                  width: 46px;
                  height: 46px;
                  border: 1px solid #e1e1e1;
                  border-radius: 45px;
                  font-size: 28px;
                  font-weight: bold;
                  background-color: white;
                  color: #666;

                  line-height: 1.36;
                }
                .product-quantity {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 50px;

                  font-size: 24px;
                  font-weight: bold;
                }
              }

              .price-box {
                .origin-price {
                  height: 24px;
                  font-size: 14px;
                  color: #999;
                  text-align: right;
                  line-height: 1.71;
                  text-decoration: line-through;
                }
                .price {
                  height: 28px;
                  font-size: 18px;
                  font-weight: bold;
                  color: black;
                  text-align: right;
                  line-height: 1.56;
                }
              }
            }
          }

          .item-detail {
            display: flex;
            padding: 20px 0;

            border-top: 1px solid #dbdbdb;

            .common-detail {
              padding: 0 34px;
            }

            .menu-detail {
              flex-basis: 50%;

              padding-right: 4px;

              .product-price-item {
                height: 24px;
                font-size: 14px;
                line-height: 1.71;

                .title {}
                .number {
                  margin-left: 4px;
                  font-weight: bold;
                }
              }
            }

            .option-detail {
              flex-basis: 50%;

              padding-left: 4px;
              

              .option-item {
                height: 24px;
                font-size: 14px;

                line-height: 1.71;

                .price {
                  margin-left: 4px;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

      .cart-statistics {
        display: flex;
        margin-top: 10px;
        padding: 33px 50px;
        border: 1px solid $color-main;
        border-radius: 18px;
        background-color: white;

        .left {
          display: flex;
          justify-content: space-between;
          flex-basis: 55.555556%;
          flex-grow: 0;
          padding-right: 30px;
          border-right: 1px solid #dbdbdb;

          .price-box {
            .row {
              height: 28px;
              font-size: 18px;
              line-height: 1.56;
              
              &:not(:first-child) {
                margin-top: 10px;
              }

              .title { }
              .price {
                margin-left: 8px;
                font-weight: bold;
              }
            }
          }

          .count-box {
            text-align: right;

            .title {
              height: 24px;
              font-size: 14px;
              line-height: 1.71;
            }
            .count {
              height: 40px;
              margin-top: 4px;
              font-size: 30px;
              font-weight: bold;
              line-height: 1.33;

              .number {
                color: $color-main;
              }
            }
          }
        }

        .right {
          flex-basis: 44.444444%;
          flex-grow: 0;

          text-align: right;

          .title {
            height: 24px;
            font-size: 14px;
            line-height: 1.71;
          }
          .total-price {
            margin-top: 4px;
            height: 40px;
            font-size: 30px;
            font-weight: bold;
            line-height: 1.33;

            .number {
              color: #d0021b;
            }
          }
        }
      }

      .cart-button-box {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 30px 0 40px;

        .cart-button {
          width: 222px;
          height: 50px;
          border-radius: 25px;
          font-size: 20px;
          color: white;
        }

        .main {
          background-color: #212121;
        }
        .pay {
          margin-left: 10px;
          background-color: $color-main;
        }
      }
    }
  }
}
</style>