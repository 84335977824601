<template>
  <div id="Toast">
    <div class="toast-position" :class="{ visible: toastVisible, show: toastShow }">
      <div class="outer">
        <div class="inner">
          <div class="centered toast-window" :class="{ 'show-window': toastShow }">
            <div class="toast-msg">
              {{ toastMsg }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vuex from 'vuex'

export default {
  computed: {
    toastVisible() {
      return this.$store.state.toast.visible;
      return true;
    },
    toastShow() {
      return this.$store.state.toast.show;
      return true
    },
    toastMsg() {
      return this.$store.state.toast.msg;
    }
  },
  methods: {
    ...vuex.mapMutations({
      'hideToastMsg': 'hideToastMsg'
    })
  },
  destroyed() {
    this.hideToastMsg();
  }
}
</script>

  <style lang="scss">
  #Toast {
  .toast-position {  
    display: none;
    opacity: 0;
    position: fixed;

    top: calc(50%);
    right: 45px;
    bottom: calc(50% - 100px);
    left: 45px;

    color: rgba(#fff, 1);

    transition: all 1s;

    .toast-window {
      padding: 10px 20px;

      font-size: 14pt;

      background-color: rgba(#000, .0);
      

      line-height: 38px;

      transition: all 1s;  
    }

    .show-window {
      background-color: rgba(#000, .6);
    }
  }

  .visible {
    display: block;
  }

  .show {
    opacity: 1;

    top: calc(50% - 50px);
    bottom: calc(50% - 50px);
    
    color: rgba(#fff, 1);
  }
}


</style>
