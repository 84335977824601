<template>
  <div id="OrderCreditcard">
    <div class="header">
      <div class="header-title">
        <span class="cancel" @click="close()"></span>
        <span></span>
      </div>
      <div class="fran-name-row">
        <div class="fran-name-title">가맹점명</div>
        <div class="fran-name">{{ this.$store.state.storeInformation.store_names | language }}</div>
      </div>
      <div class="price-title">{{ t('ORDER.FINAL_PAYMENT_AMOUNT') }}</div>
      <div class="result-price">{{ this.$store.state.orderInform.totalPrice | commaNumber }} 원</div>
    </div>
    <div class="body">
      <div class="card-frame">
        <div class="card">
          <div class="card-payment-guide">
            상품권 결제를 위해 <br>신용카드 정보를 입력해주세요.
          </div>
          <div class="text-box">
            <input type="tel" class="card-number" placeholder="카드번호" v-model="cardNo" maxlength="16">
            <input type="text" class="card-validity" placeholder="유효기간 (MM/YY)" v-model="cardYMD" maxlength="4">
            <select name="" id="" v-model="cardQuota" class="installment">
              <option value="00" >일시불</option>
              <option value="02" >2개월</option>
              <option value="03" >3개월</option>
              <option value="06" >6개월</option>
              <!-- <option value="" v-for="number in 12" :key="number">{{number}}개월</option> -->
            </select>
            <div class="payment-agreement" :class="{ checked: agreementCheck }" @click="agreementCheck = !agreementCheck">위 구매조건을 확인, 결제 진행에 동의합니다.</div>
          </div>
          <div class="button-row">
            <button class="payBtn" @click="payOrder()">결제</button>
          </div>   
        </div>        
      </div>

      <div class="infinity-window"></div>      
    </div>    
  </div>
</template>

<script>
import axios from 'axios';

import language from '../filters/language.js'

export default {
  data() {
    return {
      agreementCheck: false,
      // cardNo: '4171030601633920',
      cardNo: '',
      cardYMD: '',
      cardQuota: '00'
    }
  },
  filters: {
    commaNumber(value) {
      value = '' + value;

      return value.split('').reverse().reduce((acc, digit, i) => {
        if (i > 0 && i % 3 === 0) acc.push(',')
        return [...acc, digit]
      }, []).reverse().join('');
    },
    language
  },
  methods: {
    payOrder() {
      let self = this;

      if(this.cardNo.length < 16) {
        // window.alert('카드 번호를 입력해주세요');
        this.$store.commit('showToastMsg', '카드 번호를 입력해주세요');
        return;
      }
      if(this.cardYMD.length < 4) {
        // window.alert('카드 유효기간을 입력해주세요.');
        this.$store.commit('showToastMsg', '카드 유효기간을 입력해주세요.');
        return;
      }
      if(!this.agreementCheck) {
        // window.alert('결제 진행에 동의해주세요');
        this.$store.commit('showToastMsg', '결제 진행에 동의해주세요');
        return;
      }

      let mm = this.cardYMD.substring(0, 2);
      let yy = this.cardYMD.substring(2, 4);

      let orderTitle = this.getOrderTitle();

      let data = {
        store_id: this.$store.state.storeID,
        user_tel: this.$store.state.orderInform.phoneNumber,
        card_no: this.cardNo,
        card_ymd: `${yy}${mm}`,
        quota: this.cardQuota,
        title: orderTitle,
        packing_yn: this.$store.state.orderInform.packing? "Y" : "N",
        order_fail_time: this.$store.state.storeSetting.order_fail_time,
        request_t: this.$store.state.orderInform.requestMsg,
        order: this.$store.state.order
      }

      console.log(data);

      axios({
        method: 'post',
        url: `${this.$store.state.menucApi}/card/approval`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
        .then(function(res) {
          console.log(res.data.reg_number);

          self.$store.commit('setState', {
            key: 'regNumber',
            value: res.data.reg_number
          })       
          
          console.log(res)
          
          self.$router.push(`${self.$store.state.gRoot}/order/result`);
        })
        .catch(function(err) {
          self.$store.commit('showToastMsg', '카드 정보를 확인해주세요.');
          console.log(err);
        })
    },
    getOrderTitle() {
      let order = this.$store.state.order;
      let title = '';
      if(order.length > 1) {
        title = `${language(order[0].menu_names)} 외 ${order.length-1}건`
      } else {
        title = `${language(order[0].menu_names)}`;
      }

      return title;
    },
    close() {
      this.$router.push(`${this.$store.state.gRoot}/product/list`);
    }
  },
  created() {
  }

}
</script>

<style lang='scss'>
#OrderCreditcard {
  .header {
    position: fixed;
    z-index: 100;
    right: 0;
    left: 0;

    padding-bottom: 16px;
    background-color: #000;

    color: #fff;

    .header-title {
      position: relative;
      height: 44px;
      font-size: 17px;
      line-height: 44px;
      text-align: center;

      .cancel {
        display: inline-block;
        position: absolute;
        left: 0;;
        width: 44px;
        height: 44px;
        background-image: url("../assets/images/bars-navigation-bar-x-close-nor.svg");
      }
    }

    .fran-name-row {
      overflow: hidden;
      padding: 20px 30px 0;

      line-height: 24px;
      font-size: 15px;

      .fran-name-title {
        float: left;
        height: 24px;
        font-weight: bold;
      }
      .fran-name {
        float: right;
        height: 24px;
      }
    }
    .price-title {
      margin-top: 10px;
      padding: 0 30px;
      height: 24px;
      text-align: left;
      font-size: 15px;
      font-weight: bold;
      line-height: 1.6;
    }
    .result-price {
      padding: 0 30px;
      height: 50px;
      text-align: right;
      font-size: 30px;
      line-height: 50px;
    }
  }

  .body {
    overflow-y: scroll;
    position: fixed;
    //
    top: 188px;
    right: 0;
    bottom: 0;
    left: 0;
    
    .card-frame {
      padding: 20px 10px 0;

      .card {
        padding: 30px 0;
        border-radius: 18px 18px 0 0;
        border: solid 1px #e0e0e0;
        border-bottom: none;
        background-color: #fff;

        .card-payment-guide {
          padding: 0 30px;
          height: 60px;
          font-size: 20px;
          line-height: 1.5;
          color: #007aff;
        }

        .text-box {
          padding: 0 23px;
        }

        .card-number {
          display: block;
          width: 100%;
          height: 50px;
          margin-top: 20px;
          padding: 12px 10px;
          font-size: 17px;
          line-height: 1.53;
          color: #424242;
          border: none;
          border-bottom: 1px solid #e0e0e0;

          &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #999;
          }
          &::-moz-placeholder { /* Firefox 19+ */
            color: #999;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            color: #999;
          }
          &:-moz-placeholder { /* Firefox 18- */
            color: #999;
          }

          &:focus {
            border-bottom: 1px solid #d9312e;
          }
        }
        .card-validity {
          display: block;
          width: 100%;
          height: 50px;
          margin-top: 20px;
          padding: 12px 10px;
          font-size: 17px;
          line-height: 1.53;
          color: #424242;
          border: none;
          border-bottom: 1px solid #e0e0e0;

          &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #999;
          }
          &::-moz-placeholder { /* Firefox 19+ */
            color: #999;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            color: #999;
          }
          &:-moz-placeholder { /* Firefox 18- */
            color: #999;
          }

          &:focus {
            border-bottom: 1px solid #d9312e;
          }
        }
        .installment {
          display: block;
          width: 100%;
          height: 50px;
          margin-top: 20px;
          padding: 12px 10px;
          font-size: 17px;
          font-weight: bold;
          line-height: 1.53;
          color: #424242;
          border: none;
          outline: none;
          border-radius: 0;
          border-bottom: 1px solid #e0e0e0;
          background-color: #fff;

          background-image: url('../assets/images/style-1-down.svg');
          background-position: right;
          background-size: 40px 40px;
          background-repeat: no-repeat;

          &:focus {
            border-bottom: 1px solid #d9312e;
          }
        }

        .payment-agreement {
          padding-left: 45px;
          width: 100%;
          height: 40px;
          margin-top: 19px;
          font-size: 13px;
          line-height: 40px;
          color: #424242;

          background-image: url('../assets/images/controls-buttons-checkbox-style-1-nor.svg');
          background-position: center left;
          background-size: 40px 40px;
          background-repeat: no-repeat;
          
          &:active {
            background-image: url('../assets/images/controls-buttons-checkbox-style-1-pre.svg');
          }
        }
        .checked {
          background-image: url('../assets/images/controls-buttons-checkbox-style-1-act.svg');
        }

        .button-row {
          width: 100%;
          padding: 29px 15px 0;
          
          .payBtn {
            display: inline-block;
            width: 100%;
            height: 60px;
            border-radius: 30px;
            background-color: #007aff;
            font-size: 20px;
            line-height: 60px;
            color: #fff;

            &:active {
              background-color: #0062cc;
            }
          }
          
        }
      }
    }
  }

  .infinity-window {
    z-index: -10;
    position: fixed;
    right: 10px;
    bottom: -100px;
    left: 10px;

    height: 50%;

    background-color: #fff;

    border: {
      right: 1px solid #e0e0e0;
      left: 1px solid #e0e0e0;
    }
  }
}
</style>
