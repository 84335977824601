<template>
  <div id="PolicyPIPC" v-if="getPolicyPIPC">
    <div class="modal-background">
      <div class="outer">
        <div class="inner">
          <div class="modal-term centered">
            <div class="term-content">
              <div class="term-header">
                <span class="header-title">{{ $t('MOBILE_PRIVACY_COLLECTION_CONSENT') }}</span>
                <button class="close-modal" @click="HIDE_POLICY_PIPC"></button>
              </div>
              <div class="term-body">
                <div class="body-content">
                  <div class="content-scroll">
                    <p>[개인정보 제3자 제공에 대한 동의]</p>

                    <p>귀하의 개인정보는 제 3자 제공 동의를 선택한 경우 「개인정보보호법」 에 따라 다음과 같이 제3자에게 제공됩니다.</p>
                    <p>개인정보의 제공</p>
                    <p>개인정보를 제공받는 자</p>
                    <p>ㆍ(주)엘릭서페이</p>
                    <p>제공받는자의 개인정보 수집 이용</p>
                    <p>제공받는자의 개인정보 수집 이용 목적</p>
                    <p>① 주문관리</p>
                    <p>② 주문 알림 전송 및 주문 내역 제공</p>
                    <p>제공할 개인정보의 항목</p>
                    <p>① 결제 상태 정보</p>
                    <p>② 상품 구매, 취소, 반품 교환 정보</p>
                    <p>③ 휴대폰번호</p>
                    <p>제공받는자의 개인정보 보유 이용기간</p>
                    <p>동의일로부터 1년</p>
                    <p>개인정보의 제3자 제공 동의</p>
                    <p>귀하는 개인정보의 선택적인 제공에 대한 동의를 거부할 수 있습니다. 다만, 동의 거부시 주문 서비스 이용에 제한이 있을 수 있습니다.</p>
                    <p>고객이 주문을 완료 하면 개인정보 제3자 제공에 대해 동의한 것으로 간주합니다.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vuex from 'vuex'

export default {
  computed: {
    ...vuex.mapGetters({
      'getPolicyPIPC': 'getPolicyPIPC'
    })
  },
  methods: {
    ...vuex.mapActions({
      'HIDE_POLICY_PIPC': 'HIDE_POLICY_PIPC'
    })
  }
}
</script>

<style lang="scss">
#PolicyPIPC {
  .modal-background {
    position: fixed;

    z-index: 3000;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 500px;
    margin: 0 auto;

    background-color: rgba(#000, .6);

    .modal-term {
      padding: 0 25px;

      .term-content {
        background-color: #fff;

        text-align: left;

        .term-header {
          position: relative;

          height: 48px;
          padding: 0 21px;

          border-bottom: 1px solid #ebebeb;

          .header-title {
            font-size: 14pt;
            font-weight: 700;
            color: #424242;
            line-height: 48px;
          }

          .close-modal {
            display: inline-block;
            position: absolute;

            top: 0;
            right: 3px;

            width: 38px;
            height: 100%;

            background-image: url('../assets/images/bt_close_1_n.png');
            background-size: 38px 38px;
            background-position: center;
            background-repeat: no-repeat;

            &:active {
              background-image: url('../assets/images/bt_close_1_p.png');
            }
          }
        }

        .term-body {
          padding: 18px 0;

          font-size: 11pt;

          color: #808080;

          line-height: 15.333333pt;

          .body-content {
            overflow-y: scroll;

            max-height: 450px;
            padding: 0 21px;
          }
        }
      }
    }
  }
}
</style>