<template>
  <div id="OrderCancellation">
    <div class="cancel-body">
      <div class="notice">주문접수가 취소되었습니다</div>
      <div class="title">주문 취소</div>
      <div class="guide">다시 주문을 진행해주세요</div>
    </div>

    <div class="btn-box">
      <button id="routeMain" @click="routeMain">메인으로 가기</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    routeMain() {
      this.$router.push('/stationary/home')
    }
  }
}
</script>

<style lang="scss">
#OrderCancellation {
  .cancel-body {
    max-width: 550px;
    margin: 0 auto;
    padding: 61px 40px;

    background-color: white;
    background-image: url('../../assets/images/new/pattern.svg'), url('../../assets/images/new/pattern.svg');
    background-position: left top, right top;
    background-size: 20px 32px;
    background-repeat: repeat-y;

    text-align: center;

    .notice {
      height: 28px;
      font-size: 18px;
      font-weight: bold;
      color: #999;
      line-height: 1.56;
    }

    .title {
      height: 64px;
      margin-top: 10px;
      font-size: 54px;
      font-weight: bold;
      color: #d0021b;
      line-height: 1.19;
    }

    .guide {
      height: 34px;
      margin-top: 30px;
      font-size: 24px;
      line-height: 1.42;
    }
  }

  .btn-box {
    padding: 30px;
    text-align: center;

    #routeMain {
      width: 222px;
      height: 50px;
      border-radius: 25px;
      font-size: 20px;
      background: #212121;
      color: white;
    }
  }
}
</style>