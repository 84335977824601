<template>
  <div id="StationaryOrder">
    <div class="category-navigator">
      <div class="category-navigator-container">
        <div class="category-box">
          <span>
            <button class="menu"></button>
            <button 
              class="category-item"
              v-for="(category, idx) in GET_CATEGORY_LIST" :key="idx"
              @click="lookProductList(category)"
            >{{ category.category_names | language }}</button>
          </span>
        </div>
      </div>
    </div>

    <div class="navigator-step">
      <div class="navigator-step-container">
        <div class="title">주문／결제</div>
        <div class="step-list">
          <span class="step-item" v-for="(step, idx) in stepList" :key="step.text">
            <span class="step-name" :class="{ now: $route.name === step.routeName }">{{ step.text }}</span>
            <span class="step-arrow" v-if="stepList.length-1 !== idx">＞</span>
          </span>
        </div>
      </div>
    </div>

    <div class="order-body-container">
      <div class="order-body">
        <div class="left-pannel">
          <div class="item-box">
            <div class="item-title">주문 옵션</div>
            <div class="order-type-list">
              <div class="order-type" :class="{ checked: !packing }" @click="togglePacking(false)">직접수령</div>
              <div class="order-type" :class="{ checked: packing }" @click="togglePacking(true)">택배</div>
            </div>
          </div>

          <div class="item-box">
            <div class="item-title">요청사항이 있으신가요?</div>
            <input type="text" name="" id="Requests" class="order-input-box" placeholder="요청 사항을 입력해주세요" v-model="requests">
          </div>

          <div class="item-box">
            <div class="item-title">주문내역 조회 ／ SMS(알림톡)안내 수신</div>
            <div class="warning">
              <div>휴대폰 번호를 입력하지 않고 주문한 고객은 주문 내역 조회 및 SMS(알림톡)안내 수신이 불가합니다.</div>
              <div>결제 완료 후 번호 수정이 불가하니, 정확히 입력해주세요.</div>
            </div>
            <input type="tel" name="" id="Tel" class="order-input-box" placeholder="휴대폰 번호 입력 (-없이)" inputmode="tel" v-model="tel">
          </div>

          <div class="item-box">
            <div class="item-title">결제 수단</div>
            <div class="order-type-list">
              <div v-for="(method, idx) in GET_STORE_INFORMATION.payment_type_list" :key="idx">
                <div  v-if="method.payment_access.pc" class="order-type" :class="{ checked: idx === paymentMethod }" @click="setPaymentMethod(idx)">{{ method.titles | language }}</div>
              </div>
            </div>
          </div>

          <div class="item-box">
            <div class="order-digest">
              <div class="row">
                <span class="title">총 메뉴 금액</span>
                <span class="content">{{ GET_ORDER_TOTAL.originPrice | commaPrice }} 원</span>
              </div>
              <div class="row">
                <span class="title">총 할인 금액</span>
                <span class="content">{{ GET_ORDER_TOTAL.discountPrice | commaPrice }}원</span>
              </div>
              <div class="row">
                <span class="title">총 주문 개수</span>
                <span class="content">{{ GET_ORDER_TOTAL.count }}개</span>
              </div>
            </div>
            <div class="order-core">
              <div class="title">최종 결제금액</div>
              <div class="price"><span class="number">{{ GET_ORDER_TOTAL.finalPaymentAmount | commaPrice }}</span> 원</div>
            </div>
          </div>

          <div class="item-box">
            <div class="agree" :class="{ checked: personalInformation }" @click="personalInformation = !personalInformation">개인 정보 제공에 동의하고 주문 진행합니다.</div>
            <div class="link">개인정보 제3자 제공동의</div>
            <div class="guide">주식회사 엘릭서페이는 통신판매 중개자로서 엘릭서메뉴 주문하기의 거래 당사자가 아니며, 입점 판매자가 등록한 상품 정보 및 거래에 대해 책임을 지지 않습니다.</div>
          </div>

          <div class="button-box">
            <button id="pay" @click="pay()" :disabled="!personalInformation">결제하기</button>
          </div>
        </div>
        <!-- <div class="right-pannel"></div> -->
      </div>
    </div>

  </div>
</template>

<script>
import vuex from 'vuex'
import language from '../../filters/language'
import commaPrice from '../../filters/commaPrice'
import symbolNumber from '../../filters/symbolNumber'

export default {
  data() {
    return {
      stepList: [
        { text: '장바구니', routeName: 'S_CART' },
        { text: '주문／결제', routeName: 'S_ORDER' },
        { text: '주문완료' }
      ],
      packing: false,
      paymentMethod: 0,
      personalInformation: false,
      requests: '',
      tel: ''
    }
  },
  computed: {
    ...vuex.mapGetters({
      'GET_CATEGORY_LIST': 'im/GET_CATEGORY_LIST',
      'GET_CATEOGRY_ID': 'im/GET_CATEOGRY_ID',
      'GET_STORE_INFORMATION': 'im/GET_STORE_INFORMATION',
      'GET_ORDER': 'im/GET_ORDER',
      'GET_ORDER_TOTAL': 'im/GET_ORDER_TOTAL'
    }),
  },
  filters: {
    language,
    commaPrice,
    symbolNumber
  },
  created() {
    console.log(this.GET_ORDER_TOTAL)
  },
  mounted() {
    console.log(`Stationary Order Component Mounted!`)
    // console.log(this.GET_STORE_INFORMATION)
  },
  methods: {
    ...vuex.mapActions({
      'PAY_ORDER': 'im/PAY_ORDER',
      'SET_ERROR': 'SET_ERROR',
      'FETCH_PRODUCT_LIST': 'im/FETCH_PRODUCT_LIST'
    }),
    async lookProductList(category) {
      let categoryID = category.category_id;
      let res = await this.FETCH_PRODUCT_LIST(categoryID);      
      this.$router.push(`/stationary/home`)
    },
    togglePacking(value) {
      this.packing = value;
    },
    setPaymentMethod(idx) {
      this.paymentMethod = idx;
    },
    async pay() {
      let payMethod = this.GET_STORE_INFORMATION.payment_type_list[this.paymentMethod];

      let payOpts = {
        paymentTypeCode: payMethod.code,
        paymentTypeExtra: payMethod.extra,
        returnURL: location.origin,
        language: this.$i18n.locale,
        urserTel: this.tel,
        requests: this.requests,
        packingYN: this.packing ? "Y" : "N",
        title: this.GET_ORDER.length > 1 ? `${language(this.GET_ORDER[0].menu_names)} 외 ${this.GET_ORDER.length-1}건` : `${language(this.GET_ORDER[0].menu_names)}`
      }

      console.log(payMethod)
      console.log(payOpts);
      let res = await this.PAY_ORDER(payOpts);
      console.log(res)


      if(res.data && res.data.result && res.data.result.message) {
        this.SET_ERROR({
          message: res.data.result.message,
          code: res.data.result.code
        })
        
        this.$router.push(`${this.$store.state.gRoot}/page/error`);
        return;
      }
      
      if (res.status == 200) {
        if(this.needChildWindow(res.data, payMethod)) {
          return 0;
        }

        let url = res.data.qr_url;
        console.log(url)

        if(url === '') {
          console.log('TEST 예외처리');

          let query = {
            store: this.GET_STORE_INFORMATION.store_name,
            title: this.GET_ORDER.length > 1 ? `${language(this.GET_ORDER[0].menu_names)} 외 ${this.GET_ORDER.length-1}건` : `${language(this.GET_ORDER[0].menu_names)}`,
            price: this.GET_ORDER_TOTAL.finalPaymentAmount
          }

          this.$router.push({
            path: `/stationary/test`,
            query
          })

          return;
        }
        
        location.replace(url);
      }
    },
    needChildWindow(result, method) {
      let paymentMethods = ['EX_WALLET'];
      let filter = paymentMethods.find(item => item === method.extra);

      if(filter) {
        var win = window.open(result.qr_url, '_blank');
        win.focus();
        return true;
      }
    }
  },

}
</script>

<style lang="scss">
@import './common.scss';

#StationaryOrder {
  @include category;

  .navigator-step {
    background-color: white;

    border-bottom: 1px solid #dbdbdb;

    .navigator-step-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      max-width: $limitWidth;
      height: 90px;
      margin: 0 auto;
      padding: 0 60px;
    }

    .title {
      font-size: 30px;
      font-weight: bold;
      line-height: 1.33;
    }

    .step-list {
      .step-item {
        font-size: 14px;
        color: #999;

        .now {
          color: black;
        }
        .step-arrow {
          margin: 0 3px;
        }
      }
    }
  }

  .order-body-container {
    max-width: $limitWidth;
    margin: 0 auto;

    .order-body {
      display: flex;
      padding: 20px 30px 40px;

      .left-pannel {
        /* flex-basis: 61.538462%; */
        flex-basis: 100%;
        flex-grow: 0;
        padding-right: 10px;

        .item-box {
          padding: 20px;

          border: 1px solid #e1e1e1;
          border-radius: 18px;
          background-color: white;

          &:not(:first-child) {
            margin-top: 10px;
          }

          .item-title {
            height: 28px;
            font-size: 18px;
            font-weight: bold;

            line-height: 1.56;
          }

          .order-type-list {
            margin-top: 20px;

            .order-type {
              height: 24px;
              margin-top: 12px;
              padding-left: 34px;

              font-size: 14px;
              font-weight: bold;

              background-image: url('../../assets/images/new/option-unchecked.svg');
              background-size: 24px;
              background-position: left;
              background-repeat: no-repeat;

              line-height: 1.71;

              /* &:not(:first-of-type) {
                margin-top: 12px;
                background-color: gold;
              } */

              &.checked {
                background-image: url('../../assets/images/new/option-checked.svg');
                color: $color-main;
              }
            }
          }

          .order-input-box {
            display: block;
            width: 100%;
            height: 54px;
            padding: 0 20px;
            border: 1px solid #e1e1e1;
            border-radius: 12px;
            font-size: 14px;

            &::placeholder {
              color: rgba(black, .3);
            }
          }

          #Requests {
            margin-top: 15px;
          }

          #Tel {
            margin-top: 20px;
          }

          .warning {
            margin-top: 10px;

            font-size: 13px;
            color: #212121;
            line-height: 1.69;
          }

          .order-digest {
            .row {
              display: flex;
              justify-content: space-between;
              height: 24px;
              padding: 0 10px;
              font-size: 14px;
              line-height: 1.71;

              &:not(:first-child) {
                margin-top: 10px;
              }

              .title {}
              .content {
                font-weight: bold;
                line-height: 1.71;
              }
            }
          }

          .order-core {
            margin-top: 20px;
            padding: 20px 10px 0;

            border-top: 1px solid #dbdbdb;

            .title {
              height: 28px;
              font-size: 18px;
              font-weight: bold;
              line-height: 1.56;
            }

            .price {
              height: 40px;
              margin-top: 4px;
              font-size: 30px;
              font-weight: bold;

              text-align: right;
              line-height: 1.33;

              .number {
                margin-right: 4px;
                color: red;
              }
            }                        
          }

          .agree {
            height: 24px;
            padding-left: 34px;

            font-size: 14px;
            font-weight: bold;

            background-image: url('../../assets/images/new/option-unchecked.svg');
            background-size: 24px;
            background-position: left;
            background-repeat: no-repeat;

            line-height: 1.71;

            &.checked {
              background-image: url('../../assets/images/new/option-checked.svg');
            }
          }

          .link {
            height: 22px;
            margin-top: 4px;
            padding-left: 34px;
            font-size: 13px;
            color: #888;
            line-height: 1.69;
            text-decoration: underline;
          }

          .guide {
            margin-top: 4px;
            padding-left: 34px;
            font-size: 13px;
            color: #888;
            line-height: 1.69;
          }
        }

        .button-box {
          margin-top: 30px;

          text-align: center;

          #pay {
            width: 222px;
            height: 50px;
            border-radius: 25px;
            font-size: 20px;
            background-color: $color-main;
            color: white;
            line-height: 1.5;

            &:disabled {
              background-color: #dbdbdb;
            }
          }
        }
      }

      .right-pannel {
        flex-basis: 38.461538%;
        flex-grow: 0;

        background: blue;
      }
    }
  }
}
</style>